import React from 'react';
import {Icon, Modal} from 'semantic-ui-react';
import PropTypes from 'prop-types';

export const CustomModalHeader = ({ title, onClose, isCloseDisabled = false, isCloseVisible = true, ...otherProps }) => {
    return (
        <Modal.Header {...otherProps} className={'customModalHeader'}>
            <span>{title}</span>
            {isCloseVisible && (
                <Icon name="close" onClick={onClose} link disabled={isCloseDisabled}/>
            )}
        </Modal.Header>
    );
};

CustomModalHeader.propTypes = {
    title: PropTypes.string.isRequired,
    onClose: function(props, propName, componentName) {
        // Check if onClose is required based on the presence of isCloseVisible
        if (props.isCloseVisible && !props[propName]) {
            console.warn(`Prop ${propName} is required when isCloseVisible is true in ${componentName}.`);
        }
    },
    isCloseDisabled: PropTypes.bool,
    isCloseVisible: PropTypes.bool
};