import React from 'react';

import {S3Image} from "../../custom-common";
import IMAGE from "../../../constants/images";

const StrategicPartnerLogo = (props) =>(
    <S3Image {...props} {...IMAGE.OFFICIAL_LOGO}/>
);

export default StrategicPartnerLogo
