const STRATEGIC_PARTNER = Object.freeze({
    LABEL: 'Voya Protect',
    PALETTE: {
        PRIMARY: '#D75426',
        SECONDARY: '#8ABF2E'
    },
    CONTACT_SUPPORT: {
        EMAIL: 'voyaprotectsupport@joinansel.com',
        NUMBER: '(888) 290-1153'
    },
    S3: {
        APPLICATION: 'employer',
        BASE_URL: 'https://brella-platform-assets.s3.amazonaws.com',
        SP_NAME: 'voya',
    },
    PAPER_CLAIMS_SUBMISSION_FORM_PDF: 'https://voyaprotectforms.joinansel.com/Voya+Protect+Paper+claim+form+Order+%23219641+2_12_2024.pdf',
    LEGAL:{
        SERVICE_NAME: 'Ansel Services, Inc.',
        INSURANCE_NAME: 'Ansel Health, Inc.',
        TERMS: 'https://www.joinansel.com/terms',
        PRIVACY: 'https://www.joinansel.com/privacy-policy'
    },
});

export default STRATEGIC_PARTNER;
