import React from 'react';
import {Table, Grid} from "semantic-ui-react";
import {formatMoney} from "../../../utils/utils";
import {contributionTypes, plansDescription} from "../../../utils/strings";
import FamiliyTierAgeBandContributionTable from "../../familiyTierAgeBandContributionTable";

const OptionalsView = ({title, plansDescriptionText, riderOptionMoney}) => (
    <React.Fragment>
        <Grid.Column width={4}>
            <div className="colorfulText">{title}</div>
            <p className={"smaller neutral700Text"}>{plansDescriptionText}</p>
        </Grid.Column>
        <Grid.Column width={2}><p className={"small"}>{formatMoney(riderOptionMoney)}</p></Grid.Column>
    </React.Fragment>
);

const RidersTable = ({optionalRiders}) => (
    <React.Fragment>
        <Table.Header className="secondTableHeader">
            <Table.Row>
                <Table.HeaderCell textAlign="left" colSpan={4}>Included riders</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            <Table.Row verticalAlign='top'>
                <Table.Cell colSpan={4}>
                    <Grid>
                        {optionalRiders.map((rider, index) => (
                            <OptionalsView
                                key={index}
                                title={rider.label}
                                plansDescriptionText={plansDescription[rider.category.toLowerCase()]}
                                riderOptionMoney={rider.amount}
                            />
                        ))}
                    </Grid>
                </Table.Cell>
            </Table.Row>
        </Table.Body>
    </React.Fragment>
);

const isBrellaSelectCustom = (planOffering) => (planOffering === 'Brella Select Custom');

const EmployerContributionTable = ({ratedClassUtil, planOffering, ageRanges}) => {
    if (ratedClassUtil.hasEmployerContribution() === false && !isBrellaSelectCustom(planOffering)){
        return null
    }

    return (
        <React.Fragment>
            <Table.Header className="secondTableHeader">
                <Table.Row>
                    <Table.HeaderCell textAlign="left" colSpan={4}>Employer contribution </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <Table.Row>
                    {isBrellaSelectCustom(planOffering) ? (
                        <Table.Cell colSpan={4}>
                            <p className={"smaller neutral700Text"}>100% employer-paid</p>
                        </Table.Cell>
                    ):(
                        <Table.Cell colSpan={4} style={{paddingLeft: '.75em', paddingRight: '.75em'}}>
                            { ratedClassUtil.contributionType() === contributionTypes.flatContribution  &&
                            <Table compact>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>
                                            <div className="smaller neutral700Text">Employee only per month</div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div className={"smaller neutral700Text"}>Employee + Spouse per month</div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div className={"smaller neutral700Text"}>Employee + child(ren) per month</div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div className={"smaller neutral700Text"}>Family per month</div>
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell textAlign="left" className={"small"}>
                                            {formatMoney(ratedClassUtil.flatContribution())}
                                        </Table.Cell>
                                        <Table.Cell textAlign="left" className={"small"}>
                                            {formatMoney(ratedClassUtil.flatContribution())}
                                        </Table.Cell>
                                        <Table.Cell textAlign="left" className={"small"}>
                                            {formatMoney(ratedClassUtil.flatContribution())}
                                        </Table.Cell>
                                        <Table.Cell textAlign="left" className={"small"}>
                                            {formatMoney(ratedClassUtil.flatContribution())}
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                            }
                            { ratedClassUtil.contributionType() === contributionTypes.familyTierContribution  &&
                            <Table compact>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>
                                            <div className="smaller neutral700Text">Employee only per month</div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div className={"smaller neutral700Text"}>Employee + Spouse per month</div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div className={"smaller neutral700Text"}>Employee + child(ren) per month</div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div className={"smaller neutral700Text"}>Family per month</div>
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell textAlign="left" className={"small"}>
                                            {formatMoney(ratedClassUtil.employeeOnlyContribution())}
                                        </Table.Cell>
                                        <Table.Cell textAlign="left" className={"small"}>
                                            {formatMoney(ratedClassUtil.employeeSpouseContribution())}
                                        </Table.Cell>
                                        <Table.Cell textAlign="left" className={"small"}>
                                            {formatMoney(ratedClassUtil.employeeChildrenContribution())}
                                        </Table.Cell>
                                        <Table.Cell textAlign="left" className={"small"}>
                                            {formatMoney(ratedClassUtil.familyContribution())}
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                            }
                            { ratedClassUtil.contributionType() === contributionTypes.ageBandContribution  &&
                            <Grid padded columns={6}>
                                <Grid.Row>
                                    {ageRanges.map(band => {
                                        return <Grid.Column key={band.key} style={{paddingBottom: "10px"}}>
                                            <div className="small neutral700Text">
                                                <b>Age {band.title}</b><br/>
                                                {formatMoney(ratedClassUtil.findAgeBandContribution(band.key))}
                                            </div>
                                        </Grid.Column>
                                    })}

                                </Grid.Row>
                            </Grid>
                            }
                            {ratedClassUtil.contributionType() === contributionTypes.familyTierAgeBandContribtion &&
                            <FamiliyTierAgeBandContributionTable ratedClassUtil={ratedClassUtil} ageRanges={ageRanges}/>
                            }
                        </Table.Cell>
                    )}
                </Table.Row>
            </Table.Body>
        </React.Fragment>
    );
};

const BottomReviewChoices = ({optionalRiders, ratedClassUtil, planOffering, ageRanges}) => (
    <Table padded={"very"}>
        {optionalRiders.length > 0 && <RidersTable optionalRiders={optionalRiders}/>}
        <EmployerContributionTable
            ratedClassUtil={ratedClassUtil} planOffering={planOffering} ageRanges={ageRanges}
        />
    </Table>
);

export default BottomReviewChoices;
