import React from 'react';
import {Button, Modal} from "semantic-ui-react";
import {CustomModalHeader} from "../../custom-common";

const RemoveMicrodepositBankAccountModal = (props) => {
    const {show, close, remove, paymentMethod} = props;

    const handleSubmit = () => {
        remove(paymentMethod);
        close();
    };

    return (
        <Modal size={'tiny'} onClose={close} open={show} style={{maxWidth: '502px'}}>
            <CustomModalHeader title={"Are you sure you want to delete your payment method?"} onClose={close}/>
            <Modal.Content style={{overflowY: 'auto'}}>
                Deleting your payment method will result in the loss of your bank account's verification status. It can take up to an additional 2 business days to re-verify your bank account.
                <div style={{marginTop: '30px', display: 'flex', justifyContent: 'flex-end'}}>
                    <Button color='grey' basic onClick={close}>Cancel</Button>
                    <Button primary onClick={handleSubmit}>I'm sure</Button>
                </div>
            </Modal.Content>
        </Modal>
    )
}

export default RemoveMicrodepositBankAccountModal;
