const key = "employer-fsl";

export const loadState = () => {
    try {
        const serializedState = localStorage.getItem(key)
        return JSON.parse(serializedState) || undefined
    } catch (e) {
        console.warn(e)
        return undefined
    }
}

export const persistState = async (state) => {
    try{
        localStorage.setItem(key, JSON.stringify(state))
    } catch (e) {
        console.warn(e)
    }
}

export const purgeState = () => {
    localStorage.removeItem(key)
}