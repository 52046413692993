import React from 'react'
import {Icon} from "semantic-ui-react";

export const DownloadIcon = () => {
    return (
        <Icon
            name='download'
            style={{
                color: '#1F74DB',
                position: 'absolute',
                top: '14px',
                right: '6px',
            }}
        />
    )
}
