
import React from "react";
import {MFAContainer, MFASplashScreen, MFAHeading, MFABox, MFAText, MFAButton, MFAGrid} from './styled';
import {ClickableText} from "../components/custom-common";

const MfaSplash = ({skipForNow, configMFA, mfaNotEnabledGracePeriodEnded}) => {
    return (
        <MFAContainer className="splashWrapper" maxWidth={'700px'}>
                <MFASplashScreen>
                    <MFAGrid centered>
                        <MFAGrid.Row>
                            <MFAGrid.Column>
                                <MFAHeading as="h2">Set up two-factor authentication</MFAHeading>
                            </MFAGrid.Column>
                        </MFAGrid.Row>
                        <MFAGrid.Row>
                            <MFAGrid.Column>
                                <MFABox>
                                    <MFAHeading as="h4">About two-factor authentication</MFAHeading>
                                    <MFAText>Two-factor authentication is an additional layer of security for your account. In addition to your normal credentials, you'll also need to provide an authentication code when logging in.</MFAText>
                                </MFABox>
                            </MFAGrid.Column>
                        </MFAGrid.Row>
                        <MFAGrid.Row>
                            <MFAGrid.Column>
                                <MFAButton onClick={configMFA}>Set up two-factor authentication</MFAButton>
                            </MFAGrid.Column>
                        </MFAGrid.Row>
                        {!mfaNotEnabledGracePeriodEnded &&(
                        <MFAGrid.Row>
                            <MFAGrid.Column>
                                <ClickableText onClick={skipForNow} small>Skip for now</ClickableText>
                            </MFAGrid.Column>
                        </MFAGrid.Row>
                        )}
                    </MFAGrid>
                </MFASplashScreen>
            </MFAContainer>
    )
}

export default MfaSplash;