import React from "react";
import STRATEGIC_PARTNER from "../constants/strategicPartners"

export const plansDescription = {
    moderate: "Injuries or illnesses that likely require a short visit to the ER or urgent care",
    severe: "Serious conditions that require more intensive medical treatment and attention.",
    catastrophic: "Dangerous or life-threatening conditions that require immediate medical intervention.",
    chronic: <React.Fragment>Receive coverage for over <b>4,100</b> additional chronic conditions spanning asthma, diabetes, epilepsy, and many more.</React.Fragment>,
    mental: <React.Fragment>Additional coverage for <b>600</b> different mental health disorders like anxiety, bipolar, and depression.</React.Fragment>
}

export const howCanClaraHelpUrl = "https://www.google.com/url?q=https://clara-corporate-resources.s3.amazonaws.com/brokers/Clara_%2BUserCases_onePager_200310.pdf&sa=D&ust=1588684011368000&usg=AFQjCNEvPhD7OGwKFc1xlm0wMDVQPJRCCw"

export const brellaChoice = "Brella Choice"
export const brellaSelect = "Brella Select"
export const brellaSelectCustom = "Brella Select Custom"

export const documentTypes = {
    groupApplication: "GroupApplication",
    policy: "Policy",
    employerCertificate: "ER_certificate",
    chronicRider: "Chronic_Rider",
    employeeCertificate: "EE_certificate"
}

export const certificateProductCode = {
    moderate: "Moderate Conditions",
    severe: "Severe Conditions",
    catastrophic: "Catastrophic Conditions",
    mental: "Mental Disorders",
    chronic: "Chronic Conditions"
}

export const contributionTypes = {
    familyTierAgeBandContribtion: "Varies by Family Tier and Age Band",
    familyTierContribution: "Varies by Family Tier",
    ageBandContribution: "Varies by Age Band",
    flatContribution: "Flat Amount",
    percentContribution: "Percent",
}

export const familyTier = {
    employeeOnly: "employee",
    employeeSp: "spouse",
    employeeCh: "child",
    family: "family"
}

export const ageBands = {
    b1: "b1",
    b2: "b2",
    b3: "b3",
}

export const errorDuplicateFein = `There was an issue validating the FEIN please reach out to ${STRATEGIC_PARTNER.CONTACT_SUPPORT.EMAIL}`;
export const issueWithFEIN = "Issue with FEIN"
export const unexpectedError = "An unexpected error has occurred."
export const userAccountAlreadyHasAContract = "This user account already has a contract"
export const unableToDesignateNewSigner = "Unable to designate new signer"
