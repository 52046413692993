import React, {Component} from "react";
import {Container, Grid, Icon, Loader, Message, Segment, Transition} from "semantic-ui-react";
import {connect} from "react-redux";
import {getStepProps} from "../../utils/common";
import Steps from "../steps/steps";
import AccountCreationHeader from "./accountCreationHeader";
import Footer from "../footer";
import {withRouter} from "react-router-dom";
import ReviewChoice from "./choices/reviewChoice";
import ReviewSelect from "./choices/reviewSelect";
import DesignateSigner from "./designateSigner";
import ConfirmAccount from "./confirmAccount";
import axios from "axios";
import {resolveErrorMessage} from "../../utils/utils";
import ReviewSelectCustom from "./choices/reviewSelectCustom";
import {
    brellaChoice,
    brellaSelect,
    brellaSelectCustom,
    errorDuplicateFein,
    issueWithFEIN,
    unexpectedError,
} from "../../utils/strings";
import BorderedSegment from "../borderedSegment";
import RatedClassUtil from "../../utils/ratedClassUtil";
import {setAuthToken} from "../../actions/authActions";
import STRATEGIC_PARTNER from "../../constants/strategicPartners";
import {CustomDivider} from "../custom-common";

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        quote: state.accountCreation.initialData.quote,
        employer: state.accountCreation.initialData.employer,
        group: state.accountCreation.group,
        initialData: state.accountCreation.initialData,
        ratedClassUtil: state.accountCreation.initialData.ratedClassUtil,
        ageRanges: state.accountCreation.initialData.ageRanges,
        ratedClasses: state.accountCreation.group.ratedClasses,
        increments: state.accountCreation.initialData.increments
    }
};

class ReviewPlan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stepProps: null,
            openModal: false,
            loading: false,
            submitError: '',
            openSignerModal: false,
        }
        this.longPolling = null;
        this.submitErrorStatus = "ERROR";
        this.submitStatusNotFound = "NOT_FOUND";
        this.submitSuccessStatus = "SUCCESS";
    }

    componentDidMount() {
        this.buildSteps();
    }

    componentWillUnmount() {
        clearInterval(this.longPolling);
    }

    buildSteps = () => {
        this.setState({stepProps: getStepProps(3)});
    }

    checkSubmitStatus = (entryId) => {
        const {dispatch, history, nextRoute} = this.props;

        const handleSuccess = (data) => {
            clearInterval(this.longPolling);
            this.setState({loading: false, submitError: ''});
            dispatch(setAuthToken(data.authToken));
            history.push({pathname: nextRoute});
        };
        const handleError = (error) => {
            clearInterval(this.longPolling);
            let errorMessage = resolveErrorMessage(error, unexpectedError);
            if (errorMessage === issueWithFEIN) {
                errorMessage = errorDuplicateFein;
            }
            this.setState({ loading: false, submitError: errorMessage });
        };

        const pollStatus = async () => {
            try {
                const {data} = await axios.post("/api/employer/v2/checkSubmitGroupInfoStatus", {entryId});
                const {submitStatus} = data;
                if (submitStatus === this.submitSuccessStatus) {
                    handleSuccess(data);
                } else if (submitStatus === this.submitErrorStatus || submitStatus === this.submitStatusNotFound) {
                    handleError(data.error);
                }
            } catch (error) {
                handleError(error);
            }
        };
        this.longPolling = setInterval(pollStatus, 2000);
    };

    handleSubmit = async (e) => {
        const {
            taxId,
            legalEntityName,
            dbaName,
            address,
            zipcode,
            city,
            state,
            ratedClasses,
            affiliatesSubsidiaries,
            variesByClass,
            newEligibilityInfo
        } = this.props.group;
        let elegibilities = [];
        if (!!variesByClass) {
            ratedClasses.forEach(groupClass => {
                const elegibility = {
                    onlyUnmarried: groupClass?.onlyUnmarriedChildren,
                    benefitTerminationRules: groupClass.benefitTerminationRules,
                    activelyAtWorkHours: parseFloat(groupClass.activelyAtWorkHours),
                    domesticPartnership: groupClass.domesticPartnership,
                    subjectToERISA: groupClass.subjectToERISA,
                    groupClassId: groupClass.groupClass.id,
                    cobra: groupClass.cobra
                }
                if (groupClass.hasOwnProperty('continuationProvisions')) {
                    if (groupClass.continuationProvisions) {
                        const {
                            continuationProvisions,
                            leaveAbsenceMilitaryLeave,
                            leaveAbsenceSabbatical,
                            leaveAbsenceQuarantine,
                            furlough,
                            laborStrike
                        } = groupClass
                        elegibility.coverageContinued = !!continuationProvisions
                        elegibility.coverageNotContinued = !continuationProvisions
                        if (continuationProvisions) {
                            elegibility.leaveAbsenceMilitaryLeave = !!leaveAbsenceMilitaryLeave
                            elegibility.leaveAbsenceSabbatical = !!leaveAbsenceSabbatical
                            elegibility.leaveAbsenceQuarantine = !!leaveAbsenceQuarantine
                            elegibility.furlough = !!furlough
                            elegibility.laborStrike = !!laborStrike
                        }
                    } else {
                        elegibility.coverageNotContinued = false
                    }
                }
                elegibilities.push(elegibility)
            })
        } else {
            ratedClasses.forEach(groupClass => {
                const elegibility = {
                    onlyUnmarried: newEligibilityInfo.onlyUnmarriedChildren,
                    benefitTerminationRules: newEligibilityInfo.benefitTerminationRules,
                    activelyAtWorkHours: parseFloat(newEligibilityInfo.activelyAtWorkHours),
                    domesticPartnership: newEligibilityInfo.domesticPartnership,
                    subjectToERISA: newEligibilityInfo.subjectToERISA,
                    groupClassId: groupClass.groupClass.id,
                    cobra: newEligibilityInfo.cobra
                }
                elegibilities.push(elegibility)
            })
        }

        let payload = {
            fein: taxId,
            legalEntityName: legalEntityName,
            dbaName: dbaName,
            streetAddress: address,
            zipCode: zipcode,
            city,
            stateValue: state,
            affiliatesSubsidiaries,
            eligibilities: elegibilities,
            acceptTerms: true,
            designatedSigner: null,
            ackSigningAuthority: true,
            selectedClassIndex: 0,
        }
        this.setState({loading: true, submitError: ''});
        try {
            const {data} = await axios.post("/api/employer/v2/submitGroupInfo", payload, {headers: {'Content-Type': 'application/json'}});
            this.checkSubmitStatus(data.entryId)
        } catch (e) {
            this.setState({
                loading: false,
                submitError: resolveErrorMessage(e, "An unexpected error has occurred."),
            })
        }
    }

    openModalDesignateSigner(isOpen) {
        this.setState(state => state.openSignerModal = isOpen)
    }

    toggleDetail = (index) => {
        const {selectedClassIndex} = this.state
        if (index === selectedClassIndex) {
            index = null
        }
        this.setState({selectedClassIndex: index})
    }

    render() {
        const {
            quote,
            prevRoute,
            increments,
        } = this.props;
        const {stepProps, submitError} = this.state;
        const {legalEntityName, ratedClasses} = this.props.group;

        const nextButton = (
            <ConfirmAccount
                onOpen={(isOpen) => this.openModalDesignateSigner(isOpen)}
                handleSubmit={this.handleSubmit}
                loading={this.state.loading}
                employerName={legalEntityName}
                text="Sign"/>
        );
        const designateSigner = (
            <DesignateSigner
                open={this.state.openSignerModal}
                onOpen={(isOpen) => this.openModalDesignateSigner(isOpen)}
                group={this.props.group}
                initialData={this.props.initialData}
                history={this.props.history}
                loading={this.state.loading}
                //basePlans={this.props.basePlans}
            />
        );

        if (!stepProps) {
            return <Container><Loader active={true}/></Container>
        }

        return (
            <div className="employer-main-container">
                <AccountCreationHeader prevRoute={prevRoute} history={this.props.history} centerTitle="Create account"/>
                {increments && quote &&
                <Container className={"employer-accountCreation-container"}>
                    <Grid stackable>
                        <Grid.Column>
                            <Steps stepProps={stepProps}/>
                        </Grid.Column>
                    </Grid>
                    {ratedClasses.length > 0 && ratedClasses.map((item, index) => {
                        const {selectedClassIndex} = this.state
                        const showDetails = selectedClassIndex === index
                        const optionalRiders = [];
                        const ageRanges = this.props.ageRanges.filter(range => range.groupClassId === item.groupClass.id)[0]
                        if (item.contractTerms.mentalBrokerRequested === true) {
                            const mental = {
                                category: "mental",
                                amount: item.contractTerms.mentalCoverageLimit,
                                label: "Mental illness"
                            }
                            optionalRiders.push(mental)
                        }
                        if (item.contractTerms.chronicBrokerRequested === true) {
                            const chronic = {
                                category: "chronic",
                                amount: item.contractTerms.chronicCoverageLimit,
                                label: "Chronic conditions"
                            }
                            optionalRiders.push(chronic)
                        }
                        const ratedClassUtil = new RatedClassUtil(item);

                        const reviewPlan = <React.Fragment key={index}>
                            {item.contractTerms.planOffering === brellaChoice ? (
                                <ReviewChoice
                                    ratesPerAge={item.ratedClassRates}
                                    increments={increments}
                                    optionalRiders={optionalRiders}
                                    ratedClassUtil={ratedClassUtil}
                                    ageRanges={ageRanges.ageRanges}
                                />
                            ) : (item.contractTerms.planOffering === brellaSelect ? (
                                <ReviewSelect
                                    presets={item.contractTerms.presets}
                                    ratedClassUtil={ratedClassUtil}
                                    ageRanges={ageRanges.ageRanges}
                                    optionalRiders={optionalRiders}
                                />
                            ) : (item.contractTerms.planOffering === brellaSelectCustom &&
                                <ReviewSelectCustom
                                    presets={item.contractTerms.presets}
                                    ratedClassUtil={ratedClassUtil}
                                    ageRanges={ageRanges.ageRanges}
                                    optionalRiders={optionalRiders}
                                />
                            ))}
                            <CustomDivider hidden/>
                        </React.Fragment>
                        if (ratedClasses.length === 1) {
                            return reviewPlan;
                        }

                        const classContent = <React.Fragment>
                            <div className={"clickable"} onClick={() => {
                                this.toggleDetail(index)
                            }}>
                                <b>Class {index + 1}: {item.groupClass.documentDescription}</b>
                                <Icon style={{float: 'right'}}
                                      name={showDetails ? 'chevron up' : 'chevron down'}/>
                            </div>
                            {showDetails &&
                            <Transition.Group animation={"slide down"} duration={100}>
                                { reviewPlan }
                            </Transition.Group>}
                        </React.Fragment>

                        return <React.Fragment key={index}>
                            {showDetails ?
                                <BorderedSegment borderColor={STRATEGIC_PARTNER.PALETTE.PRIMARY}>
                                    {classContent}
                                </BorderedSegment> :
                                <Segment>
                                    {classContent}
                                </Segment>
                            }
                        </React.Fragment>
                    })
                    }
                    {submitError.length > 0 && <Message negative>{submitError}</Message>}
                    <CustomDivider hidden section/>
                </Container>
                }

                <Footer button={nextButton} link={designateSigner} showTopFooter hasToDisplayCarrierDisclaimer/>
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps)(ReviewPlan));
