import React from "react";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {setLeftNavigation} from "../../actions/navigationActions";
import {
    MFAIcon,
    MFASegment,
    MFAText
} from "../styled";
import { ClickableText } from "../../components/custom-common";

const MfaReminder = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const setUpMfa = () => {
        dispatch(setLeftNavigation(false));
        history.push({ pathname: '/mfa'});
    }

    return(
        <MFASegment className={"mfaReminder"}>
            <MFAText className={"mfaReminder-title"}>Two-factor needs to be setup</MFAText>
            <MFAText className={"neutral400Text"}>Add more security to your account with two-factor authentication.</MFAText>
            <ClickableText onClick={setUpMfa}>
                Set it up now <MFAIcon name={'long arrow alternate right'} />
            </ClickableText>
        </MFASegment>
    )
}

export default MfaReminder