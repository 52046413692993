import React, { useCallback, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';

// TODO: Add this for the shareable link variant
// import { Button } from "semantic-ui-react";

// TODO: Remove this for the non-shareable link variant
import { Dropdown } from "semantic-ui-react";
import { PlaidLinkDialog } from './plaidLinkDialog';
import axios from "axios";

const Plaid = (props) => {
    const [ showLink, setShowLink ] = useState(false)

    const onSuccess = useCallback((_, metadata) => {
        props.setPlaidData(metadata)
    }, [props]);

    const onExit = useCallback(async (error, metadata) =>  {
        if(error) {
            console.log({error, metadata})
            try {
                await axios.post("/api/employer/v2/plaidError", {error, metadata}, {headers: {'Content-Type': 'application/json'}});
            } catch (e) {

            }
        }
    }, []);

    const config = {
        token: props.plaidLinkToken,
        env: props.plaidEnv,
        onSuccess,
        onExit,
    };

    const { open, ready, loading } = usePlaidLink(config);

    // TODO: Uncomment this for the non-shareable link variant
    // return (
    //     <Button primary onClick={() => open()} disabled={!ready || loading || !!props.loading}>
    //         Add payment method
    //     </Button>
    // )

    // TODO: Comment this instead for the shareable link variant
    return (<>
        <Dropdown data-qa-button_add_payment text='Add payment method' className='button primary' disabled={!ready || loading || !!props.loading}>
            <Dropdown.Menu>
                <Dropdown.Item onClick={() => open()} style={{ lineHeight: '21px' }}>
                    Add payment method
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setShowLink(true)} style={{ lineHeight: '21px' }}>
                    Share a link
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
        {showLink && <PlaidLinkDialog onClose={() => setShowLink(false)}/>}
    </>)
};
export default Plaid;
