// Global Site color palette
// All color values must be defined here.
// The palette should only be used within the theme (not the components).

export const palette = {
  // Primary
  voyaOrange: '#F58000',

  // Secondary
  voyaYellow: '#FFC700',
  redOrange: '#D75426',
  voyaViolet: '#B73F7C',
  seaBlue: '#72B0BD',
  voyaPlum: '#551B57',
  voyaLightBlue: '#76C5E4',
  voyaDarkBlue: '#145A7B',
  voyaDarkerBlue: '#0c4b69',
  voyaLightGreen: '#9AC1A6',
  voyaTeal: '#0097A9',
  voyaGrey: '#6E6E6E',
  voyaNeutralGrey: '#767676',
  voyaLightGrey: '#838383',

  // Neutrals
  neutral50:  '#FAFAFA',
  neutral100: '#F5F5F5',
  neutral200: '#EEEEEE',
  neutral300: '#E0E0E0',
  neutral400: '#BDBDBD',
  neutral500: '#9E9E9E',
  neutral600: '#757575',
  neutral700: '#616161',
  neutral800: '#424242',
  neutral900: '#212121',

  // Generic
  white:          '#fff',
  black:          '#000',
}
