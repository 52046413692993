import React, {Component, Fragment} from "react";
import {Header, Grid} from "semantic-ui-react";
import PersonalizationCard from "../../personalizationCard";
import BottomReviewChoices from "./bottomReviewChoices";
import {brellaSelectCustom} from "../../../utils/strings";
import {CustomDivider} from "../../custom-common";


class ReviewSelectCustom extends Component {

    render() {
        const {
            optionalRiders,
            quoteInfo,
            presets,
            ratedClassUtil,
            ageRanges
        } = this.props;

        return(
            <Fragment>
                <CustomDivider hidden/>
                <Header as={"h2"} className={'colorfulText'}>Preconfigured package</Header>
                <CustomDivider hidden/>

                <Grid stackable columns={2}>
                    <Grid.Column width={4}>
                        <PersonalizationCard title={"Employer paid"} preset={presets.custom}/>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <BottomReviewChoices
                            optionalRiders={optionalRiders}
                            quoteInfo={quoteInfo}
                            ratedClassUtil={ratedClassUtil}
                            ageRanges={ageRanges}
                            planOffering={brellaSelectCustom}
                        />
                    </Grid.Column>
                </Grid>
            </Fragment>
        )
    }
}

export default ReviewSelectCustom;