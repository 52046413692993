import {Component} from 'react'
import {withRouter} from 'react-router-dom'
import connect from "react-redux/es/connect/connect"
import axios from "axios"
import {logout} from "../actions/authActions"

const mapStateToProps = (state, ownProps) => {
    return {
        authToken: state.auth.user?.authToken,
        ...ownProps
    }
};

class Config extends Component {
    constructor(props) {
        super(props);

        const getToken = this.getToken;
        const getDispatcher = this.props.dispatch;
        axios.interceptors.request.use(function (config) {
            try{
                const authToken =  getToken();
                if(!config.url.includes('http') && !!authToken){
                    config.headers['authToken'] = authToken;
                }
            }catch(e){}
            return config;
        }, function (error) {
            return Promise.reject(error);
        });

        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error && error.response && error.response.status === 401) {
                getDispatcher(logout());
            }
            return Promise.reject(error);
        });
    }

    getToken = () => {
        return this.props.authToken ? this.props.authToken : null;
    };

    render() {
        return this.props.children
    }
}

export default withRouter(connect(mapStateToProps)(Config))