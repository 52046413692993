import React from "react";
import {
  MFAGrid,
  MFAHeading,
  MFAText,
  MFAButton,
  MFAImage,
  MFAContainer
} from "../../styled";
import IMAGE from "../../../constants/images";

const SuccessMFASetup = ({ continueToPortal }) => {
  return (
    <>
    <MFAContainer className="successWrapper" maxWidth={"590px"}>
      <MFAGrid>
        <MFAGrid.Row>
          <MFAGrid.Column>
            <MFAGrid>
              <MFAGrid.Row>
                <MFAGrid.Column
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <MFAImage
                    size="tiny"
                    {...IMAGE.CHECK_80PX}
                  />
                  <MFAHeading as="h1" className="success">Success</MFAHeading>
                  <MFAText className="subTitle">
                    Two-factor authentication has been enabled for your account.
                  </MFAText>
                </MFAGrid.Column>
              </MFAGrid.Row>
              <MFAGrid.Row>
                <MFAGrid.Column
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <MFAButton onClick={() => continueToPortal()}>
                    Continue
                  </MFAButton>
                </MFAGrid.Column>
              </MFAGrid.Row>
            </MFAGrid>
          </MFAGrid.Column>
        </MFAGrid.Row>
      </MFAGrid>
    </MFAContainer>
    </>
  );
};

export default SuccessMFASetup;
