import {initialDataActionTypes, authActionTypes} from "../../actions/actionTypes";
import RatedClassUtil from "../../utils/ratedClassUtil";

const initialState = {
    employer: null,
    policy: null,
    quote: null,
    ageRanges: null,
    states: [],
    benefitTerminationRulesOptions: [],
    ratedClass: null,
    ratedClassUtil: null,
    increments: null,
    footer: null,
};

export const initialDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case initialDataActionTypes.SET_FOOTER: {
            return {
                ...state,
                footer: action.payload
            }
        }
        case initialDataActionTypes.SET_INITIAL_DATA: {
            let ratedClass = (action.payload.ratedClasses && action.payload.ratedClasses.length > 0 && action.payload.ratedClasses[0]) || null
            return {
                ...state,
                employer: action.payload.employer,
                policy: action.payload.policy,
                quote: action.payload.quote,
                ageRanges: action.payload.ageRangesPerClass,
                benefitTerminationRulesOptions: action.payload.benefitTerminationRules,
                states: action.payload.stateOptions,
                ratedClass: ratedClass,
                ratedClassUtil: new RatedClassUtil(ratedClass),
                increments: action.payload.increments,
                footer: action.payload.footer
                /*
                moderate: action.payload.moderate,
                severe: action.payload.severe,
                catastrophic: action.payload.catastrophic,
                mental: action.payload.mental,
                employerAccountUpdates: action.payload.employerAccountUpdates,
                employerName: action.payload.employerName,
                employerCompany: action.payload.employerCompany,
                optionalRiders: action.payload.optionalRiders,
                policyEffectiveDate: action.payload.policyEffectiveDate,
                */
            };
        }
        case authActionTypes.LOGOUT:
            return {
                employer: null,
                policy: null,
                quote: null,
                ageRanges: null,
                states: [],
                benefitTerminationRulesOptions: [],
                ratedClass: null,
                ratedClassUtil: null,
                increments: null,
                footer: null
            }
        default:
            return state
    }
};