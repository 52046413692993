import React from "react";

import {
  MFAGrid,
  MFAHeading,
  MFAText,
  MFAForm,
  MFALabel,
  MFATextError,
  MFAInput,
  MFAIcon,
  MFAContainer
} from "../../../styled";


const VerifyRenderItem = ({code, validationErrors, isDisabled, codeHandler, validateForm }) => {
  return (
    <>
      <MFAForm.Field
        style={{
          width: "300px",
          display: "inline-block",
          marginRight: "16px",
        }}
        error={validationErrors}
      >
        <MFALabel
          style={{
            width: "80%",
            backgroundColor: "transparent",
            padding: "8px 0 3px",
            fontSize: "14px",
          }}
        >
          6-digit authentication code
        </MFALabel>
        <MFAInput
          type="text"
          name="authenticatorCode"
          id="authenticatorCode"
          value={code}
          onChange={(e) => codeHandler(e)}
          maxLength="6"
          required
          disabled={isDisabled}
          onBlur={(e) => validateForm(code)}
        />
      </MFAForm.Field>
      {isDisabled &&
      (
        <>
          <MFAText className="verified">
            <MFAIcon name="checkmark" size="small" className="verifiedIcon" />
            Verified
          </MFAText>
        </>
      )}
    </>
  );
};

const AuthenticatorVerify = ({
  codeHandler,
  validationErrors,
  code,
  validateMfaCode,
  validateMfaCodeError,
  loading,
  mfaEnabled,
  changeMethodSettings,
  isMethodChanged,
  validateForm
}) => {
  return (
    <>
    <MFAContainer className="sectionWrapper" maxWidth={"497px"}>
      <MFAGrid>
        <MFAGrid.Row>
          <MFAGrid.Column>
            <MFAHeading as="h2">Enter authentication code</MFAHeading>
            <MFAText  className="subTitle">
              Enter the 6-digit code generated by your authenticator app to verify setup—
            </MFAText>
          </MFAGrid.Column>
        </MFAGrid.Row>
        <MFAGrid.Row>
          <MFAGrid.Column>
            <MFAForm>
              <MFAForm.Group grouped>
                <MFAGrid padded={false}>
                  <MFAGrid.Row style={{ paddingTop: "0", paddingBottom: "0" }}>
                    <MFAGrid.Column>
                      {!changeMethodSettings && (
                        <VerifyRenderItem code={code} validationErrors={validationErrors || validateMfaCodeError} isDisabled={mfaEnabled} loading={loading} codeHandler={codeHandler} validateMfaCode={validateMfaCode} validateForm={validateForm}/>
                      )}

                      {changeMethodSettings && (
                        <VerifyRenderItem code={code} validationErrors={validationErrors || validateMfaCodeError} isDisabled={isMethodChanged} loading={loading} codeHandler={codeHandler} validateMfaCode={validateMfaCode} validateForm={validateForm}/>
                      )}
                      {(validationErrors && !validateMfaCodeError) && (
                        <MFATextError>Enter a 6-digit authentication code</MFATextError>
                      )}
                      {validateMfaCodeError && (
                        <MFATextError>{validateMfaCodeError}</MFATextError>
                      )}
                    </MFAGrid.Column>
                  </MFAGrid.Row>
                </MFAGrid>
              </MFAForm.Group>
            </MFAForm>
          </MFAGrid.Column>
        </MFAGrid.Row>
        </MFAGrid>
      </MFAContainer>
    </>
  );
};

export default AuthenticatorVerify;
