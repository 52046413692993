import {QLE_CARD_TYPE, QLE_STATUS} from "../constants/qle";

export function getQLEStatus(willBePendingForApproval, status, decisionDate){
    if (willBePendingForApproval || status === QLE_STATUS.PENDING_FOR_APPROVAL) {
        return QLE_STATUS.PENDING_FOR_APPROVAL;
    } else if (status === QLE_STATUS.COMPLETED && decisionDate) {
        return QLE_STATUS.COMPLETED;
    } else if (status === QLE_STATUS.REJECTED && decisionDate) {
        return QLE_STATUS.REJECTED;
    } else {
        return QLE_STATUS.DEFAULT;
    }
}

export function getQLECardType(willBePendingForApproval, status, decisionDate){
    if (willBePendingForApproval || status === QLE_STATUS.PENDING_FOR_APPROVAL) {
        return QLE_CARD_TYPE.WARNING;
    } else if (status === QLE_STATUS.COMPLETED && decisionDate) {
        return QLE_CARD_TYPE.SUCCESS;
    } else if (status === QLE_STATUS.REJECTED && decisionDate) {
        return QLE_CARD_TYPE.ERROR;
    } else {
        return QLE_CARD_TYPE.DEFAULT;
    }
};

export function getQLEIcon(qleStatus) {
    switch (qleStatus) {
        case QLE_STATUS.PENDING_FOR_APPROVAL:
            return `${QLE_CARD_TYPE.WARNING}_qle_icon`;
        case QLE_STATUS.COMPLETED:
            return `${QLE_CARD_TYPE.SUCCESS}_qle_icon`;
        case QLE_STATUS.REJECTED:
            return `${QLE_CARD_TYPE.ERROR}_qle_icon`;
        default:
            return `${QLE_CARD_TYPE.DEFAULT}_qle_icon`;
    }
};