import React, {Component} from 'react'
import {connect} from 'react-redux'
import moment from 'moment'
import axios from 'axios'
import {Button, Dimmer, Form, Loader, Message, Modal, Radio} from 'semantic-ui-react';
import {addWorkDays, displayDateFormat, parseDate} from '../../../utils/date'
import {isEmpty, resolveErrorMessage} from '../../../utils/utils'
import {setData} from '../../../actions/openEnrollmentActions'
import STRATEGIC_PARTNER from "../../../constants/strategicPartners";
import {CustomModalHeader, DateSelector} from "../../custom-common";

const mapStateToProps = (state, ownProps) => {
    const oe = state.openEnrollment || { renewedContract: {} }

    return {
        ...ownProps,
        status: oe.status,
        preEnrollmentEducationLaunchDate: oe.preEnrollmentEducationLaunchDate,
        openEnrollmentId: oe.openEnrollmentId,
        contractId: oe.renewedContract.contractId,
        policyEffectiveDate: oe.renewedContract.policyEffectiveDate,
        form: {
            preEnrollmentEducationLaunchDate: parseDate(oe.preEnrollmentEducationLaunchDate),
            enrollmentStartDate: parseDate(oe.enrollmentStartDate),
            enrollmentEndDate: parseDate(oe.enrollmentEndDate),
            reEnrollRequired: !!oe.reEnrollRequired,
        },
        isBenAdmin: state.portal.isBenAdmin,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setOpenEnrollmentData: state => dispatch(setData(state)),
    }
}

const dateFields = ['preEnrollmentEducationLaunchDate', 'enrollmentStartDate', 'enrollmentEndDate']

class FormModal extends Component {
    constructor(props) {
        super(props)

        const { form } = props

        this.state = {
            fetching: false,
            form: { ...form },
            errors: {},
            serverError: null
        }
    }

    componentWillUnmount() {
        this.setState = () => null // Prevents setState event warnings after unmount.
    }

    validateDate = (fieldName, fieldValue = null) => {
        const { policyEffectiveDate, preEnrollmentEducationLaunchDate } = this.props
        const { form } = this.state
        const errors = {}
        const value = fieldValue || form[fieldName]

        let date
        // All editable fields are required
        if (value === null || value === '' || value === undefined) {
            errors[fieldName] = 'This field is required.'
            return errors
        }

        // Validating date fields
        date = moment(value, displayDateFormat)
        if (!date.isValid()) {
            errors[fieldName] = 'This date is invalid.'
            return errors
        }

        // Validaciones específicas según el campo
        switch (fieldName) {
            case 'preEnrollmentEducationLaunchDate': {
                const sqlDate = date.format('YYYY-MM-DD')
                const changed = sqlDate !== preEnrollmentEducationLaunchDate
                if (changed && date < this.launchMinDate) {
                    errors.preEnrollmentEducationLaunchDate = 'Launch date must be from the next 3 working days onwards.'
                }
                break
            }
            case 'enrollmentStartDate': {
                if (date < this.startMinDate) {
                    errors.enrollmentStartDate = `Enrollment start date cannot be earlier than ${this.startMinDate.format('YYYY-MM-DD')}.`
                }
                const peeld = moment(form.preEnrollmentEducationLaunchDate, displayDateFormat)
                if (date < peeld) {
                    errors.enrollmentStartDate = 'Enrollment start date cannot be prior to Launch date.'
                }
                break
            }
            case 'enrollmentEndDate': {
                if (date <= this.endMinDate) {
                    errors.enrollmentEndDate = `Enrollment end date cannot be earlier than ${this.endMinDate.format('YYYY-MM-DD')}`
                } else if (policyEffectiveDate) {
                    const ped = moment(policyEffectiveDate, 'YYYY-MM-DD').startOf('day')
                    if (date >= ped) {
                        errors.enrollmentEndDate = `Enrollment end date must be before the policy effective date ${ped.format(displayDateFormat)}.`
                    }
                }
                break
            }
            default: {}
        }

        return errors
    }

    validateForm = () => {
        const errors = {}

        // Validate form fields
        this.editFields.forEach(field => {
            const fieldErrors = this.validateDate(field)
            Object.assign(errors, fieldErrors)
        })

        this.setState({ errors })

        return isEmpty(errors)
    }

    formatDateForEndpoint = (field) => {
        return moment(field, displayDateFormat).format('YYYY-MM-DD')
    }

    handleSubmit = async () => {
        if (!this.validateForm()) return false

        const { contractId, openEnrollmentId, onClose, setOpenEnrollmentData } = this.props
        const { form } = this.state

        this.setState({ fetching: true, serverError: null })

        const fields = { contractId, openEnrollmentId }
        this.editFields.forEach(field => fields[field] = dateFields.includes(field) ? this.formatDateForEndpoint(form[field]) : form[field])

        const payload = {
            enrollmentEvent: fields
        }

        try {
            const response = await axios.post('/api/employer/v2/upsertPartialOpenEnrollmentEvent', payload)
            setOpenEnrollmentData(response.data.enrollmentEvent)
        } catch (e) {
            const serverError = resolveErrorMessage(e, 'Unable to save the form. Please try again or contact support.')
            this.setState({ fetching: false, serverError })
            return false
        }

        this.setState({ fetching: false })
        onClose()
    }

    handleChange = (_, { name, value, type, checked, readOnly }) => {
        let parsedValue

        if (readOnly) return false

        if (type === 'radio') {
            if (value === 'true') parsedValue = true
            if (value === 'false') parsedValue = false
        } else if (type === 'checkbox') {
            parsedValue = checked
        } else {
            parsedValue = value
        }

        this.setState(({ form, errors: currentErrors }) => {
            const errors = { ...currentErrors }
            delete errors[name]

            return ({
                form: {
                    ...form,
                    [name]: parsedValue,
                },
                errors,
            })
        })

        // Validate form field
        this.validateDateField(name, value)
    }

    onInvalid = ({ name }) => {
        // Clear field when input is invalid
        this.setState(({ form }) => ({
            form: {
                ...form,
                [name]: '',
            },
        }))
    }

    setError = (name, msg) => {
        this.setState(({ errors }) => ({
            errors: {
                ...errors,
                [name]: msg,
            },
        }))
    }

    renderError = (field) => {
        const { errors } = this.state

        const errorMsg = errors[field] || null

        return errorMsg && (
            <div><small className={"warningRedText"}>{errorMsg}</small></div>
        )
    }

    renderServerError = () => {
        const { serverError } = this.state

        if (!serverError) return null

        return (
            <Message negative style={{ fontSize: '12px' }}>
                <b>Server error:</b> {serverError}
            </Message>
        )
    }

    handleClose = () => {
        const { onClose } = this.props
        const { fetching } = this.state

        if (fetching) return false

        return onClose && onClose()
    }

    isEditable = (field) => {
        return this.editFields.includes(field)
    }

    validateDateField = (field, value) => {
        const errors = this.validateDate(field, value)
        this.setError(field, errors[field])
    }

    get editFields() {
        const { isBenAdmin } = this.props

        if (this.hasStarted) return ['enrollmentEndDate']

        const fields = [
            'preEnrollmentEducationLaunchDate',
            'enrollmentStartDate',
            'enrollmentEndDate',
        ]

        if (!isBenAdmin) fields.push('reEnrollRequired')

        return fields
    }

    get launchMinDate() {
        return addWorkDays(moment(), 3).startOf('day')
    }

    get startMinDate() {
        const { form: { preEnrollmentEducationLaunchDate } } = this.state

        if (!preEnrollmentEducationLaunchDate) return this.launchMinDate

        const launch = moment(preEnrollmentEducationLaunchDate, displayDateFormat).startOf('day')

        return launch.isValid() ? launch : this.launchMinDate
    }


    get endMinDate() {
        const { form: { enrollmentStartDate } } = this.state

        if (!enrollmentStartDate) return this.startMinDate

        const start = moment(enrollmentStartDate, displayDateFormat).startOf('day')

        return start.isValid() ? start : this.startMinDate
    }

    get diff() {
        const { form: { enrollmentStartDate, enrollmentEndDate } } = this.state

        if (!enrollmentStartDate || !enrollmentEndDate) return ''

        const start = moment(enrollmentStartDate, displayDateFormat)
        const end = moment(enrollmentEndDate, displayDateFormat)

        if (!start.isValid() || !end.isValid()) return ''

        const diff = end.diff(start, 'days') + 1

        return diff >= 0 ? diff : ''
    }

    get disabled() {
        const { fetching } = this.state

        return fetching
    }

    get isEdit() {
        const { status } = this.props

        return status !== 'open'
    }

    get hasStarted() {
        const { status } = this.props

        return ['started', 'finished'].includes(status)
    }

    render() {
        const { isBenAdmin } = this.props
        const { form, fetching } = this.state

        return (
            <Modal open onClose={this.handleClose} style={{ maxWidth: '700px' }}>
                {fetching && (
                    <Dimmer active inverted>
                        <Loader inverted />
                    </Dimmer>
                )}
                <CustomModalHeader title={this.isEdit
                    ? 'Edit enrollment settings'
                    : 'Let’s get your enrollment set up'}
                                   onClose={this.handleClose}/>
                <Modal.Content>
                    <Form onSubmit={this.handleSubmit}>
                        <p className='neutral700Text' style={{ margin: '0 0 40px' }}>
                            Indicate your enrollment dates and when you would like {`${isBenAdmin ? "the " : ""}`}{STRATEGIC_PARTNER.LABEL} {`${isBenAdmin ? "education experience" : "Enroll to"}`} be available to employees—
                        </p>

                        <Form.Field className='required field no-margin-input' width={12}>
                            {!isBenAdmin ? (<>
                                <label>Pre-enrollment education launch date</label>
                                <p className='neutral700Text' style={{ fontStyle: 'italic', fontSize: '14px' }}>
                                    On this date, the {STRATEGIC_PARTNER.LABEL} Enroll platform will be open for employees to learn about&nbsp;
                                    {STRATEGIC_PARTNER.LABEL} and get a recommendation. If email addresses are provided, emails to employees go out on this date.
                                    We recommend starting education a minimum of 2 weeks before enrollment starts.
                                </p>
                            </>) : (<>
                                <label>Select launch date for {STRATEGIC_PARTNER.LABEL} education link</label>
                                <p className='neutral700Text' style={{ fontStyle: 'italic', fontSize: '14px' }}>
                                    Our {STRATEGIC_PARTNER.LABEL} education experience is an online resource that makes it easy for your team
                                    to quickly understand {STRATEGIC_PARTNER.LABEL} and get personalized recommendations.
                                    It’s a good idea to share it with your team prior to your enrollment period start date.
                                </p>
                            </>)}
                            <Form.Group style={{ marginBottom: 0 }}>
                                <DateSelector
                                    fluid required
                                    width={5}
                                    name='preEnrollmentEducationLaunchDate'
                                    value={form.preEnrollmentEducationLaunchDate || ''}
                                    iconPosition='right'
                                    onChange={(e, {value}) => {
                                        this.handleChange(e, {name: "preEnrollmentEducationLaunchDate", value: value});
                                    }}
                                    onInvalid={this.onInvalid}
                                    disabled={this.disabled || !this.isEditable('preEnrollmentEducationLaunchDate')}
                                />
                            </Form.Group>
                            {this.renderError('preEnrollmentEducationLaunchDate')}
                        </Form.Field>
                        <Form.Group>
                            <Form.Field width={5}>
                                <DateSelector
                                    fluid required
                                    label='Enrollment start'
                                    name='enrollmentStartDate'
                                    value={form.enrollmentStartDate || ''}
                                    onChange={(e, {value}) => {
                                        this.handleChange(e, {name: "enrollmentStartDate", value: value});
                                    }}
                                    onInvalid={this.onInvalid}
                                    disabled={this.disabled || !this.isEditable('enrollmentStartDate')}
                                />
                                {this.renderError('enrollmentStartDate')}
                            </Form.Field>
                            <Form.Field width={5}>
                                <DateSelector
                                    fluid required
                                    label='Enrollment end'
                                    name='enrollmentEndDate'
                                    value={form.enrollmentEndDate || ''}
                                    onChange={(e, {value}) => {
                                        this.handleChange(e, {name: "enrollmentEndDate", value: value});
                                    }}
                                    onInvalid={this.onInvalid}
                                    disabled={this.disabled || !this.isEditable('enrollmentEndDate')}
                                />
                                {this.renderError('enrollmentEndDate')}
                            </Form.Field>
                            <Form.Field width={2}>
                                <Form.Input fluid readOnly
                                    label='Total days'
                                    value={this.diff}
                                    disabled={this.disabled}
                                />
                            </Form.Field>
                        </Form.Group>
                        {!isBenAdmin && (
                            <Form.Group>
                                <Form.Field>
                                    <label style={{ marginBottom: '8px' }}>Are current enrollees required to re-enroll?</label>
                                    <Radio required
                                        label='No, employees do not need to re-enroll to continue their coverage'
                                        name='reEnrollRequired'
                                        value='false'
                                        checked={!form.reEnrollRequired}
                                        onClick={this.handleChange}
                                        disabled={this.disabled || !this.isEditable('reEnrollRequired')}
                                        readOnly={!this.isEditable('reEnrollRequired')}
                                    />
                                    <Radio required
                                        style={{ marginTop: '8px' }}
                                        label='Yes, we require all employees actively elect to continue their coverage'
                                        name='reEnrollRequired'
                                        value='true'
                                        checked={!!form.reEnrollRequired}
                                        onClick={this.handleChange}
                                        disabled={this.disabled || !this.isEditable('reEnrollRequired')}
                                        readOnly={!this.isEditable('reEnrollRequired')}
                                    />
                                    {this.renderError('reEnrollRequired')}
                                </Form.Field>
                            </Form.Group>
                        )}
                        {this.renderServerError()}
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic
                        color='grey'
                        onClick={this.handleClose}
                        disabled={this.disabled}
                    >Cancel</Button>
                    <Button primary
                        onClick={this.handleSubmit}
                        disabled={this.disabled}
                        loading={fetching}
                    >Save</Button>
                </Modal.Actions>
            </Modal>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormModal)
