import React, {Component} from "react";
import {Form} from "semantic-ui-react";

class ErrorEmptyFormInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: null,
        };
    }

    render() {
        const {hasError} = this.state;
        const {onChange, label, name, value, required, width, ...otherFormInputProps} = this.props;
        return (
            <Form.Field width={width} error={hasError && required}>
                <Form.Input fluid required={required}
                            label={label}
                            name={name}
                            value={value}
                            onChange={onChange}
                            onFocus={() => {
                                this.setState({hasError: false});
                            }}
                            onBlur={(e) => {
                                const val = e.target.value;
                                if(required && (!val || val.length === 0)){
                                    this.setState({hasError: true});
                                }
                            }}
                            {...otherFormInputProps}/>
                {!!hasError && required && <small className={"warningRedText"}>Required</small>}
            </Form.Field>

        );
    }
}

export default ErrorEmptyFormInput;
