import React, {Component} from 'react'
import {Form, Button, Modal} from "semantic-ui-react";
import {errorStyle, validateFloat} from "../../utils/utils";
import {CustomDivider, CustomModalHeader} from '../custom-common';

class EditActivelyWorkHoursModal extends Component {
    MinActivelyWorkingHours = 17.5
    MaxActivelyWorkingHours = 40

    constructor(props) {
        super(props);
        this.state = {
            activelyHours: props.activelyHours,
            eligibleLivesChanged: null,
            error: null,
        }
    }

    handleChange = (event, {value, name}) => {
        this.setState({[name]: value})
    }

    save = async () => {
        const {onSave} = this.props
        const {activelyHours} = this.state
        if (!validateFloat(activelyHours, false)) {
            this.setState({error: "Invalid actively at work hours format"})
            return
        }
        const value = parseFloat(activelyHours)
        if (value < this.MinActivelyWorkingHours) {

            this.setState({error: `This proposal requires a minimum of ${this.MinActivelyWorkingHours} hours. To request a proposal with a lower minimum reach out to your Broker.`})
            return
        }
        if (value > this.MaxActivelyWorkingHours) {
            this.setState({error: `Actively at work hours cannot be greater than ${this.MaxActivelyWorkingHours}.`})
            return
        }

        onSave(value)
    }

    handleActivelyWorkingHoursChange = (e, {name, value}) => {
        if (value !== '') {
            if (!validateFloat(value, true)) {
                return
            }
        } else {
            value = null
        }
        this.setState({[name]: value, error: null})
    }

    render() {
        const {open, onCancel} = this.props
        const {activelyHours, eligibleLivesChanged, error} = this.state;
        const disableEdit = eligibleLivesChanged === 'Yes'
        return (
            <Modal size={'tiny'} onClose={onCancel} open={open}>
                <CustomModalHeader title={"Actively at work hours"} onClose={onCancel}/>
                <Modal.Content>
                    <p>Has the number of eligible lives changed since the quote request?</p>
                    <Form>
                        <Form.Group grouped>
                            <Form.Radio label="Yes" name='eligibleLivesChanged' value={'Yes'}
                                        checked={eligibleLivesChanged === 'Yes'} onChange={this.handleChange}/>
                            <Form.Radio label="No" name='eligibleLivesChanged' value={'No'}
                                        checked={eligibleLivesChanged === 'No'} onChange={this.handleChange}/>
                        </Form.Group>
                        <Form.Input label="Actively at work hours" value={activelyHours || ''} name="activelyHours" disabled={eligibleLivesChanged !== 'No'}
                                    onChange={this.handleActivelyWorkingHoursChange}/>
                        {eligibleLivesChanged === 'Yes' &&
                        <small><i>Since the number of eligible lives has changed, please reach out to your Broker to request this edit.</i></small>}
                        {error && <small style={errorStyle}>{error}</small>}
                        <CustomDivider hidden/>
                        <Button primary floated={"right"} disabled={disableEdit} onClick={this.save}>Submit</Button>
                        <Button primary basic floated={"right"} onClick={onCancel}>Cancel</Button>
                    </Form>
                </Modal.Content>
                <CustomDivider hidden/>
            </Modal>
        )
    }
}

export default EditActivelyWorkHoursModal;