import React, { Component } from 'react'
import { Grid, Icon, Dropdown, Segment } from 'semantic-ui-react'

export class Account extends Component {
    render() {
        const { id, last4, bankName, onRemove, fetching } = this.props

        return (
            <Segment>
                <Grid columns={"equal"} style={{ paddingLeft: '8px' }}>
                    <Grid.Column>
                        <div className={"small neutral700Text"}>
                            <Icon name='university' style={{ fontSize: 20 }}/>{bankName}
                        </div>
                        <div className="small neutral600Text">
                            ******{last4}
                        </div>
                    </Grid.Column>
                    <Grid.Column textAlign={"right"}>
                        {onRemove && (
                            <Dropdown icon={"ellipsis horizontal"} disabled={fetching}>
                                <Dropdown.Menu>
                                    <Dropdown.Item text='Remove' onClick={() => { onRemove(id) }}/>
                                </Dropdown.Menu>
                            </Dropdown>
                        )}
                    </Grid.Column>
                </Grid>
            </Segment>
        )
    }
}

export default Account
