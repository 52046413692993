import {bankAccountActionTypes} from "./actionTypes";

export function setBankAccountsData(bankAccounts) {
    return {
        type: bankAccountActionTypes.SET_BANK_ACCOUNTS_DATA,
        payload: bankAccounts
    }
}

export function setPendingVerificationBankAccounts(bankAccounts) {
    return {
        type: bankAccountActionTypes.SET_PENDING_VERIFICATION_BANK_ACCOUNTS,
        payload: bankAccounts
    }
}

export function setPendingVerificationBankAccount(bankAccount) {
    return {
        type: bankAccountActionTypes.SET_PENDING_VERIFICATION_BANK_ACCOUNT,
        payload: bankAccount
    }
}

export function setPendingVerificationBankAccountFailed(accountId) {
    return {
        type: bankAccountActionTypes.SET_PENDING_VERIFICATION_BANK_ACCOUNT_FAILED,
        payload: accountId
    }
}

export function removePendingVerificationBankAccount(accountId) {
    return {
        type: bankAccountActionTypes.REMOVE_PENDING_VERIFICATION_BANK_ACCOUNT,
        payload: accountId
    }
}

export function setBankAccount(bankAccount) {
    return {
        type: bankAccountActionTypes.SET_BANK_ACCOUNT,
        payload: bankAccount
    }
}

export function deleteBankAccount(bankAccountId) {
    return {
        type: bankAccountActionTypes.DELETE_BANK_ACCOUNT,
        payload: bankAccountId
    }
}


export function setDefaultBankAccount(bankAccountId) {
    return {
        type: bankAccountActionTypes.SET_DEFAULT_BANK_ACCOUNT,
        payload: bankAccountId
    }
}
