import {applyMiddleware, createStore} from 'redux'
import {composeWithDevTools} from "redux-devtools-extension"
import rootReducer from "./reducers/rootReducer"
import {loadState, persistState} from "./utils/storePersistence";

const middleware = [];
const devEnhancer = composeWithDevTools(
    applyMiddleware(...middleware)
);

const persistedData = loadState();
export const store = createStore(rootReducer, persistedData || {}, devEnhancer);
store.subscribe(() => {
    const state = store.getState();
    const {auth, accountCreation, portalModalHeader, employeeTermination} = state;
    persistState({auth, accountCreation, portalModalHeader, employeeTermination});
});
