import React, {Component} from 'react';
import { Menu } from "semantic-ui-react";
import { StrategicPartnerLogo } from './common';
import { GetHelpMenu } from './menu/getHelpMenu'

class PrePortalHeader extends Component {
    render() {
        return (
            <Menu fixed={"top"} secondary pointing className='employer-portal-menuContainer shadow-bottom-border'>
                <Menu.Item style={{marginTop: '-5px', padding: 0 }}>
                    <StrategicPartnerLogo />
                </Menu.Item>
                <Menu.Menu position="right">
                    <GetHelpMenu offline />
                </Menu.Menu>
            </Menu>
        )
    }
}

export default PrePortalHeader;
