import React, {Component} from "react"
import {Grid, Message, Segment} from "semantic-ui-react";
import {Link, withRouter} from "react-router-dom";
import PrePortalHeader from "./prePortalHeader";
import axios from "axios";
import {resolveErrorMessage} from "../utils/utils";
import Footer from "./footer";
import { MailToSupport } from "./common";
import STRATEGIC_PARTNER from "../constants/strategicPartners";
import {Header} from "./base";
import IMAGE from "../constants/images";
import {ClickableText, CustomDivider, S3Image} from "./custom-common";

class RequestSent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: null,
            success: false
        };
    }

    resendLink = async () => {
        try {
            const email = this.props.location.state.email
            this.setState({loading: true, error: null, success: false});
            await axios.post("/api/employer/v2/requestPasswordReset", {email: email});
            this.setState({loading: false, success: true})
        } catch (e) {
            console.warn(e)
            const error = resolveErrorMessage(e, "Unable to send reset password request")
            this.setState({loading: false, error: error})
        }
    }

    render() {
        const {loading, error, success} = this.state
        return (
            <React.Fragment>
                <PrePortalHeader/>
                <div className="employer-portal-container">
                    <Grid container stackable centered>
                        <Grid.Column style={{marginTop: '8em'}} width={6} className={"pageContent"}>
                            <Segment style={{padding: '2em 4em 2em 4em'}} loading={loading}>
                                <S3Image {...IMAGE.CHECK_124PX} />
                                <Header as={"h3"} textAlign={"center"}>Your request was sent</Header>

                                <p className={"small"}>Check your email. If we have an account associated with that
                                    email, you’ll receive a
                                    link to reset your password.</p>

                                <CustomDivider hidden/>
                                <Segment>
                                    <p className={"smaller"}>
                                        <span className={"neutral900Text"}><b>Didn't receive an email? </b></span><br/>
                                        Either <ClickableText
                                                     onClick={this.resendLink}>re-send the link</ClickableText> or <Link className="linkText"
                                        to="/forgotPassword">try a different email</Link>
                                    </p>
                                    {error && <Message negative size="mini">{error}</Message>}
                                    {success && <Message positive size="mini">Email re-sent successfully</Message>}
                                </Segment>
                            </Segment>
                            <Segment basic style={{padding: '1em 4em'}}>
                                <p className={"smaller"}>Still need help? Reach out to technical support at <span
                                    style={{whiteSpace: "nowrap"}}>{STRATEGIC_PARTNER.CONTACT_SUPPORT.NUMBER}</span> or <MailToSupport/></p>
                            </Segment>
                        </Grid.Column>
                    </Grid>
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}

export default withRouter(RequestSent);
