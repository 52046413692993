import {employeeActionTypes} from "./actionTypes";

export function setTerminationDetails(payload) {
    return {
        type: employeeActionTypes.SET_EMPLOYMENT_TERMINATION_DETAILS,
        payload
    }
}

export function setNewHireDetails(payload) {
    return {
        type: employeeActionTypes.SET_NEW_HIRE_DETAILS,
        payload
    }
}