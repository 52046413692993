import {employeeActionTypes} from "../actions/actionTypes";

const initialState = {
    autoApproveDate: null,
    changeExplanationCopy: null,
    coverageEndDate: null,
    coverageImpacted: null,
    decisionDate: null,
    employeeId: null,
    members: null,
    reason: null,
    rosterMemberId: null,
    status: null,
    terminationDate: null,
    tier: null,
    willBePendingForApproval: null,
};

export const employeeTerminationReducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case employeeActionTypes.SET_EMPLOYMENT_TERMINATION_DETAILS:
            return {
                ...state,
                ...payload
            }
        default:
            return state
    }
}
