import {employeeActionTypes} from "../actions/actionTypes";

const initialState = {
    autoApproveDate: null,
    changeExplanationCopy: null,
    coverageEffectiveDate: null,
    coverageImpacted: null,
    decisionDate: null,
    status: null,
    dateOfHire: null,
    declineCoverage: null,
    declineEnrollment: null,
    electionsMade: null,
    isEligibleForCoverage: null,
    newNewHireOpenEnrollmentStartDate: null,
    newNewHireOpenEnrollmentEndDate: null,
};

export const newHireDetailsReducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case employeeActionTypes.SET_NEW_HIRE_DETAILS:
            return {
                ...state,
                ...payload
            }
        default:
            return state
    }
}