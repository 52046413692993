import React, {Component, Fragment} from "react";
import {Button, Form, Grid, Input, Message, Modal, TextArea} from "semantic-ui-react";
import axios from "axios";
import {resolveErrorMessage} from "../utils/utils";
import {readFileAsync} from '../utils/common'
import {UploadFile} from './forms/uploadFile'
import {CustomModalHeader} from "./custom-common";
import {show as showToast} from "../actions/toastActions";
import {connect} from "react-redux";


const mapDispatchToProps = dispatch => {
    return {
        showToast: (toastDetails) => dispatch(showToast(toastDetails))
    }
};
class Concierge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ackSigningAuthority: false,
            loading: false,
            subject: '',
            message: '',
            files: [],
            validationErrors: {
                subject: false,
                message: false
            },
            error: null,
        };
        this.manageModal = this.manageModal.bind(this);
        this.submit = this.submit.bind(this);
    }

    manageModal = (value) => {
        const {onManage} = this.props;
        onManage(value)
    };

    submit = async () => {
        const {files, subject, message, validationErrors} = this.state;
        let error = false;
        if (!subject) {
            validationErrors.subject = true;
            error = true
        }

        if (!message) {
            validationErrors.message = true;
            error = true
        }

        if (error) {
            this.setState({validationErrors: validationErrors})
            return
        }

        this.setState({loading: true, error: null});
        try {
            const attachments = await this.processAttachments(files)

            const payload = {
                subject,
                message,
                attachments,
            };

            await axios.post('/api/employer/v2/submitCase', payload, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            this.resetForm()
            this.props.onManage(false);
            this.showToastSuccess()
        } catch (e) {
            const err = resolveErrorMessage(e, "Unable to submit your message")
            this.setState({loading: false, error: err});
            this.showToastError()
        }
    }

    handleChange = (e, {name, value}) => this.setState({[name]: value})

    resetForm = () => {
        this.setState({
            loading: false,
            subject: '',
            message: '',
            files: [],
            validationErrors: {
                subject: false,
                message: false
            },
            error: null
        })
    }

    handleFilesUpdate = (files) => {
        this.setState({files})
    }

    processAttachments = async (files) => {
        if (!files?.length) return

        return Promise.all(files.map(async file => {
            const content = await readFileAsync(file)
            const contentData = content.split(',')[1]

            return {
                fileName: file.name,
                contentType: file.type,
                contentData
            }
        }))
    }

    showToastSuccess = () => {
        this.props.showToast({
            type: 'success',
            message: "Your message has been sent!",
        })
    }

    showToastError = () => {
        this.props.showToast({
            type: 'error',
            message: "Unable to submit your message",
        })
    }

    render() {
        const {
            files,
            closeOnEscape,
            closeOnDimmerClick,
            loading,
            subject,
            message,
            validationErrors,
            error
        } = this.state;
        const { open } = this.props;
        const errorStyle = {color: '#D82000'}
        return (
            <Fragment>
                <Modal
                    open={open}
                    closeOnEscape={closeOnEscape}
                    closeOnDimmerClick={closeOnDimmerClick}
                    onClose={this.manageModal}
                    size='tiny'
                >
                    <CustomModalHeader title={"Message our team"} onClose={() => this.manageModal(false)}/>

                    <Modal.Content>
                        <Grid container columns={1}>
                            <Grid.Row>
                                <Grid.Column>
                                    Have a question? Send us a note and we will get back to you shortly—
                                </Grid.Column>
                            </Grid.Row>


                            <Grid.Column textAlign={"left"}>
                                <Form>
                                    <Form.Field>
                                        <label>Subject</label>
                                        <Input
                                            fluid
                                            disabled={loading}
                                            name='subject'
                                            value={subject}
                                            onChange={this.handleChange}
                                        />
                                        {validationErrors.subject &&
                                        <small style={errorStyle}>This field is required</small>}
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Message</label>
                                        <TextArea
                                            disabled={loading}
                                            name='message'
                                            value={message}
                                            onChange={this.handleChange}
                                        />
                                        {validationErrors.message &&
                                        <small style={errorStyle}>This field is required</small>}
                                    </Form.Field>

                                    <Form.Field>
                                        <label>{"Attachments"}</label>
                                        <UploadFile
                                            disabled={loading}
                                            files={files}
                                            validFileTypes={[
                                                'image/jpeg',
                                                'image/jpg',
                                                'image/png',
                                                'application/pdf',
                                                'text/csv',

                                                // MS Office formats
                                                'application/msword',
                                                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                                'application/vnd.ms-excel',
                                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                            ]}
                                            onFilesUpdate={files => this.handleFilesUpdate(files)}
                                            style={{marginTop: 0}}
                                        />
                                    </Form.Field>

                                </Form>
                            </Grid.Column>
                            {error &&
                            <Grid.Column>
                                <Form.Field>
                                    <Message negative size="mini" fluid>{error}</Message>
                                </Form.Field>
                            </Grid.Column>}
                            <Grid.Column textAlign={"right"}>
                                <Button onClick={() => this.manageModal(false)} basic primary>Cancel</Button>
                                <Button
                                    primary
                                    onClick={this.submit}
                                    loading={loading}
                                    disabled={loading || subject.length === 0 || message.length === 0}
                                >Submit</Button>
                            </Grid.Column>
                        </Grid>
                    </Modal.Content>
                </Modal>
            </Fragment>
        )
    }
}

export default connect(null, mapDispatchToProps)(Concierge);
