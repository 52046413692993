import React, {Component} from 'react';
import {connect} from "react-redux";
import {Route, Switch, withRouter} from "react-router-dom";
import GroupInfo from "./groupInfo";
import Eligibility from "./eligibility";
import ReviewPlan from "./reviewPlan";
import Sign from "./sign";
import Congratulations from "./congrats";
import ContractSent from "./contractSent";
import NextSteps from "./nextSteps";
import ScrollToTop from "../scrollToTop";
import PortalNotAvailable from '../pages/portalNotAvailable';


class AccountCreation extends Component {
    render() {
        const {match} = this.props
        return (
            <React.Fragment>
                <ScrollToTop/>
                <Switch>
                    <Route exact path={`${match.path}/employerInfo`} render={() => <GroupInfo nextRoute={`${match.path}/eligibility`}/>}/>
                    <Route exact path={`${match.path}/eligibility`} render={() => <Eligibility prevRoute={`${match.path}/employerInfo`} nextRoute={`${match.path}/review`}/>}/>
                    <Route exact path={`${match.path}/review`} render={() => <ReviewPlan prevRoute={`${match.path}/eligibility`} nextRoute={`${match.path}/docusign`}/>}/>
                    <Route exact path={`${match.path}/docusign`} render={() => <Sign prevRoute={`${match.path}/review`} nextRoute={`${match.path}/success`}/>}/>
                    <Route exact path={`${match.path}/success`} render={() => <Congratulations nextRoute={`${match.path}/home`}/>}/>
                    <Route exact path={`${match.path}/contractSent`} render={() => <ContractSent/>}/>
                    <Route exact path={`${match.path}/nextSteps`} render={() => <NextSteps/>}/>
                    <Route exact path={`${match.path}/portalNotAvailable`} render={() => <PortalNotAvailable nextRoute={`${match.path}/home`}/>}/>
                </Switch>
            </React.Fragment>
        )
    }
}

export default connect()(withRouter(AccountCreation));
