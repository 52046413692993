import {billingActionTypes} from "./actionTypes";

export function setBillingData(billingData) {
    return {
        type: billingActionTypes.SET_BILLING_DATA,
        payload: billingData
    }
}

export function setIsNotAvailableToPay(isNotAvailableToPay) {
    return {
        type: billingActionTypes.SET_IS_NOT_AVAILABLE_TO_PAY,
        payload: isNotAvailableToPay
    }
}