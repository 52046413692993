import React, {Component} from 'react';
import {Dropdown, Grid, Icon, Menu, Loader} from "semantic-ui-react";
import {connect} from "react-redux";
import axios from "axios";
import {logout} from "../../actions/authActions";
import {StrategicPartnerLogo} from "../common";
import { GetHelpMenu } from '../menu/getHelpMenu'

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        username: state.auth.user.username,
    }
};

class AccountCreationHeader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            logoutStatus: false,
        }
    }

    async backendLogout() {
        try {
            this.setState({logoutStatus: true})
            await axios.post("/api/employer/v2/logout", null, {headers: {'Content-Type': 'application/json'}});
        } catch (e) {
            throw new Error("An error occured while loggin out")
        } finally {
            this.setState({logoutStatus: false})
        }
    }

    async nukeStore() {
        const {dispatch} = this.props;
        await this.backendLogout();
        dispatch(logout())
        this.props.history.push({pathname: "/login"});
    }

    render() {
        const {logoutStatus} = this.state;
        const {prevRoute, history, centerTitle, username} = this.props;
        const backArrowButton = (
            <div onClick={() => history.push(prevRoute)} style={{marginLeft: 15, cursor: "pointer"}}>
                <Icon name='arrow left'/>
            </div>
        );

        const joinBrellaIcon = <StrategicPartnerLogo />;

        return (
            <div className='employer-createAccount-headerContainer'>
                <Grid>
                    <Grid.Column width={4} textAlign={'left'} style={{padding: '0 0 0 24px', display: "flex", alignItems: "center"}}>
                        {prevRoute ? backArrowButton : joinBrellaIcon}
                    </Grid.Column>
                    <Grid.Column width={4} textAlign={'center'}>
                        {centerTitle && <b>{centerTitle}</b>}
                    </Grid.Column>
                    <Grid.Column textAlign={'right'} width={4}>
                        <GetHelpMenu />
                        <span style={{paddingLeft: '10px', paddingRight: '10px', color: '#ddd'}}>|</span>
                        <Dropdown item text={username} className="menuUserDropdown" direction='left' closeOnChange={false}>
                            <Dropdown.Menu style={{padding: '0.5em 0'}} open={logoutStatus}>
                                <Dropdown.Item as={Menu.Item} onClick={() => this.nukeStore()}>
                                    {logoutStatus
                                      ? <Loader active inline >
                                          <span style={{fontWeight: 'normal'}}>Logging out</span>
                                      </Loader>
                                      : 'Log out'
                                    }
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Grid.Column>
                </Grid>
            </div>
        )
    }
}

export default connect(mapStateToProps)(AccountCreationHeader);
