import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { resolveStepUrl } from '../../../utils/common'
import { setLogin, logout } from '../../../actions/authActions'
import StaticFooter from '../../staticFooter'
import STRATEGIC_PARTNER from '../../../constants/strategicPartners'
import { theme } from './theme'
import {
  Layout,
  Content,
  Footer,
  Brand,
  Slogan,
  Welcome,
  Title,
  Subtitle,
  Form,
} from './styled'

const Login = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    dispatch(logout())

    const urlSearchParams = new URLSearchParams(window.location.search);
    const authToken = urlSearchParams.get('t');

    if (!authToken) return

    const contractSfId = urlSearchParams.get('cSfId');
    const step = urlSearchParams.get('s');
    const username = urlSearchParams.get('n');

    setLoading(true)

    dispatch(setLogin({ authToken, contractSfId, step, username }));
    history.push({pathname: resolveStepUrl(step)});
  }, [dispatch, history])

  return (
    <ThemeProvider theme={theme}>
    <Layout loading={loading}>
      <Layout.InfoPanel>
        <Brand>
          <Brand.Desktop />
          <Brand.Mobile />
        </Brand>
        <Layout.Info>
          <Slogan>
            Everything you need to administer {STRATEGIC_PARTNER.LABEL}.
          </Slogan>
          <Welcome>Right this way—</Welcome>
        </Layout.Info>
      </Layout.InfoPanel>
      <Layout.ContentPanel>
        <Content>
          <Title>Employer Portal Login</Title>
          <Subtitle>Enter your details to access your {STRATEGIC_PARTNER.LABEL} <span>portal—</span></Subtitle>
          <Form />
        </Content>
        <Footer>
          <StaticFooter />
        </Footer>
      </Layout.ContentPanel>
    </Layout>
  </ThemeProvider>
  )
}

export default Login
