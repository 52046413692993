import React, {Component} from 'react'
import {connect} from 'react-redux'
import {NavLink} from 'react-router-dom'
import {Icon} from 'semantic-ui-react'
import {parseDate} from '../../../utils/date'
import {S3Image, CustomContainer} from "../../custom-common";
import IMAGE from "../../../constants/images";

const mapStateToProps = (state, ownProps) => {
    const oe = state.openEnrollment || {}

    return {
        ...ownProps,
        status: oe.status,
        enrollmentStartDate: parseDate(oe.enrollmentStartDate),
        enrollmentEndDate: parseDate(oe.enrollmentEndDate),
        policyEffectiveDate: oe.renewedContract.policyEffectiveDate,
    }
}

class SetupBanner extends Component {

    get contents() {
        const {
            status,
            policyEffectiveDate,
            enrollmentStartDate,
            enrollmentEndDate,
        } = this.props

        switch (status) {
            case 'open':
                return {
                    title: 'Set up open enrollment',
                    description: `Your enrollment for coverage effective ${parseDate(policyEffectiveDate)} needs to be configured.`,
                    link: 'Get started',
                    icon: IMAGE.ICON_GEARS,
                }

            case 'finished':
            case 'closed':
                return {
                    title: 'Open enrollment has<br/>ended—',
                    description: 'Review your annual enrollment summary.',
                    link: 'See summary',
                    icon: IMAGE.SUCCESS_CONFETTI,
                }

            default:
                return {
                    title: 'Reminder',
                    description: `Your annual open enrollment window is set for ${enrollmentStartDate} to ${enrollmentEndDate}.`,
                    link: 'See details',
                    icon: IMAGE.STOPWATCH,
                }
        }
    }

    render() {
        const {status} = this.props

        if (status === 'unavailable') return null

        return (
            <CustomContainer basic style={{background: '#fafafa', fontSize: '12px', padding: '12px 16px'}}>
                <div><b dangerouslySetInnerHTML={{__html: this.contents.title}}/></div>
                <p>{this.contents.description}</p>
                <NavLink exact to="/portal/open-enrollment">
                    {this.contents.link}<Icon name='long arrow alternate right' style={{marginLeft: '.5rem'}}/>
                </NavLink>
                <S3Image {...this.contents.icon} width={100} style={{margin: '20px 0 0 auto', maxWidth: '100px'}}/>
            </CustomContainer>
        )
    }
}

export default connect(mapStateToProps)(SetupBanner)
