import moment from 'moment';

export const getStepProps = (activeStepIdx) => {
    const stepProps = [
        {
            active: false,
            completed: false,
            disabled: true,
            label: "Sign up",
        }, {
            active: false,
            completed: false,
            disabled: true,
            label: "Employer info",
        }, {
            active: false,
            completed: false,
            disabled: true,
            label: "Eligibility",
        }, {
            active: false,
            completed: false,
            disabled: true,
            label: "Review",
        }, {
            active: false,
            completed: false,
            disabled: true,
            label: "Sign and submit",
        }
    ];

    for (let i = 0; i < activeStepIdx; i++) {
        stepProps[i].disabled = false;
        stepProps[i].completed = true;
        stepProps[i].active = true;
    }
    if (activeStepIdx === 0 ) {
        stepProps[activeStepIdx].active = true;
    }
    stepProps[activeStepIdx].disabled = false;
    return stepProps;
}

export function resolveStepUrl(step) {
    switch (step) {
        case "success":
            return "accountCreation/success"
        case "docusign":
            return "accountCreation/docusign"
        case "contractSent":
            return "accountCreation/contractSent"
        case "employerInfo":
            return "accountCreation/employerInfo"
        case "portal":
            return "/portal"
        case "portalNotAvailable":
            return "accountCreation/portalNotAvailable"
        default:
            return null
    }
}

export function readFileAsync(file) {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
    })
}

export const getTerminationStepProps = (activeStepIdx) => {
    const stepProps = [
        {
            active: false,
            completed: false,
            disabled: true,
            label: "Termination details",
        }, {
            active: false,
            completed: false,
            disabled: true,
            label: "Review and confirm",
        }
    ];

    for (let i = 0; i < activeStepIdx; i++) {
        stepProps[i].disabled = false;
        stepProps[i].completed = true;
        stepProps[i].active = true;
    }
    if (activeStepIdx === 0) {
        stepProps[activeStepIdx].active = true;
    }
    stepProps[activeStepIdx].disabled = false;
    return stepProps;
}

export function formatDate(date, defaultValue = "", format = "MM/DD/YYYY") {
    if (!date) {
        return defaultValue
    }
    return moment.utc(date).format(format)
}

export const MFA_ROUTE = "/mfa";
export const LOGIN_ROUTE = "/login";
export const PLAN_ROUTE = "/portal";
export const ACCOUNT_ROUTE = "/account";

const mdyDateRegex = /^(?:0[1-9]|1[012])\/(?:0[1-9]|[12][0-9]|3[01])\/(?:19\d{2}|20\d{2})$/

export const convertDisplayDateFormatToServer = (dateStr) => {
    if (!mdyDateRegex.test(dateStr)) return dateStr;
    const [month, day, yyyy] = dateStr.split("/");
    const mm = (`0${+month}`).slice(-2);
    const dd = (`0${+day}`).slice(-2);
    return `${yyyy}-${mm}-${dd}`;
}

export const isEvenNumber = (num) => num & 2 === 0;

export const formatPartialDate = (partialDateStr, prevValue) => {
    let response = partialDateStr;
    const userIsAddingCharacters = !prevValue || partialDateStr.length > prevValue.length;
    if (userIsAddingCharacters) {
        if (partialDateStr.length === 2) {
            response = partialDateStr + "/";
        } else if (partialDateStr.length === 5) {
            response = partialDateStr + "/";
        }
    } else {
        if (partialDateStr.length === 2) {
            response = partialDateStr.substring(0, 1)
        } else if (partialDateStr.length === 5) {
            response = partialDateStr.substring(0, 4)
        }
    }

    return response.substring(0, 10);
}