import React from 'react';
import { Header, Input, Label } from 'semantic-ui-react'
import { MFAHeading, MFAText, MFAGrid } from '../../styled';
import styled from 'styled-components';

import {
    MFAMethodContainer,
    MFAMethodTitle,
    MFAMethodComingSoon,
    MFAContainer,
    MFAForm,
    MFALabel
  } from "../../styled";


const MethodItem = ({title, disabled}) => {
    return (
        <MFAMethodContainer method={disabled ? disabled.toString() : 'false'}>
            <MFAMethodTitle as="h5" method={disabled ? disabled.toString() : 'false'} className={disabled ? "disabled": null}>{title}</MFAMethodTitle>
            {!disabled ? (
                <p>Use an authenticator app to generate authentication codes.</p>
            ) : (
                <MFAMethodComingSoon>Coming soon</MFAMethodComingSoon>
            )}
        </MFAMethodContainer>
    )
}

const MethodItemStyled = styled(MethodItem)`
    display: ${props => props.disabled ? 'flex' : 'block'};
    color: #757575!important;
    font-weight: 400!important;

`

const MethodSelector = ({methodType, selectMethodHandler}) => {
    return (
        <>
        <MFAContainer className="sectionWrapper" maxWidth={"530px"}>
            <MFAGrid>
                <MFAGrid.Row>
                    <MFAGrid.Column>
                        <MFAHeading as="h2">Select your method</MFAHeading>
                        <MFAText>Choose one of the two-factor authentication methods below—</MFAText>
                    </MFAGrid.Column>
                </MFAGrid.Row>
                <MFAGrid.Row>
                        <MFAGrid.Column>
                            <MFAForm className={'methodsList'}>
                                <MFAForm.Group grouped>
                                    <MFAGrid padded={false}>
                                        <MFAGrid.Row className="noVerticalPadding">
                                            <MFAGrid.Column>
                                                <MFAForm.Field inline className="methodField" onClick={() => selectMethodHandler('TOTP')}>
                                                    <Input
                                                        control='input'
                                                        type='radio'
                                                        name='method'
                                                        id="authenticator"
                                                        checked={methodType === 'TOTP'}
                                                        />
                                                    <MFALabel className="methodLabel"  htmlFor="authenticator"><MethodItemStyled title={"Authenticator app"} /></MFALabel>
                                                </MFAForm.Field>
                                            </MFAGrid.Column>
                                        </MFAGrid.Row>
                                        <MFAGrid.Row>
                                            <MFAGrid.Column>
                                                <Header className="methodsListHeader" as="h5">OR</Header>
                                            </MFAGrid.Column>
                                        </MFAGrid.Row>
                                        <MFAGrid.Row className="noPadding">
                                            <MFAGrid.Column>
                                                <MFAForm.Field inline className="methodFieldDisabled" onClick={null}>
                                                    <Input
                                                        control='input'
                                                        type='radio'
                                                        name='method'
                                                        id='email'
                                                        checked={methodType === 'EMAIL'}
                                                        disabled
                                                        />
                                                    <MFALabel className="methodLabel" htmlFor="email"><MethodItemStyled title={"Email"} disabled={true} /></MFALabel>
                                                </MFAForm.Field>
                                            </MFAGrid.Column>
                                        </MFAGrid.Row>
                                        <MFAGrid.Row className="noPadding">
                                            <MFAGrid.Column>
                                                <MFAForm.Field inline className="methodFieldDisabled"  onClick={null}>
                                                    <Input
                                                        control='input'
                                                        type='radio'
                                                        name='method'
                                                        id='sms'
                                                        checked={methodType === 'SMS'}
                                                        disabled />
                                                    <MFALabel className="methodLabel"  htmlFor="sms"><MethodItemStyled title={"Text message (SMS)"} disabled={true} /></MFALabel>
                                                </MFAForm.Field>
                                            </MFAGrid.Column>
                                        </MFAGrid.Row>
                                    </MFAGrid>
                                </MFAForm.Group>
                            </MFAForm>
                        </MFAGrid.Column>
                    </MFAGrid.Row>
                </MFAGrid>
            </MFAContainer>
        </>
    )
}

export default MethodSelector;