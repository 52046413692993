import React from 'react'
import styled, { css } from 'styled-components'
import LoginForm from './loginForm'
import { from, to } from '../../../design-system/breakpoints'

const scope = 'Login'

const mobileFrame = css`
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 516px;
  margin-left: auto;
  margin-right: auto;
  `

const contentFrame = css`
  width: 100%;
  max-width: 576px;
  padding-left: 30px;
  padding-right: 30px;
  margin: auto;
`

export const Layout = styled(({ loading, ...props }) => <div {...props} />)`
  display: flex;

  ${({ loading }) => loading && css`
    position: relative;
    pointer-events: none;
    user-select: none;

    &::after {
      content: '';
      position: absolute;
      inset: 0;
      background: rgba(255, 255, 255, .5);
    }
  `}

  ${to.mobile`
    flex-direction: column;
  `}

  ${from.tablet`
    width: 100%;
    height: 100vh;
    overflow: hidden;
  `}
`

Layout.displayName = `${scope}/Layout`

export const LayoutInfoPanel = styled.div`
  color: ${({ theme }) => theme.panel.foreground};
  height: 100%;
  overflow: auto;

  ${to.mobile`
    ${mobileFrame}
    padding-top: 50px;
    color: ${({ theme }) => theme.panel.background};
  `}

  ${from.tablet`
    padding-top: max(8.4vh, 20px);
    padding-left: 65px;
    padding-right: 65px;
    padding-bottom: 20px;
    width: 50%;
    max-width: 730px;
    background: ${({ theme }) => `url(${theme.panel.backgroundImage}) no-repeat center center ${theme.panel.background}`};
    background-size: cover;
    color: ${({ theme }) => theme.panel.foreground};
  `}
`
LayoutInfoPanel.displayName = `${scope}/Layout.InfoPanel`
Layout.InfoPanel = LayoutInfoPanel

export const LayoutInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: auto;
  flex-grow: 1;
  flex-shrink: 0;

  ${from.tablet`
    padding-top: 20px;
    padding-bottom: 180px;
  `}
`
LayoutInfo.displayName = `${scope}/Layout.Info`
Layout.Info = LayoutInfo

export const LayoutContentPanel = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;

  ${to.mobile`
    flex: 1 1 auto;
  `}

  ${from.tablet`
    flex: 1;
    height: 100vh;
  `}
`
LayoutContentPanel.displayName = `${scope}/Layout.ContentPanel`
Layout.ContentPanel = LayoutContentPanel

export const Content = styled.div`
  ${contentFrame}
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding-top: 20px;
  padding-bottom: 20px;

  ${to.mobile`
    ${mobileFrame}
  `}
`
Content.displayName = `${scope}/Content`

export const Footer = styled.div`
  ${contentFrame}

  .ui.grid {
    margin-top: 0;
    margin-bottom: 0;
  }

  .ui.menu {
    font-size: 12px;
    line-height: 1.5;

    .item:not(a) {
      font-size: 14px;
    }

    a.item {
      font-weight: bold;
      color: ${({ theme }) => theme.footer.links.idle};
    }
  }

  ${to.mobile`
    ${mobileFrame}
    margin-top: 50px;
  `}
`
Footer.displayName = `${scope}/Footer`

export const Brand = styled.div`
  width: 100%;
  display: block;
  min-height: 80px;
`
Brand.displayName = `${scope}/Brand`

export const BrandDesktop = styled.img.attrs(({ theme }) => ({
  src: theme.brand.invertedLogo,
  alt: theme.brand.alt,
  width: theme.brand.width,
}))`
  ${to.mobile`
    display: none;
  `}
`
BrandDesktop.displayName = `${scope}/Brand.Desktop`
Brand.Desktop = BrandDesktop

export const BrandMobile = styled.img.attrs(({ theme }) => ({
  src: theme.brand.logo,
  alt: theme.brand.alt,
  width: theme.brand.width,
}))`
  ${from.tablet`
    display: none;
  `}
`
BrandMobile.displayName = `${scope}/Brand.Mobile`
Brand.Mobile = BrandMobile

export const Slogan = styled.h1`
  position: relative;
  font-size: 36px;
  line-height: 1.3;
  font-family: ${({ theme }) => theme.slogan.fontFamily};

  ${to.mobile`
  max-width: 340px;
    color: ${({ theme }) => theme.slogan.color};
  `}

  ${to.mobile`
    padding-top: 57px;
    font-size: 32px;
  `}
`
Slogan.displayName = `${scope}/Slogan`

export const Welcome = styled.div`
  margin-top: 16px;
  font-size: 28px;
  line-height: 1.2;

  ${to.mobile`
    display: none;
  `}
`
Welcome.displayName = `${scope}/Welcome`

export const Title = styled.div`
  font-size: 24px;
  line-height: 1.5;
  font-family: 'EuclidCircularA-SemiBold', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  color: ${({ theme }) => theme.title.color};
`
Title.displayName = `${scope}/Title`

export const Subtitle = styled.div`
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 50px;

  > span {
    white-space: nowrap;
  }
`
Subtitle.displayName = `${scope}/Subtitle`

export const Form = styled(LoginForm)`
  margin-top: 50px;
`
LoginForm.displayName = `${scope}/Form`