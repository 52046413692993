import React from "react";
import moment from "moment";

export const displayDateFormat = "MM/DD/YYYY";

export function formatMoney(value) {
    if (value === null || value === undefined || value === 0) {
        return "--"
    }

    value = (Math.round(value * 100)/100).toFixed(2)
    return `$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace(/\.00$/, "")}`
}

export function formatMoneyFixedTo2(value) {
    if (value === null || value === undefined || value === 0) {
        return "--"
    }

    value = (Math.round(value * 100)/100).toFixed(2)
    return `$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
}

export function strFormatMoney(value) {
    if (value === null || value === undefined || value === 0) {
        return "--"
    }
    return `$${value.replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace(/\.00$/, "")}`
}

export function formatMontlyCost(value, suffix="/mo") {
    let str = formatMoney(value)
    if (str !== "--"){
        str += suffix
    }
    return str
}

export function formatPlanIncrementList(increments) {
    return increments.map(value => {return formatMoney(value)}).join(" / ")
}


export const interpolateString = (str, [...args]) => {
    args.forEach((a, idx) => {
        const key = "$" + (idx + 1);
        while (str.indexOf(key) > -1) {
            str = str.replace(key, a);
        }
    });
    return str;
};

export const parseMultilineString = (str, args = null) => {
    if (args !== null) {
        str = interpolateString(str, args);
    }
    const lines = str.split("\n");
    const linesCount = lines.length;
    return lines.map((line, idx) => {
        const nbsps = line.split("&nbsp;");
        return <React.Fragment key={line}>{nbsps.join('\u00A0')}{idx < linesCount-1 && <br/>}</React.Fragment>
    });
};

export const resolveErrorMessage = (e, defaultError) => e.response?.data?.errorMessage || e.errorMessage || defaultError;

export function resolveTier(value) {
    switch (value) {
        case "employee":
            return "Employee only"
        case "spouse":
            return "Employee + Spouse"
        case "child":
            return "Employee + Child(ren)"
        case "family":
            return "Family"
        default:
            return "--"
    }
}

export function resolveTierShort(value) {
    switch (value) {
        case "employee":
            return "EE"
        case "spouse":
            return "EE+SP"
        case "child":
            return "EE+CH"
        case "family":
            return "EE+FAM"
        default:
            return "-"
    }
}

export function dowloadBase64(fileName, contentType, data) {
    const element = document.createElement("a")
    element.href = `data:${contentType};base64,${data}`;
    element.download = fileName;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element)
}

export function dowloadBlob(fileName, contentType, data) {
    const element = document.createElement("a")
    element.href = window.URL.createObjectURL(new Blob([data], { endings: 'native', type: `${contentType};charset=utf-8` }))
    element.download = fileName;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element)
}

const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const isValidEmail = email => {
    if (!email || typeof email !== 'string' || /^\s|\s$/.test(email)) {
        return false;
    }
    return emailPattern.test(email);
};

export function buildFullName(firstName, middleName, lastName, suffix) {
    let fullName = []
    if (firstName) {
        fullName.push(firstName)
    }
    if (middleName) {
        fullName.push(middleName)
    }
    if (lastName) {
        fullName.push(lastName)
    }
    if (suffix) {
        fullName.push(suffix)
    }
    return fullName.join(" ")
}

export function formatDate(date, defaultValue = "", format= displayDateFormat) {
    if (!date) {
        return defaultValue
    }
    return moment.utc(date).format(format)
}

export const errorStyle = {color: '#D82000'};

export const validateFloat = (value, acceptPartial) => {
    if (/^(\d*|\d+.\d{1,2})$/.test(value) === false) {
        // validate partial / intermediate
        return acceptPartial ? /^\d+\.$/.test(value) : false;
    }

    return true;
}

export function scrollToTop() {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
}

export const delay = ms => new Promise(resolve => setTimeout(resolve, ms))

// Blob download in browser
// Source: https://github.com/kennethjiang/js-file-download/blob/master/file-download.js
export const downloadBlob = (data, filename, mime = 'application/octet-stream') => {
    const blob = new Blob([data], {type: mime});
    const blobURL = (window.URL && window.URL.createObjectURL) ? window.URL.createObjectURL(blob) : window.webkitURL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none'
    tempLink.href = blobURL
    tempLink.setAttribute('download', filename)

    // Safari thinks _blank anchor are pop-ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') tempLink.setAttribute('target', '_blank')

    document.body.appendChild(tempLink)
    tempLink.click()

    // Fixes "webkit blob resource error 1"
    setTimeout(() => {
        document.body.removeChild(tempLink)
        window.URL.revokeObjectURL(blobURL)
    }, 200)
}

export function isEmpty (data) {
    return !Object.keys(data).length
}
