import React from 'react'
import { usePlaidLink } from 'react-plaid-link';
import { Button } from 'semantic-ui-react';

export const PlaidButton = ({ children, fetching, token, env, onSuccess, ...props }) => {
    const config = {
        token,
        env,
        onSuccess
    };
    const { open, ready, loading } = usePlaidLink(config);
    const disabled = fetching || loading || !ready

    return (
        <Button
            basic primary
            disabled={disabled}
            loading={disabled}
            onClick={() => open()}
            {...props}
        >{children}</Button>
    )
}
