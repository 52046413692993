import React, {Component} from 'react';
import {Button, Checkbox, Modal, Grid} from 'semantic-ui-react'
import {CustomModalHeader} from "../custom-common";

class ConfirmAccount extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            ackSigningAuthority: false,
            loading: false,
        };

        this.required = ['ackSigningAuthority'];
    }

    closeConfigShow = (closeOnEscape, closeOnDimmerClick, open) => () => {
        this.setState({closeOnEscape, closeOnDimmerClick, open})
    };

    close = () => this.setState({open: false});

    handleChangeCheckbox = (e, {name}) => {
      this.setState({[name]: !this.state.ackSigningAuthority});
    }

    submit = () => {
      this.props.handleSubmit();
      this.close();
    }

    validate = () => {
        let isError = false;
        if(!this.state.ackSigningAuthority) {
          isError = true
        }
        return isError;
    }

    openModal = () => {
        this.close();
        this.props.onOpen(true);
    }

    render() {
        const {open, closeOnEscape, closeOnDimmerClick, ackSigningAuthority} = this.state;
        const {loading, text="Next step", employerName} = this.props;
        const hasErrors = this.validate();

        return (
            <React.Fragment>
                  <Button
                      onClick={this.closeConfigShow(true, true, true)}
                      primary
                      loading={loading}
                  >
                      {text}
                  </Button>


                <Modal open={open}
                       closeOnEscape={closeOnEscape}
                       closeOnDimmerClick={closeOnDimmerClick}
                       onClose={this.close}
                       size='tiny'
                >
                    <CustomModalHeader title={"Acknowledgment"} onClose={this.close}/>

                    <Modal.Content style={{borderBottomLeftRadius: '.25rem', borderBottomRightRadius: '.25rem'}}>
                        <Grid container columns={1}>
                            <Grid.Column>
                              <Checkbox 
                                  label={<label style={{lineHeight: '150%'}}>By clicking <b>Continue to sign</b>, you are acknowledging that you have signing authority on behalf of {employerName}.</label>}
                                  checked={ackSigningAuthority}
                                  onClick={this.handleChangeCheckbox}
                                  name="ackSigningAuthority"
                              />
                            </Grid.Column>
                            <Grid.Column textAlign={"right"}>
                                <Button onClick={this.close} basic primary>Cancel</Button>
                                <Button primary onClick={this.submit} disabled={hasErrors}>Continue to sign</Button>
                            </Grid.Column>
                        </Grid>
                    </Modal.Content>
                    <div style={{position: 'absolute', width: '100%', textAlign: 'center', paddingTop: '10px'}}>
                        <p><span style={{color: 'white'}}>or go back and</span> <span className="alternativeLinkText"
                                onClick={() => this.openModal()}
                            >designate a new signer</span>
                        </p>
                    </div>
                </Modal>
            </React.Fragment>
        )
    }

}

export default ConfirmAccount;