import React, { useCallback, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import {Button} from "semantic-ui-react";
import { PlaidLinkDialog } from './plaidLinkDialog';
import axios from "axios";

const PlaidMicroDepositVerificationLink = (props) => {
    const [ showLink, setShowLink ] = useState(false)
    const {accountId, updateStatus, setPlaidData, tooManyVerificationAttemptsCallback, hide} = props;

    const onSuccess = useCallback(async (_, data) => {
        const {public_token} = data
        const status = data.account?.verification_status
        const metadata = {"verificationStatus": status, "verifiedAt": (new Date()).toISOString()}
        await updateStatus({account_id: accountId, status, public_token})
        await setPlaidData({...data, metadata})
        hide(accountId)
    }, [accountId, updateStatus, setPlaidData, hide])

    const onExit = useCallback(async (error, metadata) => {
        if (!error) {
            return
        }
        if (error.error_code === 'TOO_MANY_VERIFICATION_ATTEMPTS'){
            const status = "verification_failed"
            updateStatus({account_id: accountId, status})
            tooManyVerificationAttemptsCallback(accountId)
        }
        console.log({error, metadata})
        try {
            await axios.post("/api/employer/v2/plaidError", {error, metadata}, {headers: {'Content-Type': 'application/json'}});
        } catch (e) {
            console.warn({e})
        }
    }, [accountId, updateStatus, tooManyVerificationAttemptsCallback]);

    const config = {
        token: props.plaidLinkToken,
        env: props.plaidEnv,
        onSuccess,
        onExit
    };

    const { open, ready, loading } = usePlaidLink(config);

    return (<>
        <Button className="link" disabled={!ready || loading || !!props.loading} onClick={open} basic primary>
            Verify account
        </Button>
        {showLink && <PlaidLinkDialog onClose={() => setShowLink(false)}/>}
    </>)
};
export default PlaidMicroDepositVerificationLink;
