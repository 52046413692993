import React, {Component} from "react"
import {Button, Dimmer, Input, Loader, Message, Modal} from "semantic-ui-react";
import axios from "axios";
import {CustomModalHeader} from "../../custom-common";

export class PlaidLinkDialog extends Component {
    state = {
        fetching: true,
        link: null,
        copied: false,
        error: null
    }
    timer = null
    ref = null

    async componentDidMount() {
        let newState = {}

        try {
            const {data: {link}} = await axios.post('/api/employer/v2/createAddPaymentMethodLink');
            newState = {link}
        } catch (e) {
            newState = {error: e.message}
        } finally {
            this.setState({
                ...newState,
                fetching: false
            })
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timer)
    }

    renderError = () => {
        const {error} = this.state

        return (
            <Message negative>
                <Message.Header>Error creating the link</Message.Header>
                {error && (<p>{error}</p>)}
                <p>Close this dialog and retry in a few minutes. If the problem persists, please contact the support
                    team.</p>
            </Message>
        )
    }

    handleCopyClick = () => {
        const {link} = this.state

        navigator.clipboard.writeText(link).then(() => {
            this.setState({copied: true})
        })

        this.timer = setTimeout(() => this.setState({copied: false}), 500)
    }

    handleFocus = () => {
        this.ref && this.ref.select()
    }

    render() {
        const {onClose} = this.props
        const {fetching, link, error, copied} = this.state

        if (fetching) return (
            <Dimmer page active>
                <Loader size='medium'>Generating Payment link</Loader>
            </Dimmer>
        )

        return (
            <Modal open onClose={onClose} style={{maxWidth: '525px'}}>
                <CustomModalHeader title={"Share a link"} onClose={onClose} isCloseVisible={false}/>
                <Modal.Content>
                    {!error ? (<>
                        <p>
                            Use this link to allow someone else in your organization to configure a new payment method:
                        </p>
                        {true ? (
                            <Input
                                fluid readOnly
                                ref={ref => {
                                    this.ref = ref
                                }}
                                defaultValue={link}
                                onFocus={this.handleFocus}
                            />
                        ) : (
                            <p style={{overflowWrap: 'anywhere'}}>
                                {link}
                            </p>
                        )}
                    </>) : this.renderError()}
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        basic
                        color="grey"
                        content="Close"
                        onClick={onClose}
                    />
                    <Button
                        primary
                        disabled={copied}
                        content="Copy link to clipboard"
                        onClick={this.handleCopyClick}
                    />
                </Modal.Actions>
            </Modal>
        )
    }
}
