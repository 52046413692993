import {toastActionTypes} from "../actions/actionTypes";

const initialState = {
    list: [],
};

export const toastReducer = (state = initialState, action) => {
    switch (action.type) {
        case toastActionTypes.SHOW:
            return {
                ...state,
                list: [
                    ...state.list,
                    {
                        id: Date.now() + Math.random(),
                        type: action.payload.type,
                        title: action.payload.title,
                        message: action.payload.message,
                        autodismissTime: action.payload.autodismissTime,
                        visible: true
                    }
                ]
            }
        case toastActionTypes.HIDE:
            return {
                ...state,
                list: state.list.map(toast =>
                    toast.id === action.payload.id ? {...toast, visible: false} : toast
                )
            }
        case toastActionTypes.CLEAR:
            return {
                ...state,
                list: state.list.filter(e => e.id !== action.payload.id)
            }
        case toastActionTypes.RESET:
            return {
                ...state,
                list: []
            }
        default:
            return state
    }
}