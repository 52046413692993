import React, { Component } from 'react'
import { Divider, Input, Message } from 'semantic-ui-react'
import OrderedTable from './orderedTable'
import {PROGRESS_STATUS_PROPERTIES} from "../constants";


const headers = {
    firstName: {
        label: 'First Name',
        sortable: true,
    },
    lastName: {
        label: 'Last Name',
        sortable: true,
    },
    email: {
        label: 'Email',
        sortable: true,
    },
    enrollment: {
        label: 'Enrollment',
        sortable: false,
    },
}

class Members extends Component {
    state = {
        search: '',
        sorting: {
            column: null,
            direction: null,
        },
    }

    handleChange = (_, { value = '' }) => {
        this.setState({ search: value })
    }

    get data() {
        const { search } = this.state
        const { members = [] } = this.props

        const filtered = search
            ? members.filter(({ firstName = '', lastName = '', email: rawEmail = '' }) => {
                const find = search.toLowerCase()
                const name = `${firstName} ${lastName}`.toLowerCase()
                const email = rawEmail.toLowerCase()

                return name.includes(find) || email.includes(find)
            })
            : members

        return filtered.map(member => {
            const statusProperties = PROGRESS_STATUS_PROPERTIES[member.status] || {};
            const enrollment = statusProperties
                ? (<><span style={{ color: statusProperties.dotColor, fontWeight: 'bold', marginRight: '8px' }}>
                        &#9679;
                    </span>{member.status}</>)
                : "-";

            return {
                ...member,
                enrollment,
            }
        })
    }

    get isEmpty () {
        const { members = [] } = this.props

        return !members.length
    }

    render() {
        const { search, sorting } = this.state

        if (this.isEmpty) return (
            <Message>
                There are no members in this group.
            </Message>
        )

        return (
            <div>
                <Input
                    fluid autoFocus
                    icon='search'
                    placeholder='Search by name or email...'
                    value={search}
                    onChange={this.handleChange}
                />
                <Divider hidden />

                {this.data.length ? (
                    <OrderedTable
                        data={this.data}
                        headers={headers}
                        sorting={sorting}
                    />
                ) : (
                    <Message>
                        There are no matches for <b>"{search}"</b>.
                    </Message>
                )}

            </div>
        )
    }
}

export default Members
