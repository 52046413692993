import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import {Loader} from "semantic-ui-react";
import {connect} from "react-redux";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import InvoiceHistory from "./InvoiceHistory";
import PreviewInvoice from "./PreviewInvoice";
import AccountSumaryCard from "./AccountSumaryCard";
import PaymentAuthorizationCard from "./PaymentAuthorizationCard";
import {CustomDivider} from '../../custom-common';

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        previewInvoice: state.billing.previewInvoice,
        historicalInvoices: state.billing.historicalInvoices,
        accountBalance: state.billing.accountBalance,
        currentBilling: state.billing.currentBilling,
        pastDue: state.billing.pastDue,
        today: state.billing.today,
        bankAccountsDropdown: state.bankAccount.bankAccountsDropdown,
        isSelfBill: state.billing.isSelfBill
    }
};

class Overview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: null
        }
    }

    render() {
        const {loading} = this.state;
        const {previewInvoice, historicalInvoices, accountBalance, switchToPaymentDetail, isSelfBill} = this.props;
        if (loading) return (
            <div style={{height: 'calc(100vh - 250px)'}}><Loader active/></div>
        )
        return (
            <>
                <Grid container stackable columns={2} style={{marginTop: 0}}>
                    <Grid.Column style={{paddingLeft: 0, paddingRight: '.5em'}}>
                        <AccountSumaryCard/>
                    </Grid.Column>
                    <Grid.Column style={{paddingLeft: 0, paddingRight: '.5em'}} className={"pageContent"}>
                        <PaymentAuthorizationCard accountBalance={accountBalance} switchToPaymentDetail={switchToPaymentDetail}/>
                    </Grid.Column>
                    {(previewInvoice && !isSelfBill) &&
                      <Grid.Row>
                          <PreviewInvoice previewInvoice={previewInvoice} isSelfBill={isSelfBill} />
                      </Grid.Row>
                    }
                </Grid>

                {historicalInvoices && historicalInvoices.length > 0 &&
                <div>
                    <Grid container stackable columns={1} centered>
                        <Grid.Column className={"pageContent"}>
                            <CustomDivider hidden/>
                            <React.Fragment>
                                <InvoiceHistory/>
                            </React.Fragment>
                        </Grid.Column>
                    </Grid>
                </div>
                }
            </>
        )
    }
}

export default connect(mapStateToProps)(withRouter(Overview));
