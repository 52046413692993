import React, { Component } from 'react'
import CanvasJSReact from '../../../../../assets/canvasjs/canvasjs.react'
import './styles.css'

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

class Doughnut extends Component {
    get config() {
        const { size = 400, data = [] } = this.props

        return {
            width: size,
            height: size * 1.2,
            toolTip: {
                fontColor: '#616161',
                fontWeight: '700',
                fontFamily: 'Raleway',
                size: 14,
                contentFormatter: (e) => `${e.entries[0].dataPoint.name}: &nbsp;${this.rate(e.entries[0].dataPoint.y)}%`,
            },
            data: [{
                type: 'doughnut',
                dataPoints: data,
                explodeOnClick: false,
            }],
        }
    }

    rate = value => {
        const { data = [] } = this.props

        const total = data.reduce((a, { y }) => a + y, 0)

        return value ? (value / total * 100).toFixed(1) : 0
    }

    render() {
        const { data = [] } = this.props

        if (!data.length) return null

        return (
            <div className='jb-doughnut'>
                <div className='jb-doughnut__chart'>
                    <CanvasJSChart options={this.config}/>
                </div>
                <div className='jb-doughnut__legends'>
                    <table>
                        <tbody>
                        {data.map(({ name, y }) => (
                            <tr key={`${name}-${y}`}>
                                <td className='jb-doughnut__value'>{y}</td>
                                <td className='jb-doughnut__label'>{name}</td>
                                <td className='jb-doughnut__percentage'>{this.rate(y)}%</td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default Doughnut
