import React, { Component } from 'react'
import classnames from 'classnames'
import './styles.css'

class Groups extends Component {
    handleClick = (e, group) => {
        const { onClick = () => {} } = this.props
        e.preventDefault()

        return onClick(group)
    }

    render() {
        const { groups } = this.props;

        if (!groups?.length) return null;

        return (
            <div className='jb-groups'>
                {groups.map(group => {
                    const { key, label, color, dotColor, background, value, active } = group

                    const classNames = classnames('jb-groups__group', {
                        'jb-groups__group--active': active
                    })

                    return (
                        <div key={key} className={classNames} onClick={e => this.handleClick(e, group)}>
                            <div className='jb-groups__pie' style={{ background, borderColor: color }}>
                                <span>{value}</span>
                            </div>
                            <div className='jb-groups__info'>
                                <div className='jb-groups__label'>
                                    <span style={{color: dotColor, fontWeight: 'bold', marginRight: '8px' }}>&#9679;</span>
                                    {label}
                                </div>
                                <div className='jb-groups__details'>
                                    <a href={`#${key}`}>
                                        {active ? 'Hide details' : 'Show details'}
                                    </a>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }
}

export default Groups
