import React, {Fragment} from "react";
import {Icon, Table, Popup} from "semantic-ui-react";
import {formatMoney} from "../utils/utils";
import {plansDescription} from "../utils/strings";
import {CustomDivider} from "./custom-common";

const CardHeader = ({title}) => (
    <Table.Header>
        <Table.Row>
            <Table.HeaderCell textAlign="center" className={'colorfulCardHeader'}>
                {title}
            </Table.HeaderCell>
        </Table.Row>
    </Table.Header>
);

const PayoutsView = ({moderate, severe, catastrophic}) => (
    <Table.Body>
        <Table.Row>
            <Table.Cell style={{paddingLeft: 0, paddingRight: 0}}>
                <Table>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell colSpan={3} style={{paddingBottom: 0, paddingLeft: 12}}
                                        className={"smaller"}>
                                <b>Payouts</b>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row textAlign="center" style={{fontSize: '12px'}}>
                            <Table.Cell style={{paddingBottom: 0, paddingRight: 0, paddingLeft: 6}}
                                        className={"neutral700Text smaller"}>
                                Moderate
                                <Popup
                                  trigger={<Icon name="info circle"/>}
                                  content={plansDescription.moderate}
                                  position='top left'
                                />
                            </Table.Cell>
                            <Table.Cell style={{paddingBottom: 0, paddingRight: 0, paddingLeft: 6}}
                                        className={"neutral700Text smaller"}>
                                Severe
                                <Popup
                                  trigger={<Icon name="info circle"/>}
                                  content={plansDescription.severe}
                                  position='top center'
                                />
                            </Table.Cell>
                            <Table.Cell style={{paddingBottom: 0, paddingRight: 0, paddingLeft: 6}}
                                        className={"neutral700Text smaller"}>
                                Catastrophic
                                <Popup
                                  trigger={<Icon name="info circle"/>}
                                  content={plansDescription.catastrophic}
                                  position='top right'
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row textAlign="left" style={{fontWeight: 'bold'}}>
                            <Table.Cell style={{paddingTop: '0'}}>
                                <b>{formatMoney(moderate)}</b>
                            </Table.Cell>
                            <Table.Cell style={{paddingTop: '0'}}>
                                <b>{formatMoney(severe)}</b>
                            </Table.Cell>
                            <Table.Cell style={{paddingTop: '0'}}>
                                <b>{formatMoney(catastrophic)}</b>
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Table.Cell>
        </Table.Row>
    </Table.Body>
);

const PersonalizationCard = ({preset, title}) => (
    <Fragment>
        <Table style={{marginBottom: 0, border: '1px solid #E0E0E0'}}>
            <CardHeader title={title}/>
            <PayoutsView {...preset} />
        </Table>
        <CustomDivider hidden/>
    </Fragment>
);

export default PersonalizationCard;
