import React, { Component } from 'react'
import { connect } from 'react-redux'
import { List } from 'semantic-ui-react'
import STRATEGIC_PARTNER from "../../../constants/strategicPartners";

const mapStateToProps = (state, ownProps) => {
    const classes = state.openEnrollment.renewedContract.ratedClasses || []

    const links = classes.map(({
        groupClass: { description: name },
        contractTerms: { employeeShoppingLink: url, employeeShoppingCode: code }
    }) => ({
        name, url, code
    }))

    return {
        ...ownProps,
        links
    }
}

class EducationExperienceLinks extends Component {
    renderSingular = (link) => {
        const { style } = this.props

        const { url, code } = link

        return (
          <div style={{ fontSize: '16px', marginTop: '8px', ...style }}>
                <b>Employee education link</b>
                <p style={{ marginTop: '8px' }}>
                    {!!url ? (<>
                        Your {STRATEGIC_PARTNER.LABEL} education experience link can be found <a href={url} target='_blank' rel='noopener noreferrer'>here</a>.
                        &nbsp;<em className='neutral900Text' style={{ fontWeight: '400' }}>Passcode: {code}</em>
                    </>) : (<>
                        Your {STRATEGIC_PARTNER.LABEL} education experience link is disabled for this class.
                    </>)}
                </p>
            </div>
        )
    }

    renderPlural = (links) => {
        const { style } = this.props

        return (
          <div style={{ fontSize: '16px', marginTop: '8px', ...style }}>
                <b>Employee education links</b>
                <div style={{ marginTop: '8px' }}>
                    Your {STRATEGIC_PARTNER.LABEL} education experience links can be found here:
                    <List style={{ marginTop: '0.5rem' }}>{links.map(({ name, url, code }, key) => (
                        <List.Item key={key}>
                            {!!url ? (<>
                                <List.Icon name='linkify' />
                                <List.Content>
                                        <a href={url} target='_blank' rel='noopener noreferrer'>{name}</a> <em className='neutral900Text' style={{ fontWeight: '400' }}> - Passcode: {code}</em>
                                </List.Content>
                            </>) : (<>
                                <List.Icon name='checkmark' />
                                <List.Content><b>{name}</b></List.Content>
                            </>)}
                        </List.Item>
                    ))}</List>
                </div>
            </div>
        )
    }

    render() {
        const { links } = this.props

        if (!links.length) return null

        return links.length === 1 ? this.renderSingular(links[0]) : this.renderPlural(links)
    }
}

export default connect(mapStateToProps)(EducationExperienceLinks)
