import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {Dropdown, Menu} from "semantic-ui-react";
import { StrategicPartnerLogo } from './common';
import STRATEGIC_PARTNER from "../constants/strategicPartners";
import Concierge from "./concierge";

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps
    }
};

class AppHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }

    manageModal = (value) => {
        this.setState({open: value})
    }

    render() {
        const {open} = this.state
        const { onlyBrella } = this.props;

        const menu = (
            <Menu.Menu position="right">
                <Dropdown item text="Get help" direction='left' className="menuUserDropdown">
                    <Dropdown.Menu style={{padding: '0.5em 0'}}>
                        <Dropdown.Item as={Menu.Item} onClick={() => this.manageModal(true)}>Message our
                            team</Dropdown.Item>
                        <Dropdown.Divider/>
                        <Dropdown.Item as={Menu.Item}>
                            <p><b>Need more?</b><br/>
                                Email sales@joinansel.com</p>
                            <Dropdown.Divider/>
                            <div style={{margin: '1em', fontWeight: 'normal'}}>
                                <p className={"small"}>Call us at <b>{STRATEGIC_PARTNER.CONTACT_SUPPORT.NUMBER}</b></p>
                                <p className={"smaller"}>
                                    <span>Mon-Fri 8am-8pm ET</span>
                                </p>
                            </div>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Menu.Menu>
        );

        return (
            <div className='menuContainer'>
            <Menu text>
                <Menu.Item style={{marginTop: '-5px'}}>
                <StrategicPartnerLogo />
                </Menu.Item>
                { !onlyBrella && menu}
                <Concierge open={open} onManage={this.manageModal}/>
            </Menu>

            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps)(AppHeader));
