import {portalModalHeaderActionTypes} from "../actions/actionTypes";

const initialState = {
    prevRoute: '',
};

export const portalModalHeaderReducer = (state = initialState, action) => {
    switch (action.type) {
        case portalModalHeaderActionTypes.SET_PREV_ROUTE:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state
    }
};
