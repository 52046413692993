import React, {Component} from 'react'
import {connect} from 'react-redux'
import PageLayout from './pageLayout'
import SetupBanner from './setupBanner'
import EditBanner from './editBanner'
import FinishedBanner from './finishedBanner'
import FormModal from './formModal'

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        status: state.openEnrollment.status
    }
}

class OpenEnrollment extends Component {
    state = {
        edit: false
    }

    handleOpenEdit = () => {
        this.setState({edit: true})
    }

    handleCloseEdit = () => {
        this.setState({edit: false})
    }

    renderBanner = () => {
        const {status} = this.props

        switch (status) {
            case 'open':
                return <SetupBanner onClick={this.handleOpenEdit}/>

            case 'finished':
            case 'closed':
                return <FinishedBanner onClick={this.handleOpenEdit}/>

            default:
                return <EditBanner onClick={this.handleOpenEdit}/>
        }
    }

    render() {
        const {edit} = this.state

        return (
            <PageLayout>
                {this.renderBanner()}
                {edit && <FormModal onClose={this.handleCloseEdit}/>}
            </PageLayout>
        )
    }
}

export default connect(mapStateToProps)(OpenEnrollment);
