import {bankAccountActionTypes, authActionTypes} from "../actions/actionTypes";

const initialState = {
    bankAccounts: [],
    bankAccountsDropdown: [],
    selectedBankAccount: null,
    pendingVerificationBankAccounts: []
};

export const bankAccountReducer = (state = initialState, action) => {
    switch (action.type) {
        case authActionTypes.LOGOUT:
            return initialState;
        case bankAccountActionTypes.SET_BANK_ACCOUNTS_DATA:
            const bankAccounts = action.payload
            return {
                ...state,
                bankAccounts: bankAccounts,
            }
        case bankAccountActionTypes.SET_BANK_ACCOUNTS:
            return {
                ...state,
                bankAccounts: action.payload
            }
        case bankAccountActionTypes.SET_BANK_ACCOUNT:
            const {payload} = action
            const {metadata} = payload
            if (metadata && metadata.verificationStatus && metadata.verificationStatus === "manually_verified") {
                payload.status = "manually_verified"
            }
            payload.isDefault = state.bankAccounts.length === 0
            return {
                ...state,
                bankAccounts: [...state.bankAccounts, payload],
            }
        case bankAccountActionTypes.SET_SELECTED_BANK_ACCOUNT:
            let selected = state.bankAccounts.find((bank) => bank.key === action.payload)
            if(!selected) selected = null;
            return {
                ...state,
                selectedBankAccount: selected
            }
        case bankAccountActionTypes.SET_PENDING_VERIFICATION_BANK_ACCOUNTS:
            const pendingVerificationBankAccounts = action.payload
            return {
                ...state,
                pendingVerificationBankAccounts: pendingVerificationBankAccounts,
            }
        case bankAccountActionTypes.SET_PENDING_VERIFICATION_BANK_ACCOUNT:
            return {
                ...state,
                pendingVerificationBankAccounts: [...state.pendingVerificationBankAccounts, action.payload],
            }
        case bankAccountActionTypes.SET_PENDING_VERIFICATION_BANK_ACCOUNT_FAILED:
            const updatedList = state.pendingVerificationBankAccounts.map((bank) => {
                if(bank.accountId === action.payload) {
                    bank.status = "verification_failed"
                }
                return bank;
            })

            return {
                ...state,
                pendingVerificationBankAccounts: updatedList
            }
        case bankAccountActionTypes.REMOVE_PENDING_VERIFICATION_BANK_ACCOUNT:
            const banks = state.pendingVerificationBankAccounts.filter((bank, i) => bank.accountId !== action.payload)

            return {
                ...state,
                pendingVerificationBankAccounts: banks
            }
        case bankAccountActionTypes.DELETE_BANK_ACCOUNT:
            const updatedBanksList = state.bankAccounts.filter((bank, i) => bank.stripeId !== action.payload)
            return {
                ...state,
                bankAccounts: updatedBanksList
            }
        case bankAccountActionTypes.SET_DEFAULT_BANK_ACCOUNT:
            const bankAccountId = action.payload
            const bankAccWithDefault = state.bankAccounts.map(e => {
                if (!!e.isDefault && e.isDefault === true) e.isDefault = false
                if (!!e.stripeId && e.stripeId === bankAccountId) e.isDefault = true
                return e
            })
            return {
                ...state,
                bankAccounts: bankAccWithDefault
            }
        default:
            return state
    }
}
