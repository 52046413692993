import {initialDataActionTypes} from "./actionTypes"

export function setInitialData(payload) {
    return {
        type: initialDataActionTypes.SET_INITIAL_DATA,
        payload: payload
    }
}

export function setFooter(payload) {
    return {
        type: initialDataActionTypes.SET_FOOTER,
        payload: payload
    }
}
