import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  MFAHeading,
  MFASegment,
  MFAText,
  MFAForm,
  MFALabel,
  MFAInput,
  MFAButton,
  MFAContainer,
  MFATextError,
  MFACheckbox
} from "../styled";

import {
  validateMfa,
  validateBackupCode,
  setNewToken,
} from "../../actions/authActions";
import STRATEGIC_PARTNER  from "../../constants/strategicPartners";

import {ClickableText} from "../../components/custom-common";


const MINIMUM_ATTEMPTS = 0;
const LEFT_ATTEMPTS_FOR_WARN = 3;

const MFACode = ({
  userName,
  mfaToken,
  redirectPath,
  setBackupCodes,
  history,
  dispatch,
  useBackUpCodes,
  redirectToLogin,
  rememberDeviceDays
}) => {
  const [mfaCode, setMfaCode] = useState("");
  const [mfaBackupCode, setMfaBackupCode] = useState("");
  const [validationErrors, setValidationErrors] = useState(false);
  const [validateMfaCodeError, setValidateMfaCodeError] = useState("");
  const [isMfaProcessing, setIsMfaProcessing] = useState(false);
  const [remainingTotpAttempts, setRemainingTotpAttempts] = useState(null);
  const [remainingBackupAttempts, setRemainingBackupAttempts] = useState(null);
  const [disableBtn, setDisableBtn] = useState(true);
  const [remember, setRemember] = useState(false);

  //Input Backup code handler and validation
  const codeHandler = (e) => {
    setValidationErrors(false);
    setValidateMfaCodeError("");
    e.currentTarget.value.length > 0 ? setDisableBtn(false) : setDisableBtn(true)

    if (e.currentTarget.name === "mfaCode") {
      let sanitizedValue = e.currentTarget.value
        .replace(/[^0-9.]/g, "")
        .replace(/(\..*)\./g, "$1");
      if (sanitizedValue.length > 6 || isNaN(sanitizedValue)) return;
      setMfaCode(sanitizedValue);
    } else {
      if (e.currentTarget.value.length > 10) return;

      setMfaBackupCode(e.currentTarget.value);
    }
  };


  //Submit code to mfa/login
  const submitCode = async () => {
    setValidateMfaCodeError("");
    setIsMfaProcessing(true);

    if (!useBackUpCodes) {
      if (mfaCode.length <= 5 || isNaN(mfaCode) || mfaCode === "") {
        setValidationErrors(true);
        setIsMfaProcessing(false);
        return false;
      }

      await validateMfa(userName, mfaCode, mfaToken, remember)
        .then(async (response) => {
          dispatch(setNewToken(response.data));
          //dispatch(setInitialData());
          history.push({ pathname: redirectPath });
        })
        .catch((error) => {
          const { errorMessage, remainingTotpCodeAttempts, remainingBackupCodeAttempts } =
            error?.response?.data;
          setIsMfaProcessing(false);
          setValidateMfaCodeError(errorMessage);
          if(remainingTotpCodeAttempts === MINIMUM_ATTEMPTS || remainingBackupCodeAttempts === MINIMUM_ATTEMPTS){
            redirectToLogin(true);
          }
          setRemainingTotpAttempts(remainingTotpCodeAttempts);
        });
    } else {
      if (mfaBackupCode.length < 10) {
        setValidationErrors(true);
        setIsMfaProcessing(false);
        return false;
      }

      await validateBackupCode(mfaBackupCode, mfaToken)
        .then(async (response) => {
          dispatch(setNewToken(response.data));
          //dispatch(setInitialData());
          history.push({ pathname: redirectPath });
        })
        .catch((error) => {
          const { errorMessage, remainingBackupCodeAttempts, remainingTotpCodeAttempts } =
            error.response.data;
          setIsMfaProcessing(false);
          setValidateMfaCodeError(errorMessage);
          if(remainingTotpCodeAttempts === MINIMUM_ATTEMPTS || remainingBackupCodeAttempts === MINIMUM_ATTEMPTS){
            redirectToLogin(true);
          }
          setRemainingBackupAttempts(remainingBackupCodeAttempts);
        });
    }
  };

  const changeToBackupCode = () => {
    setValidationErrors(false);
    setValidateMfaCodeError("");
    setRemainingTotpAttempts(null);
    setRemainingBackupAttempts(null);
    setBackupCodes(true);
    setDisableBtn(true);
  };
  return (
    <>
      <MFAText className={"alignLeft"}>
        {!useBackUpCodes
          ? "Enter the 6-digit authentication code from your authenticator app to continue—"
          : "Enter one of your backup codes to access your account—"}
      </MFAText>

      {(remainingTotpAttempts || remainingBackupAttempts) &&
        (remainingTotpAttempts <= LEFT_ATTEMPTS_FOR_WARN ||
          (remainingBackupAttempts <= LEFT_ATTEMPTS_FOR_WARN && useBackUpCodes)) && (
          <MFAText className={"invalidAttempt"} style={{ textAlign: "left" }}>
            <span>
              {!useBackUpCodes
                ? remainingTotpAttempts
                : remainingBackupAttempts}{" "}
              invalid attempt(s) remaining!
            </span>{" "}
            After your <br/>account will be locked for security purposes.{" "}
            {useBackUpCodes}
          </MFAText>
      )}
      <MFAForm.Field className={"contentLeft"}>
        <MFALabel className={"labelVerify"}>
          {!useBackUpCodes ? "6-digit authentication code" : "10-digit backup code"}
        </MFALabel>

        <MFAContainer className="changeMethodField">
          <MFAInput
            type="text"
            name={!useBackUpCodes ? "mfaCode" : "backupCode"}
            id={!useBackUpCodes ? "mfaCode" : "backupCode"}
            onChange={(e) => codeHandler(e)}
            value={!useBackUpCodes ? mfaCode : mfaBackupCode}
            maxLength={!useBackUpCodes ? "6" : "10"}
            required
            error={validationErrors}
            disabled={isMfaProcessing}
            className={"verifyInput"}
          />
          {validationErrors && <MFATextError> {!useBackUpCodes ? "Enter a 6-digit authentication code" : "Enter a 10-digit backup code"}</MFATextError>}
          {validateMfaCodeError && (
            <MFATextError className={'verifyError'}>
              Invalid code, please try again.
            </MFATextError>
          )}
        </MFAContainer>
        {!useBackUpCodes && (
        <MFAContainer className="changeMethodField">
          <MFACheckbox
            label={`Remember me on this device for ${rememberDeviceDays} days`}
            name={"mfaRemember"}
            id={"mfaRemember"}
            onChange={(e) => setRemember(!remember)}
            /* value={!useBackUpCodes ? mfaRemember : backupRemember} */
            className={"rememberDevice"}
          />

        </MFAContainer>
        )}
        <MFAButton
          style={{
            width: "100%",
            display: "inline-block",
            marginBottom: "16px",
          }}
          loading={isMfaProcessing}
          disabled={isMfaProcessing || disableBtn}
          onClick={() => {
            submitCode();
          }}
        >
          Submit
        </MFAButton>
      </MFAForm.Field>
      {!useBackUpCodes && (
        <span style={{ textAlign: "left" }}>
          <ClickableText
            onClick={() => {
              if (!isMfaProcessing) {
                changeToBackupCode();
              }
            }}
            small
          >
            Or, use a backup code
          </ClickableText>
        </span>
        )}
    </>
  );
};


const ValidateMFA = (props) => {
  const [useBackUpCodes, setBackupCodes] = useState(false);
  const { userName, mfaToken, redirectPath, loginPath, rememberDeviceDays } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const redirectToLogin = (isLocked) => {
    history.push({ pathname: loginPath, isLocked});
  };
  return (
    <>
      <MFAContainer
        maxWidth={"456px"}
        style={{ paddingTop: "125px", textAlign: "center" }}
      >
        <MFAHeading as="h2" textAlign="center">
          Authentication code required
        </MFAHeading>
        <MFASegment className={'verifySegment'}>
          <MFACode
            userName={userName}
            mfaToken={mfaToken}
            redirectPath={redirectPath}
            setBackupCodes={setBackupCodes}
            history={history}
            dispatch={dispatch}
            useBackUpCodes={useBackUpCodes}
            redirectToLogin={redirectToLogin}
            rememberDeviceDays={rememberDeviceDays}
          />
        </MFASegment>
        {useBackUpCodes && (
          <MFAContainer className="backupCodeText">
            <MFAText>
              Still having trouble? Contact us at{" "}
              <a href={`mailto:${STRATEGIC_PARTNER.CONTACT_SUPPORT.EMAIL}`}>{STRATEGIC_PARTNER.CONTACT_SUPPORT.EMAIL}</a> or call (888)
              290-1153.
            </MFAText>
          </MFAContainer>
        )}
        <MFAContainer className="backupCodeText">
          <ClickableText
            onClick={() => redirectToLogin()}
            small
          >
            Return to login screen
          </ClickableText>
        </MFAContainer>
      </MFAContainer>
    </>
  );
};

export default ValidateMFA;
