import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import {Menu, Segment} from "semantic-ui-react";
import {connect} from "react-redux";
import Overview from "./overview";
import PortalContainer from "../portalContainer";
import PolicyDocuments from "./policyDocuments";
import BillingAlert from "../billing/BillingAlert";
import { hasPolicyInformation } from '../../../selectors/contract';
import {CustomGrid, CustomDivider} from '../../custom-common';

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        contract: state.portal.contract,
        ageRangesPerClass: state.portal.general.ageRangesPerClass,
        hasPolicyInformation: hasPolicyInformation(state),
    }
};

class PlanInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeItem: "overview",
        }
    }

    handleItemClick = (e, {name}) => {
        this.setState({activeItem: name})
    }

    switchToOverview = () => {
        this.props.history.push('/portal/billing')
    }

    render() {
        const {activeItem} = this.state;
        const {contract, ageRangesPerClass, hasPolicyInformation} = this.props

        const showPolicyDocuments = hasPolicyInformation && contract.contract?.showDocumentsOnPortals

        return (
            <PortalContainer>
                <BillingAlert switchToOverview={this.switchToOverview}/>
                <CustomGrid container stackable columns={1} centered style={{marginTop: 0}} fitted={'horizontally'}>
                    <CustomGrid.Column>
                        <CustomDivider hidden/>
                        {contract && <>
                            <Menu text size={"small"} className={"internalMenu"}>
                                <Menu.Item
                                    data-qa-button_overview
                                    style={{fontWeight: 'bold'}}
                                    name='overview'
                                    active={activeItem === 'overview'}
                                    onClick={this.handleItemClick}
                                />
                                {showPolicyDocuments && (
                                    <Menu.Item
                                        style={{fontWeight: 'bold'}}
                                        name='policyDocuments'
                                        active={activeItem === 'policyDocuments'}
                                        onClick={this.handleItemClick}
                                    >Policy documents</Menu.Item>
                                )}
                            </Menu>
                            <Segment padded>
                                {activeItem === 'overview' && <Overview data={contract} ageRangesPerClass={ageRangesPerClass}/>}
                                {showPolicyDocuments && activeItem === 'policyDocuments' && <PolicyDocuments data={contract}/>}
                            </Segment>
                        </>}
                    </CustomGrid.Column>
                </CustomGrid>
            </PortalContainer>
        )
    }
}

export default connect(mapStateToProps)(withRouter(PlanInfo));
