import React, {Component} from 'react';
import {Button, Container, Form, Grid, Header, Loader, Message} from "semantic-ui-react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Steps from "../steps/steps";
import {setInitialData} from "../../actions/initialDataActions";
import {setGroupInfo} from "../../actions/groupActions";
import {getStepProps} from "../../utils/common";
import {groupActionTypes} from '../../actions/actionTypes';
import NumberFormat from 'react-number-format';
import Footer from "../footer";
import AccountCreationHeader from "./accountCreationHeader";
import ErrorEmptyFormInput from "../errorEmtpyFormInput";
import axios from 'axios/index';
import {CustomDivider} from '../custom-common';

const AFFILIATES_MAX_CHARACTER = 255;

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        group: state.accountCreation.group,
        initialData: state.accountCreation.initialData
    }
};

class GroupInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: '',
            stepProps: null,
            validationErrorsMap: new Map(),
            loading: true
        };
        this.required = ['taxId', 'legalEntityName', 'address', 'zipcode', 'city', 'state'];
        this.prevFein = "";
    }

    prepareEmployerInfo = async () => {
        const {dispatch} = this.props;
        try {
            let response = await axios.post("/api/employer/v2/prepareEmployerInfo");
            dispatch(setInitialData(response.data));
            dispatch(setGroupInfo(groupActionTypes.SET_INITIAL_GROUP_DATA, response.data));
            this.setState({loading: false});
        } catch (e) {
            console.warn(e);
            this.setState({loading: false});
        }
    }

    async componentDidMount() {
        const {states} = this.props.initialData;
        if (states.length === 0) {
            await this.prepareEmployerInfo();
        } else {
            this.setState({loading: false});
        }
        this.buildSteps();
        const {taxId, zipcode} = this.props.group;
        taxId && this.taxIdValidation(taxId);
        zipcode && this.zipCodeValidation(zipcode);
    }

    buildSteps = () => {
        this.setState({stepProps: getStepProps(1)});
    }

    handleChange = (event, {name, value}) => {
        const {dispatch} = this.props;
        if (name === 'SET_STATE') {
            const {states} = this.props.initialData;
            value = states.filter(state => value === state.text)[0];
            if (!value) return;
        }
        dispatch(setGroupInfo(name, value));
        this.setState({error: ''})
    };

    handleSubmit = () => {
        this.props.history.push({pathname: this.props.nextRoute})
    }

    getError = (key) => {
        const vem = this.state.validationErrorsMap;
        if (!!vem) {
            return vem.get(key);
        }
        return null;
    }

    setError = (key, msg) => {
        const {validationErrorsMap} = this.state;
        validationErrorsMap.set(key, msg)
        this.setState({
            validationErrorsMap,
        })
    }

    validate = () => {
        const {taxId, legalEntityName, address, zipcode, city, state} = this.props.group;
        return this.isTaxIdValid(taxId) && !this.isEmpty(legalEntityName) && !this.isEmpty(address) &&
            this.isZipCodeValid(zipcode) && !this.isEmpty(city) && !this.isEmpty(state)
    }

    taxIdValidation(value) {
        const {SET_TAX_ID} = groupActionTypes;
        if (!this.isTaxIdValid(value)) {
            this.setError(SET_TAX_ID, "Invalid FEIN");
        }
        this.handleChange(null, {name: SET_TAX_ID, value})
    }

    isTaxIdValid(value) {
        return value && value.trim().length === "XX-XXXXXXX".length
    }

    zipCodeValidation(value) {
        const {SET_ZIP_CODE} = groupActionTypes;
        if (!this.isZipCodeValid(value)) {
            this.setError(SET_ZIP_CODE, "Invalid Zip Code");
        }
        this.handleChange(null, {name: SET_ZIP_CODE, value})
    }

    affiliatesValidation = (event, {value}) => {
        const {SET_AFFILIATES} = groupActionTypes;
        if (value.length > AFFILIATES_MAX_CHARACTER) {
            this.setError(SET_AFFILIATES, `${AFFILIATES_MAX_CHARACTER} 250 characters max.`);
            return;
        }
        this.setError(SET_AFFILIATES, null);
        this.handleChange(null, {name: SET_AFFILIATES, value})
    }

    isZipCodeValid(value) {
        return value && value.trim().length === 5
    }

    isEmpty(val) {
        return (!val || val.length === 0);
    }

    render() {
        const {
            taxId,
            legalEntityName,
            address,
            zipcode,
            city,
            state,
            affiliatesSubsidiaries,
            dbaName
        } = this.props.group;
        const {prevRoute} = this.props;
        const {SET_TAX_ID, SET_ZIP_CODE, SET_AFFILIATES} = groupActionTypes;
        const hasErrors = !this.validate();
        const {stepProps, loading} = this.state;
        const nextButton = (
            <Button onClick={this.handleSubmit} disabled={hasErrors} primary>
                Next step
            </Button>
        )

        if (!stepProps) {
            return <Container><Loader active={true}/></Container>
        }

        const loader = <Loader active={true}/>

        const content = (
            <React.Fragment>
                <AccountCreationHeader prevRoute={prevRoute} history={this.props.history} centerTitle="Create account"/>
                <Container className={"employer-accountCreation-container"}>
                    <Grid stackable>
                        <Grid.Column>
                            <Steps stepProps={stepProps}/>
                        </Grid.Column>
                    </Grid>
                    <Grid centered>
                        <Grid.Column width={8}>
                            <Header as='h2' className={"colorfulText"} style={{marginBottom: 40}}>Employer information</Header>
                            <div className={"colorfulText"} style={{marginBottom: 8}}>General</div>
                            <Form>
                                <Form.Group widths='equal'>
                                    <Form.Field error={!!this.getError(SET_TAX_ID)}>
                                        <NumberFormat
                                            format={(value) => {
                                                //"##-#######"
                                                let firstPart = value.substr(0, 2);
                                                let secondPart = value.substr(2, 7);

                                                if (value.length > 9) {
                                                    return this.prevFein;
                                                }

                                                if (firstPart.length > 1) {
                                                    const prefixInValidRange = ((firstPart >= 1 && firstPart <= 6) || (firstPart >= 10 && firstPart <= 16) || (firstPart >= 20 && firstPart <= 27) ||
                                                        (firstPart >= 30 && firstPart <= 48) || (firstPart >= 50 && firstPart <= 68) || (firstPart >= 71 && firstPart <= 77) ||
                                                        (firstPart >= 80 && firstPart <= 88) || (firstPart >= 90 && firstPart <= 95) || (firstPart >= 98 && firstPart <= 99));

                                                    if (!prefixInValidRange) {
                                                        return this.prevFein;
                                                    }
                                                }

                                                let result = value;
                                                if (secondPart !== undefined && secondPart.length !== 0) {
                                                    result = `${firstPart}-${secondPart}`;
                                                }
                                                return result;
                                            }}
                                            placeholder="XX-XXXXXXX"
                                            name={SET_TAX_ID}
                                            required={true}
                                            fluid
                                            label={"FEIN"}
                                            value={taxId}
                                            customInput={Form.Input}
                                            onFocus={() => {
                                                if (!!this.getError(SET_TAX_ID)) {
                                                    this.setError(SET_TAX_ID, null);
                                                }
                                            }}
                                            onValueChange={values => this.props.dispatch(setGroupInfo(SET_TAX_ID, values.formattedValue))}
                                            onChange={(e) => {
                                                this.prevFein = e.target.value;
                                            }}
                                            onBlur={(e) => {
                                                this.taxIdValidation(e.target.value);
                                            }}
                                        />
                                        {!!this.getError(SET_TAX_ID) &&
                                        <small className={"warningRedText"}>{this.getError(SET_TAX_ID)}</small>}
                                    </Form.Field>
                                    <ErrorEmptyFormInput fluid required label='Legal entity name'
                                                         name='SET_LEGAL_ENTITY_NAME'
                                                         value={legalEntityName || ''}
                                                         onChange={this.handleChange}
                                    />
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <ErrorEmptyFormInput fluid required label='Street address'
                                                         name='SET_ADDRESS'
                                                         value={address || ''}
                                                         onChange={this.handleChange}
                                    />
                                    <ErrorEmptyFormInput fluid label='DBA name'
                                                         name='SET_DBA_NAME'
                                                         value={dbaName || ''}
                                                         onChange={this.handleChange}
                                    />
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <ErrorEmptyFormInput fluid required label='City'
                                                         name='SET_CITY'
                                                         value={city || ''}
                                                         onChange={this.handleChange}
                                    />
                                    <Form.Input disabled
                                                 label={'State'}
                                                 name={'SET_STATE'}
                                                 required={true}
                                                 defaultValue={(state) || ""}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Field width={6} error={!!this.getError(SET_ZIP_CODE)}>
                                        <NumberFormat format="#####" placeholder="XXXXX"
                                                      onFocus={() => {
                                                          if (!!this.getError(SET_ZIP_CODE)) {
                                                              this.setError(SET_ZIP_CODE, null);
                                                          }
                                                      }}
                                                      name={SET_ZIP_CODE}
                                                      required={true} fluid
                                                      label={'Zip Code'}
                                                      customInput={Form.Input}
                                                      onValueChange={value => this.props.dispatch(setGroupInfo(SET_ZIP_CODE, value.formattedValue))}
                                                      value={zipcode || ''}
                                                      onBlur={(e) => {
                                                          this.zipCodeValidation(e.target.value)
                                                      }}
                                        />
                                        {!!this.getError(SET_ZIP_CODE) &&
                                        <small className={"warningRedText"}>{this.getError(SET_ZIP_CODE)}</small>}
                                    </Form.Field>
                                </Form.Group>
                                <div className={"colorfulText"} style={{marginTop: 56}}>Affiliates/Subsidiaries</div>
                                <Form.TextArea fluid={"true"} name={'SET_AFFILIATES'}
                                               placeholder="Note here..."
                                               value={affiliatesSubsidiaries || ''}
                                               onChange={this.affiliatesValidation}
                                               error={!!this.getError(SET_AFFILIATES)}

                                />
                                {!!this.getError(SET_AFFILIATES) &&
                                <small className={"warningRedText"}>{this.getError(SET_AFFILIATES)}</small>}
                            </Form>
                            {this.state.error.length > 0 && <Message negative>{this.state.error}</Message>}
                        </Grid.Column>
                    </Grid>
                    <CustomDivider hidden section/>
                </Container>
                <Footer button={nextButton} showTopFooter hasToDisplayCarrierDisclaimer/>
            </React.Fragment>
        )

        return <div className="employer-main-container">{loading ? loader : content}</div>;
    }
}

export default withRouter(connect(mapStateToProps)(GroupInfo));
