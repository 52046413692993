import {authActionTypes, employeesActionTypes} from "../actions/actionTypes";

const initialState = {
  employees: [],
  totalActiveCount: 0,
  totalInactiveCount: 0,
  loadedActiveEmployees: [],
  loadedInactiveEmployees: [],
  loadingEmployees: false,
  loadingEmployeesFirstPage: false,
  loadingMoreEmployees: false,
  employeesError: null,
};

export const employeesReducer = (state = initialState, action) => {
  switch (action.type) {
    case employeesActionTypes.SET_EMPLOYEES:
      if (!state.employees) state.employees = []
      return {
        ...state,
        employees: [...state.employees, ...action.payload]
      }
    case employeesActionTypes.SET_TOTAL_ACTIVE_EMPLOYEES_COUNT:
      return {
        ...state,
        totalActiveCount: action.payload
      }
    case employeesActionTypes.SET_TOTAL_INACTIVE_EMPLOYEES_COUNT:
      return {
        ...state,
        totalInactiveCount: action.payload
      }
    case employeesActionTypes.SET_EMPLOYEES_FIRST_PAGE_LOADING:
      return {
        ...state,
        loadingEmployeesFirstPage: action.payload
      }
    case employeesActionTypes.SET_EMPLOYEES_ERROR:
      return {
        ...state,
        employeesError: action.payload
      }
    case authActionTypes.LOGOUT:
      return {
        initialState
      }
    default:
      return state
  }
}
