import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { Checkbox, Icon, Message, Modal, Button } from 'semantic-ui-react'
import { setData } from '../../../actions/openEnrollmentActions'
import { resolveErrorMessage } from '../../../utils/utils'
import Concierge from '../../concierge'
import STRATEGIC_PARTNER from '../../../constants/strategicPartners'
import {ClickableText, CustomModalHeader} from '../../custom-common'


const mapStateToProps = (state, ownProps) => {
  const oe = state.openEnrollment

  return {
      ...ownProps,
      openEnrollmentId: oe.openEnrollmentId,
      contractId: oe.renewedContract.contractId,
      confirmedEligibleEmployees: !!oe.confirmedEligibleEmployees,
  }
}

const mapDispatchToProps = dispatch => {
    return {
        setOpenEnrollmentData: data => dispatch(setData(data)),
    }
}

class ConfirmEligibleEmplyees extends Component {
    state = {
        fetching: false,
        error: null,
        showConfirmation: false,
        showConciergeModal: false,
    }

    handleChange = async () => {
        const { confirmedEligibleEmployees } = this.props

        if (confirmedEligibleEmployees) return

        this.setState({ showConfirmation: true })
    }

    handleConfirm = async () => {
        const {
            setOpenEnrollmentData,
            openEnrollmentId,
            contractId,
        } = this.props
        const { fetching } = this.state

        if (fetching) return

        this.setState({ fetching: true, showConfirmation: false, error: null })

        try {
            const payload = {
                contractId,
                openEnrollmentId,
                confirmedEligibleEmployees: true,
            }
            const response = await axios.post('/api/employer/v2/confirmOeeEligibleEmployees', payload);
            setOpenEnrollmentData(response.data.enrollmentEvent)
            this.setState({ fetching: false })
        } catch (e) {
            const error = resolveErrorMessage(e, 'Unable to save. Please try again or contact support.')
            this.setState({
                fetching: false,
                error,
            })
        }
    }

    handleModalClose = () => {
        this.setState({ showConfirmation: false })
    }

    renderConfirmation = () => {
        const { showConfirmation } = this.state

        if (!showConfirmation) return null

        return (
            <Modal open onClose={this.handleModalClose} style={{ maxWidth: '525px' }}>
                <CustomModalHeader title={"Eligible employees list"} onClose={this.handleModalClose}/>
                <Modal.Content>
                    <p><b>Are you sure you want to finalize the eligible employees list?</b></p>
                    <p>
                        Once you’ve confirmed your eligible list this can't be edited going forward,
                        you'll need to contact concierge:&nbsp;
                        <ClickableText onClick={() => this.toggleConciergeModal(true)}>
                            Send us a message
                        </ClickableText>
                        &nbsp;or call {STRATEGIC_PARTNER.CONTACT_SUPPORT.NUMBER}.
                    </p>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic
                        color='grey'
                        onClick={this.handleModalClose}
                    >Go back</Button>
                    <Button primary
                        onClick={this.handleConfirm}
                    >I'm sure</Button>
                </Modal.Actions>
            </Modal>
        )
    }

    toggleConciergeModal = (value) => {
        this.setState(({ showConfirmation }) => ({ showConciergeModal: value, showConfirmation: value ? false : showConfirmation }))
    }

    renderConcierge = () => {
        const { showConciergeModal } = this.state

        return (
            <Concierge open={showConciergeModal} onManage={this.toggleConciergeModal}/>
        )
    }

    render() {
        const { confirmedEligibleEmployees } = this.props
        const { fetching, error } = this.state

        return (<>
            <div style={{ fontSize: '16px' }}>
                <b>Before open enrollment starts, please review and finalize your eligible employees</b>
                <div style={{ marginTop: '8px' }}>
                    <Checkbox toggle
                        disabled={fetching}
                        readOnly={fetching || confirmedEligibleEmployees}
                        style={{ marginRight: '0.5rem' }}
                        className='success'
                        checked={confirmedEligibleEmployees || fetching}
                        label='I have reviewed and confirmed all active and eligible employees are members of an eligible class.'
                        onChange={this.handleChange}
                    />
                    {fetching ? (
                        <Icon loading disabled name='spinner' />
                    ) : !!confirmedEligibleEmployees && (
                        <Icon disabled name='lock'/>
                    )}
                </div>
                {error && (
                    <Message negative>{error}</Message>
                )}
                {confirmedEligibleEmployees && (
                    <p className='neutral700Text' style={{ fontStyle: 'italic', fontSize: '14px', marginTop: '4px' }}>
                        The list of employees for this open enrollment has already been finalized. If corrections need to be made, please reach out to your {STRATEGIC_PARTNER.LABEL} Account Manager, <ClickableText onClick={() => this.toggleConciergeModal(true)}>send us a message</ClickableText> or
                        call {STRATEGIC_PARTNER.CONTACT_SUPPORT.NUMBER}.
                    </p>
                )}
            </div>
            {this.renderConfirmation()}
            {this.renderConcierge()}
        </>)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEligibleEmplyees)
