import {authActionTypes} from "./actionTypes";
import axios from 'axios';

const MFA_URL = '/api/employer/v2/mfa';

export function setAuthToken(payload) {
    return {
        type: authActionTypes.SET_AUTH_TOKEN,
        payload
    }
}

export function setLogin(payload) {
    return {
        type: authActionTypes.SET_LOGIN,
        payload
    }
}

export function logout() {
    return {
        type: authActionTypes.LOGOUT,
        payload: null
    }
}

export function setUsername(payload) {
    return {
        type: authActionTypes.SET_USERNAME,
        payload
    }
}


//MFA actions

export const setNewToken = (payload) => ({
    type: authActionTypes.SET_NEW_TOKEN,
    payload: { authToken: payload.token || payload.authToken },
});

export function validateMfa(username, code, mfaToken, rememberDevice) {
    const payload = {username, code, rememberDevice};
    return axios.post(MFA_URL + "/login", payload, {headers: {'Content-Type': 'application/json', "mfaAuthToken": mfaToken}});
}

export function prepareAddMethod(username, type) {
    const payload = {username, type};
    return axios.post(MFA_URL + "/prepareAddMethod", payload, {headers: {'Content-Type': 'application/json',}});
}

export function forcePrepareAddMethod(username, type, mfaToken) {
    const payload = {username, type};
    return axios.post(MFA_URL + "/forcedPrepareAddMethod", payload, {headers: {'Content-Type': 'application/json', "mfaAuthToken": mfaToken}});
}

export function generateBackupCodes() {
    return axios.post(MFA_URL + "/generateBackupCodeList", {headers: {'Content-Type': 'application/json',}});
}

export function validateBackupCode(code, mfaToken) {
    const payload = {code};
    return axios.post(MFA_URL + "/backupCodeLogin", payload, {headers: {'Content-Type': 'application/json', "mfaAuthToken": mfaToken}});
}

export const setAuthMeta = (userName, mfaEnabled, mfaNotEnabledGracePeriodEnded) => ({
    type: authActionTypes.METADATA_SET,
    payload: {
      userName,
      mfaEnabled,
      mfaNotEnabledGracePeriodEnded,
    },
});

export const setMfaSetupParams = (payload) => ({
    type: authActionTypes.METADATA_SETUP,
    payload: {
      mfaSetupParams: payload,
    },
});

export const setMfaType = (type) => ({
    type: authActionTypes.MFA_TYPE_SET,
    payload: {
      mfaType: type,
    },
});


export const setRememberDays = (time) => ({
    type: authActionTypes.MFA_REMEMBER_DEVICE_DAYS_SET,
    payload: {
      rememberDeviceDays: time,
    },
});

export const setMfaPrepare = (payload) => ({
    type: authActionTypes.MFA_PREPARE_SET,
    payload: {
      mfaToken: payload.mfaToken,
    },
});

export const setMfaIsSetup = (bool) => ({
    type: authActionTypes.MFA_IS_SETUP,
    payload: {
      mfaEnabled: bool,
    },
});

export const clearTrashToken = () => ({
    type: authActionTypes.CLEAR_TOKEN,
    payload: null,
});

export function getMfaSettings(){
    return axios.post(MFA_URL + "/listMultiFactorAuthenticationMethods", {headers: {'Content-Type': 'application/json'}});
}

export function trackSkipMfa() {
    axios.post(MFA_URL + "/skipSetup", {headers: {'Content-Type': 'application/json',}});
}

export function removeMethodMfa(method, password) {
    const payload = {factorTypeKey: method, password };
    return axios.post(MFA_URL + "/removeMethod", payload, {headers: {'Content-Type': 'application/json'}});
}