import {employeesActionTypes} from "./actionTypes";

export function setEmployees(payload) {
  return {
    type: employeesActionTypes.SET_EMPLOYEES,
    payload
  }
}
export function setTotalActiveEmployeesCount(payload){
  return {
    type: employeesActionTypes.SET_TOTAL_ACTIVE_EMPLOYEES_COUNT,
    payload
  }
}
export function setTotalInactiveEmployeesCount(payload){
  return {
    type: employeesActionTypes.SET_TOTAL_INACTIVE_EMPLOYEES_COUNT,
    payload
  }
}
export function setEmployeesFirstPageLoading(payload) {
  return {
    type: employeesActionTypes.SET_EMPLOYEES_FIRST_PAGE_LOADING,
    payload
  }
}
export function setEmployeesError(payload) {
  return {
    type: employeesActionTypes.SET_EMPLOYEES_ERROR,
    payload
  }
}
