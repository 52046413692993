import React, { Component } from 'react'
import { Table } from 'semantic-ui-react'
import './orderedTable.css'

const LIMIT_ROWS = 8

const defaultOrder = {
    column: null,
    direction: null,
}

class OrderedTable extends Component {
    state = {
        ...defaultOrder,
        expanded: false,
    }

    handleClick = key => {
        const { column, direction } = this.state

        if (key !== column) return this.setState({ column: key, direction: 'ascending' })

        if (direction === 'descending') return this.setState(defaultOrder)

        const newDirection = direction ? 'descending' : 'ascending'

        this.setState({ column, direction: newDirection })
    }

    get headers() {
        const { headers } = this.props

        return Object.keys(headers).map(key => [key, headers[key]]);
    }

    get sortedData() {
        const { data } = this.props
        const { column, direction } = this.state

        if (!column) return data

        const order = direction === 'ascending' ? 1 : -1

        return [ ...data ].sort((a, b) => (a[column] > b[column]) ? order : -order)
    }

    renderViewMore = () => {
        const { data } = this.props
        const { expanded } = this.state

        if (data?.length <= LIMIT_ROWS) return null

        const label = expanded ? 'View less' : 'View more'

        return (
            <a className='jb-ordered-table__more' href={`#${expanded ? 'less' : 'more'}`}  onClick={this.handleToggle}>{label}</a>
        )
    }

    handleToggle = e => {
        const { expanded } = this.state

        e.preventDefault()

        this.setState({ expanded: !expanded })

    }

    get data() {
        const { expanded } = this.state

        const data = this.sortedData

        return expanded ? data : data.slice(0, LIMIT_ROWS)
    }

    render() {
        const { column, direction } = this.state

        return (<>
            <Table className='jb-ordered-table' sortable>
                <Table.Header>
                    <Table.Row>
                        {this.headers.map(([key, { label, sortable }]) => (
                            <Table.HeaderCell
                                key={key}
                                sorted={(sortable && (column === key ? direction : null)) || null}
                                onClick={() => sortable && this.handleClick(key)}
                            >{label}</Table.HeaderCell>
                        ))}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {this.data.map(({ key, ...row }) => (
                        <Table.Row key={key}>
                            {this.headers.map(([column]) => (
                                <Table.Cell key={column}>{row[column] || '-'}</Table.Cell>
                            ))}
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            {this.renderViewMore()}
        </>)
    }
}

export default OrderedTable
