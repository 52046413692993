import {footerActionTypes} from "../actions/actionTypes";

const initialState = {
    carrierDisclaimer: null,
};

export const footerReducer = (state = initialState, action) => {
    switch (action.type) {
        case footerActionTypes.SET_FOOTER:
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state
    }
}
