
import React from 'react'
import { Menu, Grid } from 'semantic-ui-react'
import {currentYear} from "../utils/date";
import STRATEGIC_PARTNER from '../constants/strategicPartners';

const StaticFooter = () => (
  <Grid columns={3}>
      <Grid.Column width={3}>
          <Menu text>
              <Menu.Item>©{currentYear()} {STRATEGIC_PARTNER.LEGAL.INSURANCE_NAME} All Rights Reserved.</Menu.Item>
          </Menu>
      </Grid.Column>
      <Grid.Column width={6} textAlign={"center"}>
      </Grid.Column>
      <Grid.Column width={3}>
          <Menu text>
              <Menu.Menu position="right">
                  <Menu.Item as="a" href={STRATEGIC_PARTNER.LEGAL.TERMS} target="_blank">
                      Terms
                  </Menu.Item>
                  <Menu.Item as="a" href={STRATEGIC_PARTNER.LEGAL.PRIVACY} target="_blank">
                      Privacy
                  </Menu.Item>
              </Menu.Menu>
          </Menu>
      </Grid.Column>
  </Grid>
)

export default StaticFooter