import React from 'react'
import {Icon} from "semantic-ui-react";

export const PdfIcon = () => {
    return (
        <Icon
            name="file pdf outline"
            color="red"
            size="large"
            style={{
                position: 'absolute',
                left: '1.5rem',
                top: '2rem'
            }}
        />
    )
}
