import React, { Component } from 'react'
import { Icon, List } from 'semantic-ui-react';
import FileUpload from './partials/fileUploads';

export class UploadFile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            analyzing: false,
            error: null
        }
    }

    onFilesLoad = (newFiles) => {
        const { files = [], validFileTypes, onAddingFiles, onFilesUpdate, onError } = this.props

        this.setState({ error: null })

        let added = []
        let invalid = []

        if (validFileTypes) {
           added = newFiles.filter((file) => validFileTypes.indexOf(file.type) !== -1);
           invalid = newFiles.filter((file) => validFileTypes.indexOf(file.type) === -1);
        } else {
            added = newFiles
        }

        if (invalid.length) {
            let error

            if (newFiles.length > 1) {
                const list = invalid.map(f => f.name).join(', ')
                error = `Invalid files: ${list}.`
            } else {
                const file = invalid[0]
                error = `${file.name} is not a valid type.`
            }
            this.setState({ error }, () => onError && onError({ message: error, files: invalid }))
        }

        const res = onAddingFiles && onAddingFiles(added)
        if (res === false) return

        const newFileList = [...files, ...added]

        onFilesUpdate(newFileList, { added })
    }

    removeFile = (index) => {
        const { files, onFilesUpdate, onRemovingFiles } = this.props

        const removed = files[index]

        const res = onRemovingFiles && onRemovingFiles(removed)
        if (res === false) return

        const newFileList = [...files]
        newFileList.splice(index, 1)

        onFilesUpdate(newFileList, { removed })
    }

    render() {
        const {
            style,
            files,
            hideAttachmentArea = false,
            disabled,
            customText,
            multiple,
        } = this.props
        const {analyzing, error} = this.state
        const styles = {
            ...style,
            height: 'auto',
            minHeight:0
        }

        return (<>
            {!!files?.length && (
                <List className={`neutral900Text ${hideAttachmentArea && "attachment-area-box"}`} color='red'>
                    {files.map((file, index) => {
                        return (
                            <List.Item key={index}>
                                <Icon name='file'/>
                                <List.Content>
                                    <b>{file.name}</b>
                                    &nbsp;
                                    {!disabled && (
                                        <Icon
                                            link
                                            color='grey'
                                            onClick={()=>{this.removeFile(index) }}
                                            name='trash alternate outline'
                                        />
                                    )}
                                </List.Content>
                            </List.Item>
                        )
                    })}
                </List>
            )}
            {!hideAttachmentArea && (
                <FileUpload small style={styles} onFilesLoad={this.onFilesLoad} disabled={analyzing || disabled} multiple={multiple}>
                    <>
                        <p style={{ marginBottom: 0 }}><Icon color="grey" name="download"/></p>
                        <p style={{ marginBottom: 0 }} className="small neutral700Text">Drag and drop or <strong>browse your computer</strong></p>
                        {customText && (
                          <p className="small neutral700Text">{customText}</p>
                        )}
                        {/* <p className="small neutral700Text">File types accepted are JPGs, PNGs, and PDFs</p> */}
                        {error && (
                            <p className="small">
                                <Icon style={{color: '#D82000'}} name='exclamation circle'/>
                                <span style={{color: '#D82000'}}>{error}</span>
                            </p>
                        )}
                    </>
                </FileUpload>
            )}
        </>)
    }
}
