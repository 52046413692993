import React, {Component} from 'react';
import {Table} from "semantic-ui-react";
import {formatMoney} from "../utils/utils";
import {familyTier} from "../utils/strings";

export default class FamiliyTierAgeBandContributionTable extends Component {
    render() {
        const {ratedClassUtil, ageRanges} = this.props
        return (
            <Table compact collapsing>
                <Table.Body>
                    <Table.Row textAlign="center">
                        <Table.Cell/>
                        <Table.Cell style={{borderTop: 'none'}} textAlign={"center"}
                                    className={"small neutral700Text"}><b>EE</b></Table.Cell>
                        <Table.Cell style={{borderTop: 'none'}} textAlign={"center"}
                                    className={"small neutral700Text"}><b>EE+SP</b></Table.Cell>
                        <Table.Cell style={{borderTop: 'none'}} textAlign={"center"}
                                    className={"small neutral700Text"}><b>EE+CH(s)</b></Table.Cell>
                        <Table.Cell style={{borderTop: 'none'}} textAlign={"center"}
                                    className={"small neutral700Text"}><b>FAM</b></Table.Cell>
                    </Table.Row>
                    {ageRanges.map(band =>{
                        return (
                            <Table.Row key={band.key}>
                                <Table.Cell style={{borderTop: 'none'}} textAlign={"right"}
                                            className={"small neutral700Text"}><b>Age {band.title}</b></Table.Cell>
                                <Table.Cell style={{borderTop: 'none'}}>{formatMoney(ratedClassUtil.findFamilyTierAgeBandContribution(familyTier.employeeOnly, band.key))}</Table.Cell>
                                <Table.Cell style={{borderTop: 'none'}}>{formatMoney(ratedClassUtil.findFamilyTierAgeBandContribution(familyTier.employeeSp, band.key))}</Table.Cell>
                                <Table.Cell style={{borderTop: 'none'}}>{formatMoney(ratedClassUtil.findFamilyTierAgeBandContribution(familyTier.employeeCh, band.key))}</Table.Cell>
                                <Table.Cell style={{borderTop: 'none'}}>{formatMoney(ratedClassUtil.findFamilyTierAgeBandContribution(familyTier.family, band.key))}</Table.Cell>
                            </Table.Row>)
                    })}
                </Table.Body>
            </Table>
        )
    }
}
