import React, {Component} from 'react'
import PortalContainer from '../portalContainer'
import {Grid} from 'semantic-ui-react'
import {CustomDivider} from '../../custom-common'

class PageLayout extends Component {
    render() {
        const {children} = this.props

        return (
            <PortalContainer showLeftNavigation={true}>
                <Grid container stackable columns={1} centered style={{marginTop: 0}}>
                    <Grid.Column className={"pageContent"}>
                        <CustomDivider hidden/>
                        {children}
                    </Grid.Column>
                </Grid>
            </PortalContainer>
        )
    }
}

export default PageLayout
