import React, {Component} from "react";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Label from "semantic-ui-react/dist/commonjs/elements/Label";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import BorderedSegment from "../../borderedSegment";
import RemovePaymentMethodModal from "./removePaymentMethodModal";
import {hoursSinceDate} from "../../../utils/date";

class BankAccount extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showRemovePMModal: false,
            showChangeDefaultModal: false
        }
    }

    renderContent = () => {
        const {name, lastAccountNumbers, defaultBankAccount, id, bankAccountData} = this.props;
        const showIsVerified = () => {
            if(!bankAccountData || !bankAccountData.metadata){
                return false
            }
            const {metadata} = bankAccountData
            if (metadata?.verificationStatus && metadata.verificationStatus === "manually_verified") {
                return hoursSinceDate(metadata.verifiedAt) <= 72
            }
            return false
        }

        return <Grid columns={"equal"}>
                <Grid.Column width={10}>
                        <span className="small neutral700Text">
                            <Icon name='university' style={{fontSize: 20}}/><b>{name}</b>
                        </span>
                        {defaultBankAccount && <Label className="widePadding" style={{marginLeft: "2em"}} horizontal circular>Default</Label>}
                {showIsVerified() && (
                            <span className="small primaryText" style={{marginLeft: "2em"}}>
                                <Icon name='check' color="blue"/><b>Verified</b>
                            </span>
                        )}
                        <br/>
                        <small className="neutral600Text">******{lastAccountNumbers}</small>
                </Grid.Column>
            <Grid.Column textAlign={"right"}>
                    <Dropdown icon={"ellipsis horizontal"}>
                        <Dropdown.Menu>
                        {!defaultBankAccount &&
                            <Dropdown.Item text='Set as default' onClick={() => {
                                this.props.setDefault(id)
                            }}/>
                        }
                        <Dropdown.Item text='Delete' onClick={() => {
                            this.setState({showRemovePMModal: true})
                        }}/>
                        </Dropdown.Menu>
                    </Dropdown>
                </Grid.Column>
            </Grid>
    }

    render() {
        const {bankAccountData, bankAccountsListLength, defaultBankAccount} = this.props;
        const {showRemovePMModal} = this.state;
        return (
            <>
                {!!showRemovePMModal &&
                    <RemovePaymentMethodModal show={showRemovePMModal}
                                              close={()=>{this.setState({showRemovePMModal: false})}}
                                              remove={this.props.remove}
                                              paymentMethod={bankAccountData.stripeId}
                                              autoPay={false}
                                              pmListLength={bankAccountsListLength}
                                              isDefault={defaultBankAccount}
                    />
                }
                {defaultBankAccount ?
                    <BorderedSegment borderColor={"#43806C"}>
                        {this.renderContent()}
        </BorderedSegment>
                    :
                    <Segment>
                        <div style={{paddingLeft: '8px'}}>{this.renderContent()}</div>
                    </Segment>
    }
            </>
        )
}
}
export default withRouter(connect()(BankAccount));