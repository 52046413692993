import React from "react";
import {useSelector} from "react-redux";
import {selectNewHireDetails} from "../../../selectors/newHire";
import {CustomQLECard} from "../../custom-common";
import {QLE_STATUS} from "../../../constants/qle";
import {formatDate} from "../../../utils/utils";
import STRATEGIC_PARTNER from "../../../constants/strategicPartners";
import {getQLECardType, getQLEStatus} from "../../../utils/qle";

export const NewHireCard = () => {
    const newHireInfo = useSelector(selectNewHireDetails);
    const {
        autoApproveDate,
        isEligibleForCoverage,
        electionsMade,
        declineCoverage,
        declineEnrollment,
        newNewHireOpenEnrollmentStartDate,
        newNewHireOpenEnrollmentEndDate,
        dateOfHire,
        changeExplanationCopy,
        coverageEffectiveDate,
        coverageImpacted,
        decisionDate,
        status,
        tier,
        willBePendingForApproval,
    } = newHireInfo;

    const cardType = getQLECardType(willBePendingForApproval, status, decisionDate);
    const qleStatus = getQLEStatus(willBePendingForApproval, status, decisionDate);
    const { CONTACT_SUPPORT: { NUMBER } } = STRATEGIC_PARTNER;

    const renderDescription = () => {
        if(!isEligibleForCoverage) return <i>This new hire is not eligible for coverage.</i>;
        if(declineCoverage) return <i>This employee has declined coverage.</i>;
        if(!electionsMade) return <i>No elections have been made.</i>;
        const descriptions = {
            [QLE_STATUS.PENDING_FOR_APPROVAL]: `This change impacts historical coverage and requires carrier approval before it can be processed. If you have any questions, please reach out to ${NUMBER}.`,
            [QLE_STATUS.COMPLETED]: `${changeExplanationCopy ? `${changeExplanationCopy}. ` : ''}If you have any questions, please reach out to ${NUMBER}.`,
            [QLE_STATUS.REJECTED]: (
                <>
                  {declineEnrollment ? (
                    <span>
                      There are no coverage changes as a result of this event.<br />
                    </span>
                  ) : null}
                  {changeExplanationCopy ? (
                    <span>
                      {`${changeExplanationCopy}. `}
                    </span>
                  ) : null}
                  If you have any questions, please reach out to {NUMBER}.
                </>
              ),
            [QLE_STATUS.DEFAULT]: 'This change has an impact to coverage, see details below for more information.'
          };

          return <i>{descriptions[qleStatus] || descriptions[QLE_STATUS.DEFAULT]}</i>;
    };

    const renderChangeRequestLanguage = () => {
        if(autoApproveDate) return null;
        const formattedDecisionDate = decisionDate && formatDate(decisionDate, decisionDate, 'MMM Do, YYYY');
        if (willBePendingForApproval || qleStatus === QLE_STATUS.PENDING_FOR_APPROVAL) return `Pending carrier approval.`;
        if (qleStatus === QLE_STATUS.COMPLETED) return `Approved on ${formattedDecisionDate}.`;
        if (qleStatus === QLE_STATUS.REJECTED) return `Denied on ${formattedDecisionDate}.`;
        return null;
    };

    const getFooterContent = () => {
        const footerData = [];
        footerData.push({
            title: 'Date of hire:',
            value: formatDate(dateOfHire, dateOfHire, "MMM Do, YYYY")
        })
        if(newNewHireOpenEnrollmentStartDate){
            footerData.push({
                title: 'Enrollment dates:',
                value: `${formatDate(newNewHireOpenEnrollmentStartDate, newNewHireOpenEnrollmentStartDate, "MMM Do, YYYY")} - ${formatDate(newNewHireOpenEnrollmentEndDate, newNewHireOpenEnrollmentEndDate, "MMM Do, YYYY")}`
            })
        }
        if(coverageImpacted){
            footerData.push({
                title: `${tier} coverage begins on:`,
                value: formatDate(coverageEffectiveDate, coverageEffectiveDate, "MMM Do, YYYY")
            })
        }
        return footerData.length ? footerData: null;
    };

    return (
        <CustomQLECard
            qleStatus={qleStatus}
            cardType={cardType}
            qleName={"New Hire"}
            changeRequest={renderChangeRequestLanguage()}
            description={renderDescription()}
            footerData={getFooterContent()}
        />
    )
};