import moment from 'moment'

export const displayDateFormat = 'MM/DD/YYYY';

export const isEvenNumber = (num) => num & 2 === 0;

export const isValidPartialDate = (partialDateStr, prevValue) => {
    const reg = /^\d*$/;
    const parts = partialDateStr.split('/');
    if (parts.length > 3) {
        return null;
    }
    const mm = parts[0];
    const dd = parts[1];
    const yyyy = parts[2];
    if (mm !== undefined && (!reg.test(mm) || mm.length > 2 || parseInt(mm) > 12)) {
        return null;
    }
    if (dd !== undefined) {
        if (!reg.test(dd) || dd.length > 2 || parseInt(dd) > 31) {
            return null;
        }
        const digitMm = parseInt(mm);
        const digitDd = parseInt(dd);
        const isEvenMonth = isEvenNumber(digitMm);

        if (digitMm === 2 && digitDd > 29) {
            return null;
        }
        if (isEvenMonth) {
            if (digitDd > 30) {
                return null;
            }
        } else {
            if (digitDd > 31) {
                return null;
            }
        }
    }
    if (yyyy !== undefined && (!reg.test(yyyy) || yyyy.length > 4)) {
        return null;
    }

    let response = partialDateStr;
    const userIsAddingCharacters = partialDateStr.length > prevValue.length;
    if (userIsAddingCharacters) {
        if (partialDateStr.length === 2) {
            response = partialDateStr + '/';
        } else if (partialDateStr.length === 5) {
            response = partialDateStr + '/';
        }
    } else {
        if (partialDateStr.length === 2) {
            response = partialDateStr.substring(0, 1)
        } else if (partialDateStr.length === 5) {
            response = partialDateStr.substring(0, 4)
        }
    }

    return response
}

export function addWorkDays(date, days) {
    date = moment(date);
    while (days > 0) {
        date = date.add(1, 'days');
        if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
            days -= 1;
        }
    }
    return date;
}

export function parseDate(string) {
    if (!string) return ''

    const date = moment(string, 'YYYY-MM-DD')

    return date.isValid() ? date.format(displayDateFormat) : ''
}

export function hoursSinceDate (date)  {
    return moment().diff(date, 'hours')
}

export function currentYear () {
    return new Date().getFullYear()
}