import {Grid, Table} from "semantic-ui-react";
import React from "react";
import {CustomDivider} from "../custom-common";

const CategoriesTable = ({title, subtitle, categoryItems, sideText, secondColumnName}) => (
    <>
        <p className={'small'}><b>{title}</b></p>
        <p>{subtitle}</p>
        <Grid columns={2}>
            <Grid.Column width={7}>
                <Table className={"benefitsCategoriesTable"} compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell className={'small'}>Category</Table.HeaderCell>
                            <Table.HeaderCell className={'small'}>{secondColumnName}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        { categoryItems?.map(({label, value}, index) => (
                            <Table.Row key={label + '-' + index}>
                                <Table.Cell>{label}</Table.Cell>
                                <Table.Cell>{value}</Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </Grid.Column>
            {
                sideText && <Grid.Column verticalAlign="middle" width={5}>
                    <p className={"smaller"}>{sideText}</p>
                </Grid.Column>
            }
        </Grid>
        <CustomDivider hidden/>
    </>
);

export default CategoriesTable;