import styled from 'styled-components'
import {Divider as SCDivider} from 'semantic-ui-react'

const scope = 'ViewMoreButton'

export const Container = styled.div`
  text-align: center;
`

export const Divider = styled(SCDivider)`
  margin-top: 0;
  padding-top: 0;
  width: 886px;
  margin-left: 24px;
`
export const Text = styled.span`
  margin-bottom: 24px;
`

Container.displayName = `${scope}`
