export const isDirectBilling = state => {
  return state.portal?.contract?.employer?.billingMethod === "Direct Bill"
}

export const hasEmployerContributions = state => {
  const contract = state.portal.contract
  const contrib = contract.ratedClasses?.[0]?.employerContributions || []
  const hasContributions = contrib.some(e => e.amount || e.percentage)

  return hasContributions
}
