import React from "react";

import {Container} from "semantic-ui-react";
import CategoriesTable from "./categoriesTable";
import axios from "axios";
import {CustomDivider} from "../custom-common";


const BenefitLimits = ({contractTerms, statePostalCode}) => {
    const [benefits, setBenefits] = React.useState({});

    React.useEffect(() => {
        const getBenefitLimits = async () => {
            const {data} = await axios.post("/api/employer/v2/getBenefitLimits",
                { contractTerms, statePostalCode }, {headers: {'Content-Type': 'application/json'}});

            return data;
        }

        getBenefitLimits().then(data => setBenefits(data));
    }, [contractTerms, statePostalCode]);

    const { separationPeriodsData, ocurrenceLimitsData } = benefits;

    return (
        <>
            <p><b>Benefit limits</b></p>
            <p>
                Benefits are paid if the insured is diagnosed with a covered condition. Members may receive a benefit
                more than once as noted below.
            </p>
            <CustomDivider hidden/>
            <Container className={'benefits'}>
                {separationPeriodsData && <CategoriesTable {...separationPeriodsData} />}
                <CategoriesTable {...ocurrenceLimitsData}/>
            </Container>
        </>
    );
};

export default BenefitLimits;