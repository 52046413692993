import React, {Component} from 'react'
import {
    Icon,
    Menu
} from "semantic-ui-react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {NavLink} from "react-router-dom";
import PortalHeader from "./portalHeader";
import PortalFooter from "./portalFooter";
import SidebarWidget from './openEnrollment/sidebarWidget';
import { StrategicPartnerLogo } from '../common';
import Mfa from '../../mfa/index';
import {CustomDivider} from '../custom-common';

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        contract: state.portal.contract,
        pastDue: state.billing.pastDue,
        oeStatus: state.openEnrollment.status,
        mfaEnabled: state.auth.mfaEnabled,
        isSelfBill: state.billing.isSelfBill
    }
};

class PortalContainer extends Component {
    renderOpenEnrollmentLink() {
        const { oeStatus } = this.props

        if (oeStatus === 'unavailable') return null

        return (
            <Menu.Item as={NavLink} exact to="/portal/open-enrollment" className="navigationItem">
                <b><Icon name='calendar outline'/>Open enrollment {oeStatus === 'open' && <Icon name='exclamation circle' style={{ color: '#F0A446' }} />}</b>
            </Menu.Item>
        )
    }

    render() {
        const {children, showLeftNavigation = true, pastDue, mfaEnabled, isSelfBill} = this.props;
        const marginLeft = showLeftNavigation ? '220px' : '0px';
        const mfaOverflow = !showLeftNavigation ? 'hidden' : null;
        const showIcon = !isSelfBill && !!pastDue && pastDue !== "0.00"

        return (
            <React.Fragment>
                {showLeftNavigation && <div className={"leftNavigation"}>
                    <div style={{ flex: 1, paddingBottom: '50px' }}>
                        <StrategicPartnerLogo />
                        <CustomDivider hidden medium/>
                        <Menu text vertical>
                            {/*<Menu.Item className="navigationItem"><b><Icon name='th'/>Dashboard</b></Menu.Item>*/}
                            {this.renderOpenEnrollmentLink()}
                            <Menu.Item as={NavLink} exact to="/portal/employees" className="navigationItem"><b><Icon name='users'/>Employees</b></Menu.Item>
                            <Menu.Item as={NavLink} exact to="/portal/plan" className="navigationItem"><b><Icon name='info circle'/>Policy info</b></Menu.Item>
                            <Menu.Item as={NavLink} exact to="/portal/reports" className="navigationItem"><b><Icon name='list alternate outline'/>Reports</b></Menu.Item>
                            <Menu.Item as={NavLink} exact to="/portal/billing" className="navigationItem"><b><Icon name='credit card outline'/>Billing</b>{showIcon && <Icon color={"red"} name='exclamation circle' className="billIconExclamation" />}</Menu.Item>
                            <Menu.Item as={NavLink} exact to="/portal/account" className="navigationItem"><b><Icon name='setting'/>Account</b></Menu.Item>
                        </Menu>
                        {!mfaEnabled && (<Mfa showReminder={true}/>)}
                        <CustomDivider hidden medium/>
                        <SidebarWidget />
                    </div>

                </div>}

                <div style={{marginLeft: marginLeft, minWidth: '550px', minHeight: '100vh', overflow: mfaOverflow}}>
                    <PortalHeader/>
                    <div className="employer-portal-container">
                        {children}
                    </div>
                </div>
                <PortalFooter/>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps)(withRouter(PortalContainer));

