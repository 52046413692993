import {authActionTypes} from "../actions/actionTypes";

const initialState = {
    user: {}
};

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case authActionTypes.SET_AUTH_TOKEN:
            return {
                ...state,
                user: {
                    ...state.user,
                    authToken: action.payload
                }
            }
        case authActionTypes.SET_LOGIN:
            return {
                ...state,
                user: action.payload || {},
                userName: state.userName,
                mfaEnabled: state.mfaEnabled,
                mfaSetupParams: state.mfaSetupParams,
                mfaType: state.mfaType,
                mfaAction: state.mfaAction,
                mfaNotEnabledGracePeriodEnded: state.mfaNotEnabledGracePeriodEnded,
                mfaToken: state.mfaToken
            }
        case authActionTypes.LOGOUT:
            return {
                user: {},
                userName: null,
                mfaEnabled: null,
                mfaSetupParams: null,
                mfaAction: null,
                mfaType: null,
                mfaNotEnabledGracePeriodEnded: null,
                mfaToken: null
            }
        case authActionTypes.SET_USERNAME:
            return {
                user: {
                    ...state.user,
                    username: action.payload
                }
            }
        case authActionTypes.METADATA_SET:
            return {
                ...state,
                loginInfo: state.loginInfo,
                userName: action.payload.userName,
                mfaEnabled: action.payload.mfaEnabled,
                mfaSetupParams: state.mfaSetupParams,
                mfaType: state.mfaType,
                mfaAction: state.mfaAction,
                mfaNotEnabledGracePeriodEnded: action.payload.mfaNotEnabledGracePeriodEnded,
                mfaToken: state.mfaToken
            }
        case authActionTypes.METADATA_SETUP:
            return {
                ...state,
                loginInfo: state.loginInfo,
                userName: state.userName,
                mfaEnabled: state.mfaEnabled,
                mfaSetupParams: action.payload.mfaSetupParams,
                mfaType: state.mfaType,
                mfaAction: state.mfaAction,
                mfaNotEnabledGracePeriodEnded: state.mfaNotEnabledGracePeriodEnded,
                mfaToken: state.mfaToken
            }
        case authActionTypes.MFA_TYPE_SET:
            return {
                ...state,
                loginInfo: state.loginInfo,
                userName: state.userName,
                mfaEnabled: state.mfaEnabled,
                mfaSetupParams: state.mfaSetupParams,
                mfaType: action.payload.mfaType,
                mfaAction: state.mfaAction,
                mfaNotEnabledGracePeriodEnded: state.mfaNotEnabledGracePeriodEnded,
                mfaToken: state.mfaToken
            }
        case authActionTypes.MFA_PREPARE_SET:
            return {
                ...state,
                loginInfo: state.loginInfo,
                userName: state.userName,
                mfaEnabled: state.mfaEnabled,
                mfaSetupParams: state.mfaSetupParams,
                mfaType: state.mfaType,
                mfaAction: state.mfaAction,
                mfaNotEnabledGracePeriodEnded: state.mfaNotEnabledGracePeriodEnded,
                mfaToken: action.payload.mfaToken
            }
        case authActionTypes.SET_NEW_TOKEN:
            const {authToken, ...rest} = state.user;
            const newLoginInfo = {...action.payload, mfaToken: null, ...rest}
            return {
                ...state,
                user: newLoginInfo,
                userName: state.userName,
                mfaEnabled: state.mfaEnabled,
                mfaSetupParams: state.mfaSetupParams,
                mfaType: state.mfaType,
                mfaAction: state.mfaAction,
                mfaNotEnabledGracePeriodEnded: state.mfaNotEnabledGracePeriodEnded,
                mfaToken: null
            }
        case authActionTypes.MFA_IS_SETUP:
            return {
                ...state,
                mfaEnabled: action.payload.mfaEnabled,
            }
        case authActionTypes.CLEAR_TOKEN:
            return {
                ...state,
                mfaToken: null
            }
        case authActionTypes.MFA_REMEMBER_DEVICE_DAYS_SET:
            return {
                ...state,
                rememberDeviceDays: action.payload.rememberDeviceDays
            }
        default:
            return state
    }
};