import React, {useEffect} from 'react';
import {Container, Grid, GridRow, Menu} from "semantic-ui-react";
import {withRouter} from "react-router-dom";
import axios from "axios";
import {currentYear} from "../utils/date";
import {setFooter} from "../actions/footerActions";
import {connect} from "react-redux";
import {MFA_ROUTE} from "../utils/common";
import STRATEGIC_PARTNER from "../constants/strategicPartners";
import {resolveErrorMessage} from "../utils/utils";


const mapStateToProps = ({footer: {carrierDisclaimer}}, ownProps) => ({
    ...ownProps,
    carrierDisclaimers: carrierDisclaimer?.split('\n') || [],
});

const Footer = ({button, showTopFooter, link, hasToDisplayCarrierDisclaimer, carrierDisclaimers, dispatch, location: {pathname}}) => {
    useEffect(() => {
        const fetchFooterData = async () => {
            const {data} = await axios.post("/api/employer/v2/getFooter", null);
            dispatch(setFooter(data));
        };

        if (hasToDisplayCarrierDisclaimer && carrierDisclaimers.length === 0) {
            fetchFooterData().catch(error => {
                console.error(resolveErrorMessage(error, "Error fetching footer data"));
            });
        }
    }, [hasToDisplayCarrierDisclaimer, carrierDisclaimers.length, dispatch]);

    return (
        <div className={`footer ${pathname === MFA_ROUTE ? "mfaFlowFooter" : "accountCreation"}`}>
            {showTopFooter &&
                <div className="buttonContainer">
                    <Container>
                        <Grid columns={3} verticalAlign='middle'>
                            <Grid.Column width={5}/>
                            <Grid.Column width={2}>
                                {button}
                            </Grid.Column>
                            <Grid.Column width={5} textAlign='left'>
                                {!!link && link}
                            </Grid.Column>
                        </Grid>
                    </Container>
                </div>
            }
            <Grid columns='equal'>
                <Grid.Column>
                    <Menu text>
                        <Menu.Item>©{currentYear()} {STRATEGIC_PARTNER.LEGAL.INSURANCE_NAME} All Rights Reserved.</Menu.Item>
                    </Menu>
                </Grid.Column>
                <Grid.Column>
                    <Menu text className='termsPrivacy'>
                        <Menu.Menu position="right">
                            <Menu.Item as="a" href={STRATEGIC_PARTNER.LEGAL.TERMS} target="_blank">
                                Terms
                            </Menu.Item>
                            <Menu.Item as="a" href={STRATEGIC_PARTNER.LEGAL.PRIVACY} target="_blank">
                                Privacy
                            </Menu.Item>
                        </Menu.Menu>
                    </Menu>
                </Grid.Column>
            </Grid>
            {hasToDisplayCarrierDisclaimer && carrierDisclaimers.length > 0 &&
                <GridRow>
                    {carrierDisclaimers.map((paragraph, index) => (
                        <p key={index} className='carrierDisclaimers'>{paragraph}</p>
                    ))}
                </GridRow>
            }
        </div>
    );
};

export default connect(mapStateToProps)(withRouter(Footer));
