import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import {Dropdown, Form, Grid, Icon, Popup, Segment} from "semantic-ui-react";
import Concierge from "../../../concierge";
import PortalModalHeader from "../../portalModalHeader";
import {getTerminationStepProps} from "../../../../utils/common";
import Steps from "../../../steps/steps";
import moment from "moment/moment";
import Footer from "../../../footer";
import {connect} from "react-redux";
import {setPrevRoute} from "../../../../actions/portalModalHeaderActions";
import {setTerminationDetails} from "../../../../actions/employeeActions";
import {Button, Header} from "../../../base";
import {DateSelector} from '../../../custom-common';

const mapStateToProps = (state, ownProps) => {
    const {employeeTermination} = state;
    return {
        employeeTermination,
        ...ownProps,
    }
};

const mapDispatchToProps = dispatch => ({
    setPrevRoute: portalModalHeader => dispatch(setPrevRoute(portalModalHeader)),
    setTerminationDetails: employeeTermination => dispatch(setTerminationDetails(employeeTermination)),
})

class EmploymentTerminationDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            conciergeOpen: false,
            contactId: null,
            errors: {},
            form: {},
            reasons: ["Deceased Employee", "Disability Retirement", "Gross Misconduct", "Involuntary", "Retirement", "Voluntary"],
            stepProps: null,
            submitting: false,
        };
        this.requiredFields = ['terminationDate', 'reason'];
    }

    async componentDidMount() {
        window.onbeforeunload = function (event) {
            event.preventDefault();
            event.returnValue = '';
        };
        this.buildSteps();
        const {match, setPrevRoute, employeeTermination: {terminationDate, reason}} = this.props;
        this.setState({form: {terminationDate, reason}});
        const id = match.params.id;
        setPrevRoute({prevRoute: `/portal/employee/${id}`});
    }

    componentWillUnmount() {
        window.onbeforeunload = null;
    }

    buildSteps = () => {
        this.setState({stepProps: getTerminationStepProps(0)});
    }

    manageConciergeModal = (value) => {
        this.setState({conciergeOpen: value})
    }

    handleSubmit = () => {
        const isValid = this.validate()
        if (!isValid) return
        this.setState({submitting: true})
        this.next()
    }

    validate = () => {
        const {form} = this.state;
        const {requiredFields} = this;
        const errors = {};
        let isValid = true;
        requiredFields.forEach(field => {
            if (!form[field]?.length) {
                errors[field] = 'Required';
                isValid = false;
            }
        });
        if (form.terminationDate && !moment(form.terminationDate, 'MM/DD/YYYY', true).isValid()) {
            errors.terminationDate = 'Invalid date';
            isValid = false;
        }
        this.setState({errors});
        return isValid;
    }

    next = () => {
        const {match, history, setTerminationDetails} = this.props;
        const id = match.params.id;
        const {form} = this.state;
        setTerminationDetails(form);
        history.push({pathname: `/portal/employee/${id}/employment-termination/review`});
    }

    handleChange = (_, {name, value}) => {
        this.setState(({form, errors}) => {
            delete errors[name];
            return {form: {...form, [name]: value}, errors: {...errors}};
        });
    }

    get hasErrors() {
        const {errors} = this.state;
        return Object.keys(errors).length > 0;
    }

    get hasEmptyField() {
        const {terminationDate, reason} = this.state.form;
        return !terminationDate || !reason;
    }

    render() {
        const {
            conciergeOpen,
            reasons,
            form,
            stepProps,
            errors,
            submitting
        } = this.state;

        const nextButton = (
            <Button type={'submit'} disabled={this.hasEmptyField || this.hasErrors || submitting} primary form="termination-details-form">
                Next step
            </Button>
        );

        const infoIcon = <Icon name={'info circle'} color={'grey'} style={{marginLeft: '6px', fontSize: '10px', cursor: 'pointer'}}/>;
        const renderFieldTooltip = (field) => {
            const tips = {
                terminationDate: 'The last day of employment for this employee.',
                reason: 'Termination reason may impact the employee\'s coverage end date.'
            };
            return (<Popup content={<span>{tips[field]}</span>} style={{fontSize: '12px', maxWidth: 'max-content'}} trigger={infoIcon}/>);
        }

        return (
            <React.Fragment>
                <PortalModalHeader modalTitle={'Employment termination'} showRightMenu/>
                <div className="employer-portal-container" style={{background: 'white'}}>
                    {stepProps && <Grid stackable>
                        <Grid.Column>
                            <Steps stepProps={stepProps} customStyle={{marginTop: '61px', marginLeft: '-138px', marginBottom: 0}} lineWidth={{width: '153px'}}/>
                        </Grid.Column>
                    </Grid>}
                    <Grid container stackable columns={1} centered>
                        <Grid.Column className={"pageContent"}>
                            <Segment padded basic>
                                <Header as={"h2"} textAlign={"center"} style={{marginBottom: '8px'}}>Enter termination details</Header>
                                <span className={'termination-details-subTitle'}>Complete the employment termination information below—</span>
                                <Form id="termination-details-form" onSubmit={this.handleSubmit} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <Form.Field className='required field no-margin-input' width={4} error={!!errors.terminationDate}>
                                        <label style={{marginBottom: '4px', display: 'inline-block'}}>Employment termination
                                            date</label>{renderFieldTooltip('terminationDate')}
                                            <DateSelector
                                                fluid
                                                name='terminationDate'
                                                value={form.terminationDate || ''}
                                                onChange={(e, {value}) => {
                                                    this.handleChange(e, {name: "terminationDate", value: value});
                                                }}
                                            />
                                    </Form.Field>
                                    <Form.Field className='required field no-margin-input' width={4} error={!!errors.reason}>
                                        <label style={{marginBottom: '4px', display: 'inline-block'}}>Termination reason</label>{renderFieldTooltip('reason')}
                                        <Dropdown fluid selection
                                                  placeholder='Select:'
                                                  options={reasons.map((value, key) => ({key, value, text: value}))}
                                                  name='reason'
                                                  value={form.reason || ''}
                                                  onChange={this.handleChange}
                                        />
                                    </Form.Field>
                                </Form>
                            </Segment>
                        </Grid.Column>
                    </Grid>
                </div>
                <Footer button={nextButton} showTopFooter hasToDisplayCarrierDisclaimer/>
                <Concierge open={conciergeOpen} onManage={this.manageConciergeModal} hasDedicatedConcierge/>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EmploymentTerminationDetails));
