import React, {Component} from 'react'
import {Button, Form, Input, Message, Modal} from "semantic-ui-react";
import axios from "axios";
import {errorStyle, isValidEmail} from "../../../utils/utils";
import RequiredSpan from "../../requiredMark";
import {StrategicPartnerLabel} from "../../common";
import {resolveErrorMessage} from '../../../utils/utils'
import {CustomDivider, CustomModalHeader, CustomEmailInput} from '../../custom-common';

class InviteAdminModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: null,
            lastName: null,
            email: null,
            loading: false,
            error: null,
            validationErrors: {
                firstName: null,
                lastName: null,
                email: null
            }
        }
    }

    onChange = (event, {value, name}) => {
        let {validationErrors} = this.state
        validationErrors[name] = null
        this.setState({[name]: value, validationErrors: validationErrors})
    }

    save = async () => {
        if (!this.validate()) {
            return
        }
        const {onSave, employerName, employerId} = this.props
        const {firstName, lastName, email} = this.state
        this.setState({loading: true, error: null})
        try {
            const payload = {employerName, email, firstName, lastName, employerId}
            const result = await axios.post("/api/employer/v2/inviteAdminUser", payload, {headers: {'Content-Type': 'application/json'}});
            this.setState({loading: false, data: result.data})
            onSave()
        } catch (e) {
            const defaultError = "Unable to send invite"
            const error = resolveErrorMessage(e, defaultError)
            this.setState({loading: false, error})
        }
    }

    renderError = () => {
        const {error} = this.state
        const inviteError = "The invite for this admin cannot be processed. Check the admin list to confirm an account doesn’t already exist. For additional help, reach out to our team."
        const messageUserAlreadyInvited = "user has already been invited"
        switch (error.toLowerCase()) {
            case messageUserAlreadyInvited:
                return <Message className={"errorBox"}><b>Invite Error. </b>{inviteError}</Message>
            default:
                return  <Message negative size="mini">{error}</Message>
        }
    }

    validate = () => {
        const {firstName, lastName, email, validationErrors} = this.state;
        let valid = true
        if (!firstName) {
            validationErrors.firstName = "Must enter first name"
            valid = false
        }
        if (!lastName) {
            validationErrors.lastName = "Must enter last name"
            valid = false
        }
        if (!email) {
            validationErrors.email = "Must enter email address"
            valid = false
        } else if (!isValidEmail(email)) {
            validationErrors.email = "Must enter a valid email address"
            valid = false
        }
        if (!valid) {
            this.setState({validationErrors})
        }
        return valid
    }

    render = () => {
        const {open, onCancel} = this.props
        const {firstName, lastName, email, loading, error, validationErrors} = this.state;
        return (
            <Modal size={'mini'} onClose={onCancel} open={open}>
                <CustomModalHeader title={"Invite new admin"} onClose={onCancel}/>
                <Modal.Content>
                    <p>Enter details below to invite a new administrative user to your <StrategicPartnerLabel/> portal—</p>
                    <Form loading={loading}>
                        <Form.Field error={validationErrors.firstName !== null}>
                            <label>First name <RequiredSpan/></label>
                            <Input name="firstName" value={firstName || ''} onChange={this.onChange}/>
                            {validationErrors.firstName &&
                            <small style={errorStyle}>{validationErrors.firstName}</small>}
                        </Form.Field>
                        <Form.Field error={validationErrors.lastName !== null}>
                            <label>Last name <RequiredSpan/></label>
                            <Input name="lastName" value={lastName || ''} onChange={this.onChange}/>
                            {validationErrors.lastName && <small style={errorStyle}>{validationErrors.lastName}</small>}
                        </Form.Field>
                        <CustomEmailInput
                            name="email"
                            required
                            value={email || ''}
                            onChange={this.onChange}
                            label="Email address"
                            customError={validationErrors.email}
                            placeholder=""
                        />
                        <CustomDivider hidden/>
                        {error &&
                            <div style={{marginBottom: 21}}>
                                {this.renderError()}
                            </div>
                        }
                        <Button primary floated={"right"} className={"employer-rightButton"} onClick={this.save}>
                            Send invitation
                        </Button>
                        <Button secondary basic floated={"right"} onClick={onCancel}>Cancel</Button>
                    </Form>
                </Modal.Content>
                <CustomDivider hidden/>
            </Modal>
        )
    }
}

export default InviteAdminModal;
