import {portalActionTypes, authActionTypes} from "../actions/actionTypes";

const initialState = {
    activeContractId: null,
    contract: null,
    invoices: [],
    general: {
        ageRangesPerClass: [],
        genderOptions: [],
        phoneTypeOptions: [],
        nameSuffixOptions: [],
        stateOptions: [],
        payrollFreqOptions: []
    },
    isBenAdmin: null,
    footer: null
};

export const portalReducer = (state = initialState, action) => {
    switch (action.type) {
        case portalActionTypes.SET_PORTAL_DATA:
            let general = {}
            general.ageRangesPerClass = action.payload.ageRangesPerClass
            general.genderOptions = action.payload.genderOptions.map(o => {return {key: o.key, value: o.value, text: o.text}})
            general.phoneTypeOptions = action.payload.phoneTypeOptions.map(o => {return {key: o.key, value: o.value, text: o.text}})
            general.nameSuffixOptions = action.payload.nameSuffixOptions.map(o => {return {key: o.key, value: o.value, text: o.text}})
            general.stateOptions = action.payload.stateOptions.map(o => {return {key: o.key, value: o.value, text: o.text}})
            general.payrollFreqOptions = action.payload.payrollFreqOptions.map(o => {return {key: o.key, value: o.value, text: o.text}})

            return {
                ...state,
                activeContractId: action.payload.contract.contract.id,
                contract: action.payload.contract,
                isBenAdmin: action.payload.isBenAdmin,
                general: general,
                footer: action.payload.footer
            }
        case authActionTypes.LOGOUT:
            return initialState;
        case portalActionTypes.EMPLOYER:
            return {
                ...state,
                contract: {
                    ...state.contract,
                    employer: action.payload
                }
            }
        default:
            return state
    }
}
