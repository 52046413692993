import React from 'react';
import PropTypes from 'prop-types';
import {CustomContainer, CustomGrid, S3Image} from './index';
import IMAGE from '../../constants/images';
import {getQLEIcon} from '../../utils/qle';

export const CustomQLECard = ({
    qleStatus,
    cardType,
    qleName,
    reason,
    changeRequest,
    description,
    footerData
}) => {

    const imgName = getQLEIcon(qleStatus).toUpperCase()

    return (
        <CustomContainer className={`qle-card ${cardType}`}>
            <div>
                <div className="icon">
                    <S3Image {...IMAGE[imgName]} alt='Event card icon'/>
                </div>
                <div className="content">
                    <p className="reason"><b>{qleName}</b>{reason && (`: ${reason}`)}</p>
                    {changeRequest && <p className="change-request"><b>Change request:</b> {changeRequest}</p>}
                    <p className="description">{description}</p>
                </div>
            </div>
            {footerData && (
            <div className='qleFooter'>
                <div className="icon"></div>
                <div className="content">
                    <CustomGrid columns={2} compact={"vertically"}>
                            {footerData?.map((item, index) => {
                                return (
                                    <CustomGrid.Column key={index}>
                                            <div className='qleFooter-label'>{item.title}</div>
                                            <div className='qleFooter-value'>{item.value}</div>
                                    </CustomGrid.Column>
                                )
                            })}
                    </CustomGrid>
                </div>
            </div>
            )}
        </CustomContainer>
    );
};

CustomQLECard.propTypes = {
    qleStatus: PropTypes.string.isRequired,
    cardType: PropTypes.string.isRequired,
    qleName: PropTypes.string.isRequired,
    reason: PropTypes.string,
    changeRequest: PropTypes.string,
    description: PropTypes.element,
    footerData: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default CustomQLECard;