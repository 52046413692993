import React, {Component} from 'react';
import {connect} from "react-redux";
import {Container, Grid, Header} from "semantic-ui-react";
import {withRouter} from "react-router-dom";
import Footer from "../footer";
import AccountCreationHeader from "./accountCreationHeader";
import {logout} from "../../actions/authActions";
import IMAGE from "../../constants/images";
import {ClickableText, CustomDivider, S3Image} from '../custom-common';

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
});

class Congratulations extends Component {
    constructor(props) {
        super(props);
    }

    logout = () => {
        const {dispatch} = this.props;
        dispatch(logout())
        this.props.history.push({pathname: "/login"});
    }

    render() {
        return (
            <div className="employer-main-container">
                <AccountCreationHeader prevRoute={this.props.prevRoute} history={this.props.history}/>
                <Container fluid className={"employer-accountCreation-container"}>
                    <Grid columns={1}>
                        <Grid.Column textAlign="center">
                            <CustomDivider hidden/>
                            <S3Image {...IMAGE.CHECK_80PX} />
                            <Header as='h2' className={'colorfulText'}>
                                Congratulations
                            </Header>
                            <span>Your account has been successfully created.</span>
                        </Grid.Column>
                    </Grid>
                    <CustomDivider hidden/>
                    <Grid container stackable centered>
                        <Grid.Row>
                            <Grid.Column width={7}>
                                <Header as='h4' className={'colorfulText'}>Next steps</Header>
                                <p>
                                    Now that your group application is signed, we will reach out to you to finish your account set up and configure your open enrollment.
                                </p>

                                <p>
                                    Enrollment communication and employee education are super important and we're here to help. We have a variety of resources to ensure a
                                    successful enrollment for your employees.
                                </p>

                                <CustomDivider hidden/>
                                <Header as='h4' className={'colorfulText'}>All done!</Header>
                                <p>Feel free to close your browser tab or <ClickableText
                                    onClick={() => this.logout() }
                                >log out</ClickableText>.</p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    {/*<Button floated={"right"} primary as={NavLink} style={{width: '100px'}} to={this.props.nextRoute}>Next</Button>*/}
                    <CustomDivider hidden/>
                </Container>
                <Footer hasToDisplayCarrierDisclaimer/>
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps)(Congratulations));
