import React from "react";
const SmsConfig = () => {
    return (
        <>
            Configure Phone Number
        </>
    )
}


export default SmsConfig;