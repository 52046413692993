import React, {Component} from 'react';
import {connect} from "react-redux";
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import PlanInfo from "./planInfo/planInfo";
import OpenEnrollment from './openEnrollment';
import Employees from "./employees/employees";
import Employee from "./employees/employee";
import Account from "./account/account";
import ScrollToTop from "../scrollToTop";
import axios from "axios";
import {Loader, Message} from "semantic-ui-react";
import Reports from "./reports/reports";
import Billing from "./billing/billing";
import InvoiceDetails from "./billing/invoiceDetails";
import {setAuthToken} from "../../actions/authActions";
import {setBillingData} from "../../actions/billingActions";
import {setBankAccountsData, setPendingVerificationBankAccounts} from "../../actions/bankAccountActions";
import {setData as setOpenEnrollmentData} from "../../actions/openEnrollmentActions";
import {setPortalData} from "../../actions/portalActions";
import {
    setEmployees,
    setEmployeesFirstPageLoading,
    setEmployeesError,
    setTotalActiveEmployeesCount,
    setTotalInactiveEmployeesCount,
} from "../../actions/employeesActions"
import PortalNotAvailable from '../pages/portalNotAvailable';
import EmploymentTerminationDetails from "./employeeTermination/employment/employmentTerminationDetails";
import EmploymentTerminationReview from "./employeeTermination/employment/employmentTerminationReview";
import EmploymentTerminationSuccess from "./employeeTermination/employment/employmentTerminationSuccess";

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        loadedPortal: !!(state.portal && state.portal.contract),
        contract: state.portal && state.portal.contract,
        activeContractId: state.portal && state.portal.activeContractId,
        historicalInvoices: state.billing && state.billing.historicalInvoices,
        oeStatus: state.openEnrollment.status,
        loadedActiveEmployees: state.employees.loadedActiveEmployees,
        loadedInactiveEmployees: state.employees.loadedInactiveEmployees,
    }
};

class Portal extends Component {
    constructor(props) {
        super(props);
        this.controller = new AbortController();
        this.state = {
            loading: true,
            error: null,
            queyMoreEmployees: "",
            isEmployeesFirstPage: true,
            portalDisabled: false,
        }
    }

    componentWillUnmount() {
        this.controller.abort()
    }

    async componentDidMount() {
        const { history } = this.props;
        if (history?.location?.state?.backToSettings) {
            this.setState({
                loading: false
            });
        }else {
            try {
                const {dispatch} = this.props
                const result = await axios.post("/api/employer/v2/preparePortal", {}, {headers: {'Content-Type': 'application/json'}});
                if (result.data.contract?.contractGroupStatus === "Draft") {
                    this.setState({
                        loading: false,
                        portalDisabled: true
                    })

                    return
                }
                if (result.data.authToken) {
                    dispatch(setAuthToken(result.data.authToken))
                }
                dispatch(setEmployeesFirstPageLoading(true))
                this.prepareEmployees()
                const data = {...result.data.billingInfo, plaidEnvironment: result.data.billingInfo.plaidEnvironment}
                dispatch(setBillingData(data));
                dispatch(setBankAccountsData(result.data.billingInfo.bankAccounts));
                dispatch(setPendingVerificationBankAccounts(result.data.billingInfo.microDepositsBankAccounts));
                dispatch(setPortalData(result.data))
                dispatch(setOpenEnrollmentData(result.data.openEnrollment))
                this.setState({ loading: false })
            } catch (e) {
                console.warn(e)
                this.setState({ loading: false, error: e.message })
            }
        }
    }

    isLastEmployeesPage = data => data.queryMore === null

    prepareEmployees = () => {
        const {dispatch, activeContractId: contractId} = this.props
        const {queyMoreEmployees, isEmployeesFirstPage} = this.state
        dispatch(setEmployeesError(null))
        axios.post("/api/employer/v2/prepareEmployees",
          {queryMore: queyMoreEmployees, contractId},
          {headers: {'Content-Type': 'application/json'}, signal: this.controller.signal})
          .then(response => {
              const {data} = response
              const {employees, queryMore, activeCount, inactiveCount} = data
              if (isEmployeesFirstPage) {
                  dispatch(setEmployeesFirstPageLoading(false))
                  dispatch(setTotalActiveEmployeesCount(activeCount))
                  dispatch(setTotalInactiveEmployeesCount(inactiveCount))
              }
              dispatch(setEmployees(employees))
              this.setState({isEmployeesFirstPage: false})
              if(!this.isLastEmployeesPage(data)) {
                  this.setState({queyMoreEmployees: queryMore})
                  this.prepareEmployees()
              }
          })
          .catch(e => {
              console.warn(e)
              this.setPrepareEmployeesError()
          })
    }

    setPrepareEmployeesError() {
        const {dispatch} = this.props
        dispatch(setEmployeesFirstPageLoading(false))
        dispatch(setEmployeesError("Unable to load employees"))
    }

    render() {
        const {loadedPortal, match, oeStatus} = this.props
        const {loading, portalDisabled, error} = this.state

        if (error) return (
            <Message negative>
                <Message.Header>Unable to load portal information</Message.Header>
                <p>{error}</p>
            </Message>
        )

        if (portalDisabled) return <PortalNotAvailable />

        if (loading || !loadedPortal) return (
            <div className="employer-portal-main-container">
                <Loader active/>
            </div>
        )

        return (
            <div className="employer-portal-main-container">
                <React.Fragment>
                    <ScrollToTop/>
                    <Switch>
                        {!['unavailable'].includes(oeStatus) && (
                            <Route exact path={`${match.path}/open-enrollment`} component={OpenEnrollment}/>
                        )}
                        <Route exact path={`${match.path}/plan`} component={PlanInfo}/>
                        <Route exact path={`${match.path}/employees`} component={Employees}/>
                        <Route exact path={`${match.path}/employee/:id`} render={() => (<Employee/>)}/>
                        <Route exact path={`${match.path}/employee/:id/employment-termination`} component={EmploymentTerminationDetails}/>
                        <Route exact path={`${match.path}/employee/:id/employment-termination/review`} component={EmploymentTerminationReview}/>
                        <Route exact path={`${match.path}/employee/:id/employment-termination/success`} component={EmploymentTerminationSuccess}/>
                        <Route exact path={`${match.path}/account`} render={() => <Account/>}/>
                        <Route exact path={`${match.path}/reports`} component={Reports}/>
                        <Route exact path={`${match.path}/billing`} component={Billing}/>
                        <Route exact path={`${match.path}/invoice/:id`} render={() => (
                            <InvoiceDetails prevRoute={`${match.path}/billing`}/>
                        )}/>
                        <Redirect to={`${match.path}/plan`}/>
                    </Switch>
                </React.Fragment>
            </div>
        )
    }
}

export default connect(mapStateToProps)(withRouter(Portal));
