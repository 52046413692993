// Global Site color palette
// All color values must be defined here.
// The palette should only be used within the theme (not the components).

export const palette = {
  voyaOrange:     '#F58000',
  voyaDarkOrange: '#D75426',
  voyaYellow:     '#FFC700',
  voyaViolet:     '#B73F7C',
  voyaPlum:       '#551B57',
  voyaLightBlue:  '#76C5E4',
  voyaDarkBlue:   '#145A7B',
  voyaLightGreen: '#9AC1A6',
  voyaTeal:       '#0097A9',
  voyaGray:       '#6E6E6E',
  voyaLightGray:  '#F2F2F2',
  seaBlue:        '#72b0bd',
  deepTeal:       '#0C4B69',

  // Neutrals
  neutral50:  '#F5F6F7',
  neutral100: '#F5F5F5',
  neutral200: '#EEEEEE',
  neutral300: '#E0E0E0',
  neutral400: '#BDBDBD',
  neutral500: '#9E9E9E',
  neutral600: '#757575',
  neutral700: '#616161',
  neutral800: '#424242',
  neutral900: '#212121',

  // Generic
  white:          '#fff',
  black:          '#000',
}
