import { css } from 'styled-components'

const breakpoints = {
  mobile: null,
  tablet: 1024,
  desktop: 1440,
}

const bpFrom = (value, ...args) => css`
  @media screen and (min-width: ${value}px) {
    ${css(...args)}
  }
`

const bpTo = (value, ...args) => css`
  @media not screen and (min-width: ${value}px) {
    ${css(...args)}
  }
`

// From breakpoint upwards
export const from = {
  mobile: (...args) => css(...args),
  tablet: (...args) => bpFrom(breakpoints.tablet, ...args),
  desktop: (...args) => bpFrom(breakpoints.desktop, ...args),
}

// Up to breakpoint (excluded from next breakpoint)
export const to = {
  mobile: (...args) => bpTo(breakpoints.tablet, ...args),
  tablet: (...args) => bpTo(breakpoints.desktop, ...args),
  desktop: (...args) => css(...args),
}
