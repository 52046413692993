import React, {useState} from 'react';
import {Header, Table, Button, Message} from 'semantic-ui-react';
import {ViewMoreButton} from "../../base";
import {ClickableText} from '../../custom-common';

const OtherAdminsTable = ({data, removeUser, addNewAdmin}) => {
  const [maxDisplayCount, setMaxDisplayCount] = useState(10)

  const showViewMore = () => (!!data && data.length > 0) && (maxDisplayCount < data.length)
  const noAdminsTodisplay = () => !!data && data.length === 0


  const onViewMore = () => {
    setMaxDisplayCount(maxDisplayCount+10)
  }

  const TableHeader = () => {
    return (
      <div>
        <div className={"employer-headerContainer employer-account-flexHeaderContainer"}>
          <div>
            <Header className={"adminsTableHeader colorfulText"}
                    as='h4'
            >Administrator access
            </Header>
          </div>
          <Button
            className='admin-add-button'
            primary
            basic
            onClick={addNewAdmin}
            style={{height: 40, padding: '0px 14px' }}>+ Add new admin
          </Button>
        </div>
      </div>
    )
  }

  return (
    <>
      <TableHeader />
      <Table sortable basic='very' className={"adminsTable"}>
        {!noAdminsTodisplay() &&
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell >First name</Table.HeaderCell>
              <Table.HeaderCell>Last name</Table.HeaderCell>
              <Table.HeaderCell>Email address</Table.HeaderCell>
              <Table.HeaderCell>Access</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
        }
        <Table.Body>
          {noAdminsTodisplay() ?
            <Message>There are no other admins</Message> :
            (data.slice(0, maxDisplayCount).map((item, index) => {
                const { firstName, lastName, email } = item
                return <Table.Row key={index}>
                  <Table.Cell><b>{firstName}</b></Table.Cell>
                  <Table.Cell><b>{lastName}</b></Table.Cell>
                  <Table.Cell>{email}</Table.Cell>
                  <Table.Cell><ClickableText onClick={() => removeUser(item)}>Remove</ClickableText></Table.Cell>
                </Table.Row>
              })
            )}
        </Table.Body>
      </Table>
      {showViewMore() &&
        <ViewMoreButton className="linkText" onViewMore={onViewMore} />
      }
    </>
  );
};

export default OtherAdminsTable;
