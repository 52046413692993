import React, {Component} from 'react';
import {Segment} from "semantic-ui-react";
import {withRouter} from "react-router-dom";
import axios from "axios";
import {connect} from "react-redux";

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    initialData: state.accountCreation.initialData
});

class Sign extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            dsSignCeremonyUrl: "",
            signed: false,
        };
        this.bindEvents();
    }

    callCreateEnvelope = async () => {
        this.setState({loading: true, error: null});
        try {
            let result = await axios.post("/api/employer/v2/prepareDocusign");
            if (result) {
                this.setState({loading: false, dsSignCeremonyUrl: result.data.url, signed: result.data.signed});
                if (result.data.signed) {
                    this.checkSigned();
                }
            }
        } catch (err) {
            this.setState({loading: false, error: err.response.data});
        }
    };

    componentDidMount = async () => {
        await this.callCreateEnvelope();
    };

    componentWillUnmount() {
        clearInterval(this.longPooling);
    }

    bindEvents = () => {
        window.addEventListener("message", this.onMessageReceived, false);
    };

    onMessageReceived = async (event) => {
        if (event.data && typeof event.data === "string") {
            let data = JSON.parse(event.data);
            if (data.message === "complete_agreement") {
                this.checkSigned();
            }
        }
    };

    checkSigned = () => {
        this.setState({loading: true});
        this.longPooling = setInterval(async () => {
            try {
                let result = await axios.post("/api/employer/v2/checkSignStatus");
                if (result.data?.signed) {
                    clearInterval(this.longPooling);
                    this.props.history.push("/accountCreation/success");
                }
            } catch (error) {
                clearInterval(this.longPooling);
                this.setState({loading: false});
            }
        }, 3000);
    }

    render() {
        const {dsSignCeremonyUrl, loading} = this.state;

        return (
            <div className="employer-main-container">
                <Segment loading={loading} vertical basic style={{padding: 0}}>
                    <iframe
                        style={{height: "100vh", marginBottom: '-100px'}}
                        src={dsSignCeremonyUrl}
                        title={"ds"}
                        width={"100%"}
                        height={"850"}
                        scrolling={"yes"}
                        frameBorder="0"
                    />
                </Segment>
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps)(Sign));