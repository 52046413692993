import React, {Component} from 'react'
import {Button, Message, Modal} from "semantic-ui-react"
import axios from "axios"
import {CustomModalHeader} from "../../custom-common";

class RemoveUserAccessModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: null,
        }
    }

    removeUser = async () => {
        const {user, onRemove} = this.props
        try {
            this.setState({loading: true})
            await axios.post("/api/employer/v2/removeAdminUser", {"AdminSfId": user.id}, {headers: {'Content-Type': 'application/json'}})
            this.setState({loading: false})
            onRemove()
        } catch (error) {
            this.setState({loading: false, error})
        }
    }

    render = () => {
        const {
            open,
            onCancel,
            user,
        } = this.props
        const {loading, error} = this.state;
        return (
            <Modal
                onClose={onCancel}
                open={open}
                className={'removeAdminUserModal'}
            >
                <CustomModalHeader title={"Remove user access"} onClose={onCancel}/>
                <Modal.Content>
                    <div className={"employer-smallModalMainContent"}>
                        {error && <Message negative size="mini">An unexpected error ocurred</Message>}
                        {!error && <p>Once removed, <span className={'bold'}>{`${user.firstName} ${user.lastName}`}</span> will no longer have access to your portal.</p> }
                    </div>
                    <div>
                        <Button
                            negative
                            loading={loading}
                            disabled={loading}
                            floated={"right"}
                            onClick={this.removeUser}
                            className={"employer-rightButton"}
                        >
                            Remove access
                        </Button>
                        <Button
                            primary
                            basic
                            floated={"right"}
                            onClick={onCancel}
                        >Cancel</Button>
                    </div>
                </Modal.Content>
            </Modal>
        )
    }
}

export default RemoveUserAccessModal;
