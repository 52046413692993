import React from 'react'
import {Container,  Divider as SCDivider, Text} from './styled';

export const ViewMoreButton = ({ className, onViewMore }) => {
  return (
    <Container>
      <SCDivider />
      <Text className={className} onClick={onViewMore}>View More</Text>
    </Container>
  )
}
