import {billingActionTypes, authActionTypes} from "../actions/actionTypes";

const initialState = {
    currentInvoices: null,
    previewInvoice: null,
    historicalInvoices: [],
    accountBalance: null,
    paymentsUnappliedAmount: null,
    currentBilling: null,
    pastDue: null,
    today: null,
    plaidEnvironment: null,
    plaidLinkToken: null,
    isNotAvailableToPay: null,
    isLastPaymentSucceeded: null,
    lastPaymentDate: null,
    lastPaymentAmount: null
};

export const billingReducer = (state = initialState, action) => {
    switch (action.type) {
        case billingActionTypes.SET_BILLING_DATA:
            const billingData = action.payload;
            return {
                ...state,
                currentInvoices: billingData.currentInvoices,
                previewInvoice: billingData.previewInvoice,
                historicalInvoices: billingData.historicalInvoices,
                accountBalance: billingData.accountBalance,
                paymentsUnappliedAmount: billingData.paymentsUnappliedAmount,
                currentBilling: billingData.currentBilling,
                pastDue: billingData.pastDue,
                today: billingData.today,
                plaidEnvironment: billingData.plaidEnvironment,
                plaidLinkToken: billingData.plaidLinkToken,
                chargeAttempts: billingData.chargeAttempts,
                lastChargeDate: billingData.lastChargeDate,
                employerId: billingData.employerId,
                isNotAvailableToPay: billingData.isNotAvailableToPay,
                isLastPaymentFailed: billingData.isLastPaymentFailed,
                lastPaymentDate: billingData.lastPaymentDate,
                lastPaymentAmount: billingData.lastPaymentAmount,
                isLastPaymentSucceeded: billingData.isLastPaymentSucceeded,
                selfBillThreshold: billingData.selfBillThreshold,
                showBannerSelfBill: billingData.showBannerSelfBill,
                isSelfBill: billingData.isSelfBill,
            }
        case billingActionTypes.SET_IS_NOT_AVAILABLE_TO_PAY:
            const isNotAvailableToPay = action.payload;
            return {
                ...state,
                isNotAvailableToPay: isNotAvailableToPay,
            }
        case authActionTypes.LOGOUT:
            return initialState;
        default:
            return state
    }
}
