import React, { Component } from 'react'
import './styles.css'

class ProgressBar extends Component {
    get data() {
        const { bars } = this.props

        const total = bars.reduce((a, { value }) => a + value, 0)

        const data = bars.map(bar => ({
            ...bar,
            ratio: total ? bar.value / total : 0
        }))

        return data
    }

    render() {
        const data = this.data

        if (!this.data?.length) return null

        return (
            <div className='jb-progress-bar'>
                {data.map(({ color, ratio }, key) => (
                    <div
                        style={{
                            flex: ratio,
                            width: `${ratio * 100}%`,
                            background: color,
                        }}
                        key={key}
                        className='jb-progress-bar__bar'
                    />
                ))}
            </div>
        )
    }
}

export default ProgressBar
