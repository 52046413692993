import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import {Grid, Loader, Menu, Message} from "semantic-ui-react";
import {connect} from "react-redux";
import PortalContainer from "../portalContainer";
import Overview from "./overview";
import PaymentDetails from "./paymentDetails";
import BillingAlert from "./BillingAlert";
import DirectBillSummary from './directBillSummary'
import { isDirectBilling, hasEmployerContributions } from '../../../selectors/billing'
import {CustomDivider} from '../../custom-common';

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        isDirectBilling: isDirectBilling(state),
        hasEmployerContributions: hasEmployerContributions(state)
    }
  }

class Billing extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeItem: null,
            loading: false,
            error: null,
        }
    }

    componentDidMount() {
        if (this.props.location.state && this.props.location.state.activeTab) {
            if (this.props.location.state.activeTab === 'paymentDetails') {
                this.switchToPaymentDetails()
            } else {
                this.switchToOverview()
            }
        }
    }

    handleItemClick = (_, {name}) => {
        this.setState({activeItem: name})
    }

    switchToPaymentDetails = () => {
        this.setState({activeItem: "paymentDetails"})
    }

    switchToOverview = () => {
        this.setState({activeItem: "overview"})
    }

    get activeItem() {
        const { isDirectBilling, hasEmployerContributions } = this.props
        const { activeItem } = this.state

        if (activeItem) return activeItem

        if (isDirectBilling && !hasEmployerContributions) return 'directBillSummary'

        return 'overview'
    }


    renderTabItem = (name, label) => (
        <Menu.Item
            style={{fontWeight: 'bold'}}
            name={name}
            active={this.activeItem === name}
            onClick={this.handleItemClick}
        >{label}</Menu.Item>
    )

    renderTabContent = (name, component) => {
        if (this.activeItem !== name) return null

        return component
        }

    renderTabs = () => {
        const { isDirectBilling, hasEmployerContributions, error } = this.props

        if (error) return <Message error>{error}</Message>


        const showDirectBill = isDirectBilling
        const showOtherTabs = !isDirectBilling || hasEmployerContributions

        return <>
            <Menu text size={"small"} className={"internalMenu"}>
                {showOtherTabs && <>
                    {this.renderTabItem('overview', 'Overview')}
                    {this.renderTabItem('paymentDetails', 'Payment details')}
                </>}
                {showDirectBill && this.renderTabItem('directBillSummary', 'Direct Bill Summary')}
            </Menu>
            {showOtherTabs && <>
                {this.renderTabContent('overview', (
                    <Overview switchToPaymentDetail={this.switchToPaymentDetails}/>
                ))}
                {this.renderTabContent('paymentDetails', (
                    <PaymentDetails />
                ))}
            </>}
            {showDirectBill && this.renderTabContent('directBillSummary', <DirectBillSummary />)}
        </>
    }

    render() {
        const { loading } = this.state

        return (
            <PortalContainer showLeftNavigation={true}>
                <BillingAlert switchToOverview={this.switchToOverview}/>
                {loading ? <div style={{height: 'calc(100vh - 250px)'}}><Loader active/></div> :
                    <Grid container stackable columns={1} style={{marginTop: 0}}>
                        <Grid.Column className={"pageContent"}>
                            <CustomDivider hidden/>
                            {this.renderTabs()}
                        </Grid.Column>
                    </Grid>}
            </PortalContainer>
        )
    }
}

export default connect(mapStateToProps)(withRouter(Billing));