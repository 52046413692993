import {combineReducers} from 'redux'
import {portalReducer} from "./portalReducer";
import {authReducer} from "./authReducer";
import accountCreationReducer from "./accountCreation/accountCreationReducer";
import {bankAccountReducer} from "./bankAccoutReducer";
import {billingReducer} from "./billingReducer";
import {openEnrollmentReducer} from "./openEnrollmentReducer";
import {employeesReducer} from "./employeesReducer";
import {authActionTypes} from "../actions/actionTypes";
import {portalModalHeaderReducer} from "./portalModalHeaderReducer";
import {employeeTerminationReducer} from "./employeeTerminationReducer";
import {footerReducer} from "./footerReducer";
import {toastReducer} from './toastReducer';
import {newHireDetailsReducer} from './newHireDetailsReducer';

const appReducer = combineReducers({
    accountCreation: accountCreationReducer,
    auth: authReducer,
    portal: portalReducer,
    billing: billingReducer,
    bankAccount: bankAccountReducer,
    openEnrollment: openEnrollmentReducer,
    employees: employeesReducer,
    portalModalHeader: portalModalHeaderReducer,
    employeeTermination: employeeTerminationReducer,
    footer: footerReducer,
    toast: toastReducer,
    newHireDetails: newHireDetailsReducer,
});

const rootReducer = (state, action) => {
    // Clear all data in redux store to initial.
    if (action.type === authActionTypes.LOGOUT) state = undefined;

    return appReducer(state, action);
 };

export default rootReducer;
