import React from 'react';
import {
  Header,
} from 'semantic-ui-react';


const MyInfo = ({data}) => {
  const {firstName, lastName, email} = data || {}
  const info = [
    {label: 'First name', value: firstName},
    {label: 'Last name', value: lastName},
    {label: 'Email address', value: email},
  ];

  return (
    <>
      <Header as="h4" className={"colorfulText"}>
        My Info
      </Header>
      <div className="employer-myInfoContainer-infoContainer">
        {info.map((item, index) => (
          <div key={index} className={`employer-myInfoContainer-infoItem ${index !== 0 ? 'employer-myInfoContainer-infoItem-notFirst' : ''}`}>
            <p className="employer-myInfoContainer-infoLabel">{item.label}</p>
            <p className="employer-myInfoContainer-infoValue">{item.value}</p>
          </div>
        ))}
      </div>
    </>

  );
};

export default MyInfo;
