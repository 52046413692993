import React, {Component} from 'react';
import {Grid, Message, Loader, Form, Container} from "semantic-ui-react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import axios from "axios";
import Footer from "./footer";
import {resolveErrorMessage} from "../utils/utils";
import AppHeader from "./appHeader";

class ConfirmUsername extends Component {
    constructor(props) {
        super(props);
        this.state = {
            requesting: false,
            submitError: null
        }
    }

    async componentDidMount() {
        try {
            this.setState({requesting: true, submitError: null});
            const token = new URLSearchParams(window.location.search).get("token");
            if (!token) {
                const error = resolveErrorMessage("", "Missing token")
                this.setState({
                    requesting: false,
                    submitError: error
                });
                throw new Error("Missing token");
            }
            const payload = {
                token: token,
                portal: "employer"
            }
            await axios.post("/api/provisioning/v2/confirmUsername", payload, {headers: {'Content-Type': 'application/json'}});
            this.setState({requesting: false, submitError: null});
            this.props.history.push("/login");
        } catch (e) {
            console.log("Error in /confirmUsername: ", e);
            const error = resolveErrorMessage(e, "Invalid token")
            if (error === "Invalid token") {
                this.props.history.replace({pathname: "/login", state: {resetLink: true}});
            }
            this.setState({
                requesting: false,
                submitError: error
            });
        }
    }

    render() {
        const {requesting, submitError} = this.state;
        return (
            <div className="employer-main-container">
                <AppHeader/>
                <Container>
                <div className="employer-portal-container">
                    <Grid container stackable centered>
                        <Grid.Column style={{marginTop: '8em'}} width={6} className={"pageContent"}>
                            {!!submitError &&
                                <Form.Field><Message negative>{submitError}</Message></Form.Field>}
                            {requesting && <Loader active inline='centered' size='large'>Validating new email address</Loader>}
                        </Grid.Column>
                    </Grid>
                </div>
                </Container>
                <Footer showTopFooter={false}/>
            </div>
        )
    }
}

export default withRouter(connect()(ConfirmUsername));
