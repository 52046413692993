import React, {Component} from 'react';
import {connect} from "react-redux";
import {Container, Grid} from "semantic-ui-react";
import {withRouter} from "react-router-dom";
import AccountCreationHeader from "./accountCreationHeader";
import Footer from "../footer";
import {StrategicPartnerLabel} from "../common";
import {Header} from '../base';
import IMAGE from "../../constants/images";
import {CustomDivider, S3Image} from '../custom-common';

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
    }
};

class NextStep extends Component {

    render() {
        return (
            <div className="employer-main-container">
                <AccountCreationHeader history={this.props.history}/>
                <Container fluid className={"employer-accountCreation-container"}>
                    <Grid columns={1}>
                        <Grid.Column textAlign="center">
                            <CustomDivider hidden medium/>
                            <S3Image {...IMAGE.ENVELOPE_SIGNATURE} />
                            <Header as='h2'>
                              Next steps
                            </Header>
                        </Grid.Column>
                    </Grid>
                    <CustomDivider hidden medium/>
                    <Grid container stackable centered>
                        <Grid.Row>
                            <Grid.Column width={7}>
                                <p>
                                    You’re <StrategicPartnerLabel/> portal is being configured. You’ll recieve a notification when it’s ready for review!
                                </p>
                                <CustomDivider hidden section/>
                                <Header as='h4'>Questions?</Header>
                                <p>
                                  Call our concierge team at 888-888-8888 or email us at <a href="mailto:support@joinansel.com">support@joinansel.com</a>. Our hours are 9am-9pm, M-F.
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    <CustomDivider hidden/>
                </Container>
                <Footer />
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps)(NextStep));
