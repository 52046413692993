import React, {Component} from 'react';
import {Icon, Popup, Table} from "semantic-ui-react";
import {formatPlanIncrementList, parseMultilineString} from "../../../utils/utils";
import {brellaChoice, plansDescription} from "../../../utils/strings"
import BottomReviewChoices from './bottomReviewChoices';
import { Header } from '../../base';
import {CustomDivider} from '../../custom-common';

class ReviewChoice extends Component {

    render() {
        const {
            optionalRiders,
            increments,
            ratedClassUtil,
            ageRanges
        } = this.props;

        return(
            <React.Fragment>
                <CustomDivider hidden/>
                <Header as={"h2"}>Design details</Header>
                <Table padded={"very"}>
                    <Table.Header className="secondTableHeader">
                        <Table.Row>
                            <Table.HeaderCell textAlign="left" width={4}>Category</Table.HeaderCell>
                            <Table.HeaderCell textAlign="left" colSpan={8}>Increments <span>
                                                    <Popup trigger={<Icon name="info circle" link/>}
                                                           content="Employees select benefit amount in each category"
                                                           basic position='top center'/>
                                                </span>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        <Table.Row verticalAlign={"middle"}>
                            <Table.Cell>
                                <div className="colorfulText">Moderate</div>
                                <p className={"smaller neutral700Text"}>{parseMultilineString(plansDescription.moderate)}</p>
                            </Table.Cell>
                            <Table.Cell className={"small"} singleLine><p>{formatPlanIncrementList(increments.moderate)}</p></Table.Cell>
                        </Table.Row>
                        <Table.Row verticalAlign={"middle"}>
                            <Table.Cell>
                                <div className="colorfulText">Severe</div>
                                <p className={"smaller neutral700Text"}>{parseMultilineString(plansDescription.severe)}</p>
                            </Table.Cell>
                            <Table.Cell singleLine className={"small"}><p>{formatPlanIncrementList(increments.severe)}</p></Table.Cell>
                        </Table.Row>
                        <Table.Row verticalAlign={"middle"}>
                            <Table.Cell>
                                <div className="colorfulText">Catastrophic</div>
                                <p className={"smaller neutral700Text"}>{parseMultilineString(plansDescription.catastrophic)}</p></Table.Cell>
                            <Table.Cell singleLine className={"small"}><p>{formatPlanIncrementList(increments.catastrophic)}</p></Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
                <BottomReviewChoices
                    optionalRiders={optionalRiders}
                    ratedClassUtil={ratedClassUtil}
                    ageRanges={ageRanges}
                    planOffering={brellaChoice}
                />
            </React.Fragment>)
    }
}

export default ReviewChoice;