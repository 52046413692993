import React, { Component } from 'react'
import { Segment } from 'semantic-ui-react'
import Concierge from '../../concierge'
import {ClickableText} from '../../custom-common'

class DirectBillSummary extends Component {
  state = {
    showConciergeModal: false,
  }

  showConciergeModal = (value) => {
    this.setState({ showConciergeModal: value })
  }

  handleSendMessageClick = e => {
    e.preventDefault()
    console.log('Click!')
    this.showConciergeModal(true)
  }

  render() {
    const { showConciergeModal } = this.state

    console.log({ showConciergeModal })

    return (
      <Segment style={{ padding: '2em' }}>
          <div className='direct-billing-summary'>
            <img
              src='https://brella-platform-assets.s3.amazonaws.com/employer/lock-badge-success.png'
              width='64'
              height='64'
              alt='Direct Bill is enabled'
            />
            <h4>Direct billing is enabled.</h4>
            <p>Employees are billed each month directly by Brella.</p>
            <p>For any changes to this setting, <ClickableText onClick={this.handleSendMessageClick}>send us a message</ClickableText> or call (888) 300-5382.</p>
          </div>
          <Concierge open={showConciergeModal} onManage={this.showConciergeModal} hasDedicatedConcierge />
      </Segment>
    )
  }
}

export default DirectBillSummary;
