import React, {Component} from 'react';
import {Grid} from "semantic-ui-react";
import CustomStep from './customStep';

class Steps extends Component {
    render() {
        const {stepProps, customStyle, lineWidth} = this.props;
        const stepsCount = stepProps.length;
        const steps = stepProps.map((s, idx) => {
            return <Grid.Column key={idx} style={{...lineWidth && lineWidth}}>
                <CustomStep completed={!!s.completed}
                            stepNumber={idx + 1}
                            active={!!s.active}
                            disabled={!!s.disabled}
                            numberLabel={s.numberLabel}
                            lastStep={stepsCount}/>
            </Grid.Column>
        });

        const stepLabels = stepProps.map((s, idx) => {
            return <Grid.Column key={idx} style={{...lineWidth && lineWidth}}>
                <div style={{width: '130px', textAlign: 'center'}} className={(s.disabled) ? 'disabledStep' : ""}>
                    <p className="smaller">{s.label}</p>
                </div>
            </Grid.Column>
        });

        return (
            <div style={{marginLeft: '50%'}}>
                <div className='steps' style={{marginLeft: '-210px', textAlign: 'center', marginBottom: '3em', maxWidth: '450px', ...customStyle}}>
                    <Grid style={{marginLeft: '57px', marginRight: '0px'}} columns={stepsCount}>
                        {steps}
                    </Grid>
                    <Grid style={{marginLeft: '0px', marginRight: '60px', marginTop: '0px'}} columns={stepsCount}>
                        <Grid.Row style={{paddingTop: '8px'}}>
                            {stepLabels}
                        </Grid.Row>
                    </Grid>
                </div>
            </div>
        )
    }
}

export default Steps;
