import React from "react";
const EmailConfig = () => {
    return (
        <>
            Email 2-step verification
        </>
    )
}


export default EmailConfig