import React, {Component} from 'react';
import {connect} from "react-redux";
import {Container, Grid, Header} from "semantic-ui-react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTools} from '@fortawesome/free-solid-svg-icons'
import {withRouter} from "react-router-dom";
import Footer from "../footer";
import AccountCreationHeader from "../accountCreation/accountCreationHeader";
import STRATEGIC_PARTNER from '../../constants/strategicPartners';
import {CustomDivider} from '../custom-common';

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    contractEmployerName: state.auth.user.contractEmployerName,
  }
};

class PortalNotAvailable extends Component {
  render() {
    const { contractEmployerName } = this.props

    return (
      <div className="employer-main-container">
        <AccountCreationHeader prevRoute={this.props.prevRoute} history={this.props.history} />
        <Container fluid className={"employer-accountCreation-container"} style={{ marginTop: '16vh' }}>
          <Grid columns={1}>
            <Grid.Column textAlign="center">
            <CustomDivider hidden medium/>
              <FontAwesomeIcon icon={faTools} style={{
                backgroundColor: "#F7D2CC",
                width: "48px",
                height: "48px",
                padding: "28px",
                borderRadius: "50%",
                color: "#616161",
              }} />
              <Header as='h3' color="black">
                We're Sorry!
              </Header>
              <span> The {STRATEGIC_PARTNER.LABEL} portal for {contractEmployerName} is not available. </span>
            </Grid.Column>
          </Grid>
          <CustomDivider hidden medium/>
        </Container>
        <Footer />
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps)(PortalNotAvailable));