import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import {Button, Form, Grid, Icon, Message, Segment} from "semantic-ui-react";
import PortalContainer from "../portalContainer";
import axios from "axios";
import moment from "moment";
import BillingAlert from "../billing/BillingAlert";
import {Header} from '../../base';
import {ClickableText, CustomDivider, DateSelector} from '../../custom-common';

const MAX_RECORDS = 50
const LINE_FINE = "\r\n"

const reportType = {
    enrollment: 'enrollment',
    demographic: 'demographic'
}

class Reports extends Component {
    constructor(props) {
        super(props);
        this.timer = null;
        this.state = {
            activeItem: null,
            enrollmentLoading: false,
            enrollmentError: null,
            enrollmentDateOfHireFilter: null,
            enrollmentDateOfHire: null,
            enrollmentCoverageDateFilter: null,
            enrollmentCoverageDateFrom: null,
            enrollmentCoverageDateTo: null,
            demographicLoading: false,
            demographicError: null,
            demographicDateOfHireFilter: null,
            demographicDateOfHire: null,
            demographicTerminationDateFrom: null,
            demographicTerminationDateTo: null,
            demographicEmploymentStatus: null,
            demographicRole: null,
            dataCsv: null,
            payloadReport: null,
        }
    }

    handleItemClick = (name) => {
        const {activeItem} = this.state
        if (activeItem === name) {
            this.setState({activeItem: null})
        } else {
            this.setState({activeItem: name})
        }
    }

    handleChange = (e, {name, value}) => {
        if (name.startsWith("enrollment")) {
            this.setState({enrollmentError: null})
        } else if (name.startsWith("demographic")) {
            this.setState({demographicError: null})
        }
        if (value === "") {
            value = null
        }
        this.setState({[name]: value})
    }

    validateDate = (value) => {
        const validFormats = ['MM/DD/YYYY', 'M/D/YYYY', 'MM-DD-YYYY', 'M-D-YYYY']
        return moment(value, validFormats, true).isValid()
    }

    downloadEnrollmentReport = async () => {
        const {
            enrollmentDateOfHireFilter, enrollmentDateOfHire, enrollmentCoverageDateFilter,
            enrollmentCoverageDateFrom, enrollmentCoverageDateTo
        } = this.state

        let errors = []
        if (enrollmentDateOfHireFilter || enrollmentDateOfHire) {
            if (!enrollmentDateOfHireFilter) {
                errors.push("Missing date of hire filter")
            }
            if (!enrollmentDateOfHire) {
                errors.push("Missing date of hire")
            } else if (!this.validateDate(enrollmentDateOfHire)) {
                errors.push("Invalid date of hire format")
            }
        }

        if (enrollmentCoverageDateFilter || enrollmentCoverageDateFrom || enrollmentCoverageDateTo) {
            if (!enrollmentCoverageDateFilter) {
                errors.push("Missing coverage date filter")
            }
            if (!enrollmentCoverageDateFrom) {
                errors.push("Missing coverage date from")
            } else if (!this.validateDate(enrollmentCoverageDateFrom)) {
                errors.push("Invalid coverage date from format")
            }
            if (!enrollmentCoverageDateTo) {
                errors.push("Missing coverage date to")
            } else if (!this.validateDate(enrollmentCoverageDateTo)) {
                errors.push("Invalid coverage date to format")
            }
        }

        if (errors.length > 0) {
            const enrollmentError = <React.Fragment>
                <Message.Header>Please fix the following errors:</Message.Header>
                <Message.List items={errors} />
            </React.Fragment>
            this.setState({enrollmentError: enrollmentError})
            return
        }

        this.setState({enrollmentLoading: true, enrollmentError: null})
        try {
            const payload = {
                dateOfHireFilter: enrollmentDateOfHireFilter,
                dateOfHire: this.generateOptionalDate(enrollmentDateOfHire),
                coverageDateFilter: enrollmentCoverageDateFilter,
                coverageDateFrom: this.generateOptionalDate(enrollmentCoverageDateFrom),
                coverageDateTo: this.generateOptionalDate(enrollmentCoverageDateTo),
                pageSize: MAX_RECORDS,
            }
            const result = await axios.post("/api/employer/v2/generateEnrollmentReport", payload, {headers: {'Content-Type': 'application/json'}});
            const data = result.data
            const headers = data.headers.join(', ') + LINE_FINE
            const records = data.records ? data.records.map(r => r.join(', ')).join(LINE_FINE) : '';
            const csv = headers + records
            if (data.queryMore) {
                this.setState({
                    dataCsv: csv,
                    payloadReport: {
                        dateOfHireFilter: payload.dateOfHireFilter,
                        dateOfHire: payload.dateOfHire,
                        coverageDateFilter: payload.coverageDateFilter,
                        coverageDateFrom: payload.coverageDateFrom,
                        coverageDateTo: payload.coverageDateTo,
                        pageSize: payload.pageSize,
                        queryMore: data.queryMore,
                    }
                })
                await this.reCallGenerateEnrollmentReport()
            } else {
                this.downloadCsv("enrollmentReport.csv", csv)
                this.setState({enrollmentLoading: false})
            }
        } catch (e) {
            console.warn(e)
            this.setState({enrollmentLoading: false, enrollmentError: "Unable to generate the requested report"})
        }
    }

    async reCallGenerateEnrollmentReport() {
        clearTimeout(this.timer);
        try {
            const {data} = await axios.post(
              "/api/employer/v2/generateEnrollmentReport",
              this.state.payloadReport,
              {headers: {'Content-Type': 'application/json'}}
            );
            const records = data.records ? data.records.map(r => r.join(', ')).join(LINE_FINE) : LINE_FINE;
            this.setState({dataCsv: this.state.dataCsv + LINE_FINE + records})
            if (!data.queryMore) {
                this.downloadCsv("enrollmentReport.csv", this.state.dataCsv)
                this.setState({enrollmentLoading: false})
                return
            }
            this.setState({
                payloadReport: {
                    ...this.state.payloadReport,
                    queryMore: data.queryMore
                }
            })
            this.timer = setTimeout(
              () => this.reCallGenerateEnrollmentReport().then(),
              100
            );
        } catch (e) {
            console.warn(e)
            this.setState({enrollmentLoading: false, enrollmentError: "Unable to generate the requested report"})
        }
    }

    generateOptionalDate = (value) => {
        if (!value) {
            return null
        }

        const validFormats = ['MM/DD/YYYY', 'M/D/YYYY', 'MM-DD-YYYY', 'M-D-YYYY']
        const date = moment(value, validFormats, true)
        if (!date.isValid()){
            return null
        }

        return date.format("YYYY-MM-DD")
    }

    clearEnrollmentFilters = () => {
        this.setState({
            enrollmentError: null,
            enrollmentDateOfHireFilter: null,
            enrollmentDateOfHire: null,
            enrollmentCoverageDateFilter: null,
            enrollmentCoverageDateFrom: null,
            enrollmentCoverageDateTo: null,
        })
    }

    downloadDemographicReport = async () => {
        const {demographicDateOfHireFilter, demographicDateOfHire, demographicTerminationDateFrom,
            demographicTerminationDateTo, demographicEmploymentStatus, demographicRole
        } = this.state

        let errors = []
        if (demographicDateOfHireFilter || demographicDateOfHire) {
            if (!demographicDateOfHireFilter) {
                errors.push("Missing date of hire filter")
            }
            if (!demographicDateOfHire) {
                errors.push("Missing date of hire")
            } else if (!this.validateDate(demographicDateOfHire)) {
                errors.push("Invalid date of hire format")
            }
        }

        if (demographicTerminationDateFrom || demographicTerminationDateTo) {
            if (!demographicTerminationDateFrom) {
                errors.push("Missing termination date from")
            } else if (!this.validateDate(demographicTerminationDateFrom)) {
                errors.push("Invalid termination date from format")
            }
            if (!demographicTerminationDateTo) {
                errors.push("Missing termination date to")
            } else if (!this.validateDate(demographicTerminationDateTo)) {
                errors.push("Invalid termination date to format")
            }
        }

        if (errors.length > 0) {
            const enrollmentError = <React.Fragment>
                <Message.Header>Please fix the following errors:</Message.Header>
                <Message.List items={errors} />
            </React.Fragment>
            this.setState({demographicError: enrollmentError})
            return
        }


        this.setState({demographicLoading: true, demographicError: null})
        try {
            const payload = {
                dateOfHireFilter: demographicDateOfHireFilter,
                dateOfHire: this.generateOptionalDate(demographicDateOfHire),
                terminationDateFrom: this.generateOptionalDate(demographicTerminationDateFrom),
                terminationDateTo: this.generateOptionalDate(demographicTerminationDateTo),
                employmentStatus: demographicEmploymentStatus,
                role: demographicRole,
                pageSize: MAX_RECORDS,
            }
            const result = await axios.post("/api/employer/v2/generateDemographicReport", payload, {headers: {'Content-Type': 'application/json'}});
            const data = result.data
            const headers = data.headers.join(', ') + LINE_FINE
            const records = data.records ? data.records.map(r => r.join(', ')).join(LINE_FINE) : '';
            const queryMoreNext = data.queryMore
            const csv = headers + records
            if (queryMoreNext) {
                this.setState({
                    dataCsv: csv,
                    payloadReport: {
                        dateOfHireFilter: payload.dateOfHireFilter,
                        dateOfHire: payload.dateOfHire,
                        terminationDateFrom: payload.terminationDateFrom,
                        terminationDateTo: payload.terminationDateTo,
                        employmentStatus: payload.employmentStatus,
                        role: payload.role,
                        pageSize: payload.pageSize,
                        queryMore: queryMoreNext,
                    }
                })
                await this.reCallDemographicReport()
            } else {
                this.downloadCsv("demographicReport.csv", csv)
                this.setState({demographicLoading: false})
            }
        } catch (e) {
            console.warn(e)
            this.setState({demographicLoading: false, demographicError: "Unable to generate the requested report"})
        }
    }

    async reCallDemographicReport() {
        clearTimeout(this.timer);
        try {
            const {data} = await axios.post(
              "/api/employer/v2/generateDemographicReport",
              this.state.payloadReport,
              {headers: {'Content-Type': 'application/json'}}
            );
            const queryMoreNext = data.queryMore

            if (data.records) {
                const records = data.records.map(r => r.join(', ')).join(LINE_FINE) || LINE_FINE;
                this.setState({dataCsv: this.state.dataCsv + LINE_FINE + records})
            }

            if (!queryMoreNext) {
                this.downloadCsv("demographicReport.csv", this.state.dataCsv)
                this.setState({demographicLoading: false})
                return
            }

            this.setState({
                payloadReport: {
                    ...this.state.payloadReport,
                    queryMore: queryMoreNext
                }
            })
            this.timer = setTimeout(
              () => this.reCallDemographicReport().then(),
              100
            );
        } catch (e) {
            console.warn(e)
            this.setState({demographicLoading: false, demographicError: "Unable to generate the requested report"})
        }
    }

    clearDemographicFilters = () => {
        this.setState({
            demographicError: null,
            demographicDateOfHireFilter: null,
            demographicDateOfHire: null,
            demographicTerminationDateFrom: null,
            demographicTerminationDateTo: null,
            demographicEmploymentStatus: null,
            demographicRole: null
        })
    }

    downloadCsv = (fileName, content) => {
        const data = new Blob([content], {type: 'text/csv'});
        const csvURL = window.URL.createObjectURL(data);
        const element = document.createElement("a")
        element.href = csvURL
        element.download = fileName
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
    }

    switchToOverview = () => {
        this.props.history.push('/portal/billing')
    }

    render() {
        const {
            activeItem, enrollmentError, enrollmentLoading, enrollmentDateOfHireFilter, enrollmentDateOfHire, enrollmentCoverageDateFilter,
            enrollmentCoverageDateFrom, enrollmentCoverageDateTo, demographicError, demographicLoading,
            demographicDateOfHireFilter, demographicDateOfHire, demographicTerminationDateFrom, demographicTerminationDateTo,
            demographicEmploymentStatus, demographicRole
        } = this.state;
        const dateOfHireFilterOptions = [
            {key: "On or after date", text: "On or after date", value: "On or after date"},
            {key: "On or before date", text: "On or before date", value: "On or before date"},
            {key: "Equals to date", text: "Equals to date", value: "Equals to date"},
        ]
        const coverageOptions = [
            {key: "Effective date", text: "Effective date", value: "Effective date"},
            {key: "Termination date", text: "Termination date", value: "Termination date"},
            {key: "Active coverage dates", text: "Active coverage dates", value: "Active coverage dates"},
        ]
        const employmentStatusOptions = [
            {key: "Active", text: "Active", value: "Active"},
            {key: "Inactive", text: "Inactive", value: "Inactive"},
        ]


        return (
            <PortalContainer>
                <BillingAlert switchToOverview={this.switchToOverview}/>
                <Grid container stackable columns={1} centered style={{marginTop: 0}}>
                    <Grid.Column className={"pageContent"}>
                        <CustomDivider hidden />
                        <Segment padded loading={enrollmentLoading}>
                            <Header as="h4">
                                <Icon link name={activeItem === reportType.enrollment ? 'angle up' : 'angle down'}
                                      onClick={() => this.handleItemClick(reportType.enrollment)}/>
                                Enrollment report</Header>
                            <p><span className={"small neutral700Text"}> Shows employee enrollments, coverage dates, benefit choices, and cost details. </span>
                            </p>
                            {activeItem === reportType.enrollment &&
                            <React.Fragment>
                                <p className="neutral700Text"><strong>Filters</strong></p>
                                <Form>
                                    <p className={"small neutral700Text"}><strong>Date of hire</strong></p>
                                    <Form.Group>
                                        <Form.Select options={dateOfHireFilterOptions}
                                                     name="enrollmentDateOfHireFilter"
                                                     value={enrollmentDateOfHireFilter}
                                                     clearable
                                                     onChange={this.handleChange}/>
                                        <DateSelector
                                                name="enrollmentDateOfHire"
                                                value={enrollmentDateOfHire || ''}
                                                onChange={(e, {value}) => {
                                                    this.handleChange(e, {name: "enrollmentDateOfHire", value: value});
                                                }}
                                            />
                                        <Form.Field/>
                                    </Form.Group>
                                    <p className={"small neutral700Text"}><strong>Coverage date</strong></p>
                                    <Form.Group>
                                        <Form.Select options={coverageOptions} label={<label className={"smaller neutral600Text"}>&nbsp;</label>}
                                                     name="enrollmentCoverageDateFilter"
                                                     value={enrollmentCoverageDateFilter}
                                                     clearable
                                                     onChange={this.handleChange}/>
                                            <DateSelector
                                                label={"From"}
                                                name="enrollmentCoverageDateFrom"
                                                value={enrollmentCoverageDateFrom || ''}
                                                onChange={(e, {value}) => {
                                                    this.handleChange(e, {name: "enrollmentCoverageDateFrom", value: value});
                                                }}
                                            />
                                            <DateSelector
                                                label={"To"}
                                                name="enrollmentCoverageDateTo"
                                                value={enrollmentCoverageDateTo || ''}
                                                onChange={(e, {value}) => {
                                                    this.handleChange(e, {name: "enrollmentCoverageDateTo", value: value});
                                                }}
                                            />
                                    </Form.Group>
                                    <Grid columns={2}>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <ClickableText onClick={this.clearEnrollmentFilters}>Clear filters</ClickableText>
                                            </Grid.Column>
                                            <Grid.Column textAlign="right">
                                                <Button basic primary onClick={this.downloadEnrollmentReport}>Download
                                                    report <Icon name="download"/></Button>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Form>
                                {enrollmentError && <Message negative size="mini" className="smaller neutral700Text">{enrollmentError}</Message>}
                            </React.Fragment>
                            }

                        </Segment>
                        <Segment padded loading={demographicLoading}>
                            <Header as="h4">
                                <Icon link name={activeItem === reportType.demographic ? 'angle up' : 'angle down'}
                                      onClick={() => this.handleItemClick(reportType.demographic)}/>
                                Demographic report</Header>
                            <p><span className={"small neutral700Text"}>Shows demographic and employment details for employees and their dependents.</span>
                            </p>
                            {activeItem === reportType.demographic &&
                            <React.Fragment>
                                <p className="neutral700Text"><strong>Filters</strong></p>
                                <Form>
                                    <p className={"small neutral700Text"}><strong>Date of hire</strong></p>
                                    <Form.Group widths='equal'>
                                        <Form.Select options={dateOfHireFilterOptions}
                                                     name="demographicDateOfHireFilter"
                                                     value={demographicDateOfHireFilter}
                                                     clearable
                                                     onChange={this.handleChange}/>
                                            <DateSelector
                                                name="demographicDateOfHire"
                                                value={demographicDateOfHire || ''}
                                                onChange={(e, {value}) => {
                                                    this.handleChange(e, {name: "demographicDateOfHire", value: value});
                                                }}
                                            />
                                        <Form.Field/>
                                        <Form.Field/>
                                    </Form.Group>
                                    <p className={"small neutral700Text"}><strong>Termination date</strong></p>
                                    <Form.Group widths='equal'>
                                            <DateSelector
                                                label={"From"}
                                                name="demographicTerminationDateFrom"
                                                value={demographicTerminationDateFrom || ''}
                                                onChange={(e, {value}) => {
                                                    this.handleChange(e, {name: "demographicTerminationDateFrom", value: value});
                                                }}
                                            />
                                            <DateSelector
                                                label={"To"}
                                                name="demographicTerminationDateTo"
                                                value={demographicTerminationDateTo || ''}
                                                onChange={(e, {value}) => {
                                                    this.handleChange(e, {name: "demographicTerminationDateTo", value: value});
                                                }}
                                            />
                                        <Form.Field/>
                                        <Form.Field/>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Select options={employmentStatusOptions} label={<label className={"small neutral700Text"}>Employment status</label>}
                                                     name="demographicEmploymentStatus" clearable
                                                     value={demographicEmploymentStatus}
                                                     onChange={this.handleChange}/>
                                        <Form.Input name="demographicRole" label={<label className={"small neutral700Text"}>Role</label>} value={demographicRole || ''}
                                                    onChange={this.handleChange}/>
                                        <Form.Field/>
                                        <Form.Field/>
                                    </Form.Group>
                                    <Grid columns={2}>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <ClickableText onClick={this.clearDemographicFilters}>Clear filters</ClickableText>
                                            </Grid.Column>
                                            <Grid.Column textAlign="right">
                                                <Button basic primary onClick={this.downloadDemographicReport}>Download
                                                    report <Icon name="download"/></Button>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Form>
                                {demographicError && <Message negative size="mini" className="smaller neutral700Text">{demographicError}</Message>}
                            </React.Fragment>
                            }
                        </Segment>
                    </Grid.Column>
                </Grid>
            </PortalContainer>
        )
    }
}

export default withRouter(Reports);
