import React, {Component} from 'react';
import {Label} from "semantic-ui-react";

class CustomStep extends Component {
    render() {
        const {stepNumber, completed, disabled, lastStep} = this.props;
        return (
            <div className='step'>
                <Label style={{marginTop: '8px', fontSize: '5px', marginRight: '0'}} as='span'
                       color={disabled ? 'grey' : 'green'}
                       circular emtpy={'semantic ui expects a string here, not boolean true'}/>

                {stepNumber !== lastStep ?
                    <div className='lineContainer' style={{paddingTop: '5px'}}>
                        <div className={completed ? 'colorLine' : 'greyLine'}/>
                    </div> : null
                }
            </div>
        )
    }
}

export default CustomStep;