import {contributionTypes, familyTier} from "./strings";

export default class RatedClassUtil {
    constructor(ratedClass) {
        this.ratedClass = ratedClass
    }

    hasEmployerContribution() {
        return this.ratedClass && this.ratedClass.contributionType !== null
    }

    contributionType() {
        return this.ratedClass?.contributionType || null
    }

    isVariableContribution() {
        switch(this.ratedClass?.contributionType) {
            case contributionTypes.familyTierContribution:
            case contributionTypes.familyTierAgeBandContribtion:
            case contributionTypes.ageBandContribution:
                return true
            default:
                return false
        }
    }

    isFlatContribution() {
        return this.ratedClass?.contributionType === contributionTypes.flatContribution
    }

    isAgeBandContribution() {
        return this.ratedClass?.contributionType === contributionTypes.ageBandContribution
    }

    isFamilyTierContribution() {
        return this.ratedClass?.contributionType === contributionTypes.familyTierContribution
    }

    isFamilyTierAgeBandContribution() {
        return this.ratedClass?.contributionType === contributionTypes.familyTierAgeBandContribtion
    }

    flatContribution() {
        if (!this.isFlatContribution()){
            return null
        }
        return this.ratedClass.employerContributions[0].amount
    }

    employeeOnlyContribution() {
        return this._findFamilyTierContribution(familyTier.employeeOnly)
    }

    employeeSpouseContribution() {
        return this._findFamilyTierContribution(familyTier.employeeSp)
    }

    employeeChildrenContribution() {
        return this._findFamilyTierContribution(familyTier.employeeCh)
    }

    familyContribution() {
        return this._findFamilyTierContribution(familyTier.family)
    }

    _findFamilyTierContribution(type) {
        if (!this.isFamilyTierContribution()) {
            return null
        }
        for (const contrib of this.ratedClass.employerContributions) {
            if (contrib.familyTier === type) {
                return contrib.amount
            }
        }
        return null
    }

    findAgeBandContribution(band) {
        if (!this.isAgeBandContribution() || band === null) {
            return null
        }
        for (const contrib of this.ratedClass.employerContributions) {
            if (contrib.ageBand === band) {
                return contrib.amount
            }
        }
        return null
    }

    findFamilyTierAgeBandContribution(tier, band) {
        if (!this.isFamilyTierAgeBandContribution() || band === null) {
            return null
        }
        for (const contrib of this.ratedClass.employerContributions) {
            if (contrib.familyTier === tier && contrib.ageBand === band) {
                return contrib.amount
            }
        }
        return null
    }
}
