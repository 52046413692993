import React from 'react';

import STRATEGIC_PARTNER from "../../../constants/strategicPartners";

const MailToSupport = ({ label = STRATEGIC_PARTNER.CONTACT_SUPPORT.EMAIL }) =>
    <a href={`mailto:${STRATEGIC_PARTNER.CONTACT_SUPPORT.EMAIL}`} target="_blank" rel="noopener noreferrer">
        {label}
    </a>;

export default MailToSupport;
