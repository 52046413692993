import React, {useState} from 'react';
import {Table, Header, Segment, Container, Grid} from 'semantic-ui-react';
import {ViewMoreButton} from "../../base";
import {CustomDivider} from '../../custom-common';

const BrokersTable = ({data}) => {
  const [maxDisplayCount, setMaxDisplayCount] = useState(10)
  const [filteredData] = useState(data);

  const onViewMore = () => setMaxDisplayCount(maxDisplayCount + 10)
  const noBrokersToDisplay = () => !!filteredData && filteredData.length === 0
  const showViewMore = () => !noBrokersToDisplay() && (maxDisplayCount < filteredData.length)

  const TableHeader = () => {
    return (
      <>
        <div className={"employer-headerContainer employer-account-flexHeaderContainer"} style={{height: 40}}>
          <Header className={"adminsTableHeader colorfulText"}
                  as='h4'
          >Broker access
          </Header>
        </div>
      </>
    )
  }

  return (
    <>
      <TableHeader />
      <Table sortable basic='very' className={"adminsTable"}>
        {
          noBrokersToDisplay()
            ? (
              <>
                <Container fluid>
                  <Segment className="broker-segment">
                    <Grid columns={1}>
                      <Grid.Column textAlign="center">
                        <CustomDivider hidden large/>
                        <Header as='h3' className="neutral700Text">
                          Your Broker does not have access
                        </Header>
                        <span className="neutral700Text">Your broker has not been granted access.<br/>Please reach out to our team to learn more.</span>
                      </Grid.Column>
                    </Grid>
                  </Segment>
                </Container>
              </>
            )
            : (
              filteredData.slice(0, maxDisplayCount).map((item, index) => {
                const {firstName, lastName, email, accountName} = item;
                return (
                  <Table.Row key={index}>
                    <Table.Cell><b>{firstName}</b></Table.Cell>
                    <Table.Cell><b>{lastName}</b></Table.Cell>
                    <Table.Cell>{email}</Table.Cell>
                    <Table.Cell>{accountName}</Table.Cell>
                  </Table.Row>
                )
              })
            )
        }
      </Table>
      {showViewMore() &&
        <ViewMoreButton className="linkText" onViewMore={onViewMore} />
      }
    </>
  )
}

export default BrokersTable
