import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import {Container, Grid, Loader, Menu, Message, Segment,} from "semantic-ui-react";
import {connect} from "react-redux";
import PortalContainer from "../portalContainer";
import axios from "axios";
import EditAccountModal from "./editAccountModal";
import Concierge from "../../concierge";
import InviteAdminModal from "./inviteAdminModal";
import RemoveUserAccessModal from "./removeUserAccessModal";
import {setUsername} from "../../../actions/authActions";
import BillingAlert from "../billing/BillingAlert";
import {formatDate} from "../../../utils/common";
import {setLeftNavigation} from "../../../actions/navigationActions";
import {
    getMfaSettings,
    generateBackupCodes
  } from "../../../actions/authActions";
import Mfa from '../../../mfa';
import MyInfo from "./myInfo";
import BrokersTable from "./brokersTable";
import OtherAdminsTable from "./otherAdminsTable";
import {CustomDivider} from '../../custom-common';
import {show as showToast} from "../../../actions/toastActions";

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        contract: state.portal.contract,
        mfaEnabled: state.auth.mfaEnabled
    }
};

class Account extends Component {
    settingsTable = 'setings'
    accessTable = 'access'
    adminsTable ='admins'
    brokersTable = 'brokers'

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: null,
            data: null,
            showMyInfoEditModal: false,
            showInviteAdminModal: false,
            showConsiergeModal: false,
            otherAdminLoading: false,
            otherAdminError: null,
            successMessage: null,
            userToRemove: null,
            selectedTable: this.settingsTable,
            selectedAccessTable: this.adminsTable,
        }
    }

    async componentDidMount() {
        this.setState({loading: true, error: null})
        try {
            const {contract} = this.props
            const payload = {employerId: contract.employer.id}
            const result = await axios.post("/api/employer/v2/prepareAccount", payload, {headers: {'Content-Type': 'application/json'}})
            this.setState({loading: false, data: result.data})
        } catch (e) {
            console.warn(e)
            this.setState({loading: false, error: "Unable to get account information"})
        }
    }

    onEditAccountSuccess = (firstName, lastName) => {
        const {data} = this.state
        data.accountInfo.firstName = firstName
        data.accountInfo.lastName = lastName
        this.setState({data: data, showMyInfoEditModal: false})
        this.props.dispatch(setUsername(firstName))
    }

    onCancelEditAccount = () => {
        this.setState({showMyInfoEditModal: false})
    }

    showConciergeModal = (value) => {
        this.setState({showConsiergeModal: value})
    }

    showInviteAdminModal = () => {
        this.setState({showInviteAdminModal: true})
    }

    onInviteAdminSuccess = () => {
        this.setState({showInviteAdminModal: false})
        this.setState({
            showInviteAdminModal: false,
        })
        this.showToastSuccess({
            title: 'Success!',
            message: 'Invite email sent'
        });
    }

    onCancelInviteAdmin = () => {
        this.setState({showInviteAdminModal: false})
    }

    onRemoveUserSuccess = () => {
        let {data, userToRemove} = this.state
        data.otherAdmins = data.otherAdmins.filter(item => item.id !== userToRemove.id)
        this.setState({
            data,
            showRemoveUserModal: false,
        })
        this.showToastSuccess({
            title: "Access removed",
            message: "The user's access has been successfully removed.",
        });
    }

    onCancelRemoveUser = () => {
        this.setState({showRemoveUserModal: false})
    }

    removeUser = async (userToRemove) => {
        this.setState({showRemoveUserModal: true, userToRemove})
    }

    switchToOverview = () => {
        this.props.history.push('/portal/billing')
    }

    setSelectedTable = (value) => {
        this.setState({selectedTable: value})
    }

    setSelectedAccessTable = (value) => {
        this.setState({selectedAccessTable: value})
    }

    isAdminsTableSelected =  () => this.state.selectedAccessTable === this.adminsTable
    isBrokersTableSelected =  () => this.state.selectedAccessTable === this.brokersTable
    isSettingsTableSelected = () => this.state.selectedTable === this.settingsTable

    showToastSuccess = (details) => {
        this.props.dispatch(showToast({type: 'success', ...details}));
    }

    render() {
        const {contract, mfaEnabled} = this.props;
        const {
            loading,
            error,
            data,
            showMyInfoEditModal,
            showConsiergeModal,
            showInviteAdminModal,
            showRemoveUserModal,
            userToRemove,
        } = this.state;

        const {otherAdmins, producerAdmins, accountInfo} = data || {}

        return (
            <PortalContainer showLeftNavigation={true}>
                <BillingAlert switchToOverview={this.switchToOverview}/>
                <Grid container stackable columns={1} centered style={{marginTop: 0}}>
                    <Grid.Column className={"pageContent"}>
                        <CustomDivider hidden/>
                        <Container>
                            {loading && <Loader active/>}
                            {error && <Message negative style={{marginTop: 56}}>{error}</Message>}
                            {data && <>
                                <Container>
                                    <Menu text size={"small"} className={"internalMenu removePaddingSides"}>
                                        <Menu.Item name='settings'
                                                   active={this.isSettingsTableSelected()}
                                                   onClick={() => {this.setSelectedTable(this.settingsTable)}}>Settings
                                        </Menu.Item>
                                        <Menu.Item name='access'
                                                   active={!this.isSettingsTableSelected()}
                                                   onClick={() => {this.setSelectedTable(this.accessTable)}}>Access
                                        </Menu.Item>
                                    </Menu>
                                    <Segment className={"employerAdminsTableContainer"}>
                                        {this.isSettingsTableSelected() ?
                                          <MyInfo data={accountInfo}></MyInfo> :
                                          <Container style={{width: '890px'}}>
                                              <Menu text size={"small"} className={"internalMenu removePaddingSides"}>
                                                  <Menu.Item name='administrators'
                                                             active={this.isAdminsTableSelected()}
                                                             onClick={() => {this.setSelectedAccessTable(this.adminsTable)}}>Administrators
                                                  </Menu.Item>
                                                  <Menu.Item name='brokers'
                                                             active={this.isBrokersTableSelected()}
                                                             onClick={() => {this.setSelectedAccessTable(this.brokersTable)}}> Brokers
                                                  </Menu.Item>
                                              </Menu>
                                              {this.isAdminsTableSelected() ?
                                                <OtherAdminsTable data={otherAdmins}
                                                                  removeUser={this.removeUser}
                                                                  addNewAdmin={this.showInviteAdminModal}
                                                /> : <BrokersTable data={producerAdmins}/>
                                              }
                                          </Container>
                                        }
                                    </Segment>
                                </Container>
                            </>}
                            <Container className={"accountMfaContainer"}>
                                {!loading && this.isSettingsTableSelected() && (
                                    <Mfa mfaShowSettings={true} mfaEnabled={mfaEnabled} setLeftNavigation={setLeftNavigation} getMfaSettings={getMfaSettings} formatDate={formatDate} generateBackupCodes={generateBackupCodes}/>
                                )}
                            </Container>
                        </Container>
                        <Concierge open={showConsiergeModal} onManage={this.showConciergeModal} hasDedicatedConcierge/>
                        {showMyInfoEditModal &&
                          <EditAccountModal
                            firstName={accountInfo.firstName}
                            lastName={accountInfo.lastName}
                            email={accountInfo.email}
                            contactId={accountInfo.id}
                            employerId={contract.employer.id}
                            open={showMyInfoEditModal}
                            onCancel={this.onCancelEditAccount}
                            onSave={this.onEditAccountSuccess}
                          />
                        }
                        {showInviteAdminModal &&
                          <InviteAdminModal
                            open={showInviteAdminModal}
                            employerName={contract.employer.name}
                            onCancel={this.onCancelInviteAdmin}
                            onSave={this.onInviteAdminSuccess}
                            employerId={contract.employer.id}
                          />
                        }
                        {showRemoveUserModal &&
                          <RemoveUserAccessModal
                            open={showRemoveUserModal}
                            user={userToRemove}
                            onCancel={this.onCancelRemoveUser}
                            onRemove={this.onRemoveUserSuccess}
                            employerId={contract.employer.id}
                          />
                        }
                    </Grid.Column>
                </Grid>
            </PortalContainer>
        )
    }
}

export default connect(mapStateToProps)(withRouter(Account));
