import { palette } from '../../../design-system'
import STRATEGIC_PARTNER from '../../../constants/strategicPartners'
import {buildS3ImageSrc} from "../../custom-common/s3Image";
import IMAGE from "../../../constants/images";

export const theme = {
  brand: {
    logo: buildS3ImageSrc(IMAGE.OFFICIAL_LOGO),
    invertedLogo: buildS3ImageSrc(IMAGE.OFFICIAL_LOGO_WHITE),
    width: '258px',
    alt: STRATEGIC_PARTNER.LABEL,
  },
  panel: {
    foreground: '#fff',
    background: '#91391a',
    backgroundImage: buildS3ImageSrc(IMAGE.LOGIN_BACKGROUND),
  },
  slogan: {
    color: palette.voyaDarkOrange,
    line: {
      desktop: '#fff',
      mobile: '#00f',
    },
    fontFamily: 'EuclidCircularA-Bold',
  },
  content: {
    color: '#ccc',
  },
  title: {
    color: palette.voyaDarkOrange,
  },
  links: {
    idle: palette.voyaDarkBlue,
    hover: palette.deepTeal,
  },
  footer: {
    links: {
      idle: palette.neutral500,
      hover: palette.neutral700,
    }
  }
}
