import React, {Component} from 'react';
import {connect} from "react-redux";
import {Container, Grid} from "semantic-ui-react";
import {withRouter} from "react-router-dom";
import AccountCreationHeader from "./accountCreationHeader";
import {logout} from "../../actions/authActions";
import Footer from "../footer";
import {StrategicPartnerLabel} from "../common";
import {Header} from '../base';
import IMAGE from "../../constants/images";
import {ClickableText, CustomDivider, S3Image} from '../custom-common';

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
});

class ContractSent extends Component {
    constructor(props) {
        super(props);
    }

    logOut = () => {
        const {dispatch} = this.props;
        dispatch(logout())
        this.props.history.push({pathname: "/login"});
    }

    render() {
        return (
            <div className="employer-main-container">
                <AccountCreationHeader history={this.props.history}/>
                <Container fluid className="employer-accountCreation-container">
                    <Grid columns={1}>
                        <Grid.Column textAlign="center">
                            <S3Image {...IMAGE.ENVELOPE_SIGNATURE} />
                            <Header as='h2' className={"colorfulText"}>
                              Out for signature
                            </Header>
                        </Grid.Column>
                    </Grid>
                    <CustomDivider hidden/>
                    <Grid container stackable centered>
                        <Grid.Row>
                            <Grid.Column width={7}>
                              <Header as='h4' className={"colorfulText"}>Next steps</Header>
                              <p>
                                Once your group application is signed, <StrategicPartnerLabel/> will reach out to you to finish your account set up and configure your open enrollment.
                              </p>
                              <p>
                                Enrollment communication and employee education are super important and we're here to help. We have a variety of resources to ensure a successful enrollment for your employees.
                              </p>
                              <CustomDivider hidden/>
                              <Header as='h4' className={"colorfulText"}>All done!</Header>
                              <p>
                                Feel free to close your browser tab or <ClickableText onClick={this.logOut}>log out</ClickableText>.
                              </p>
                            </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    <CustomDivider hidden/>
                </Container>
                <Footer hasToDisplayCarrierDisclaimer/>
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps)(ContractSent));
