import React, {Component} from 'react';
import {Dropdown, Grid, Icon, Menu} from "semantic-ui-react";
import {connect} from "react-redux";
import {logout} from "../../actions/authActions";
import {withRouter} from "react-router-dom";
import {GetHelpMenu} from "../menu/getHelpMenu";

const mapStateToProps = (state, ownProps) => {
    const {portalModalHeader: {prevRoute}} = state;
    return {
        prevRoute,
        username: state.auth.user.username,
        ...ownProps,
    }
};

class PortalModalHeader extends Component {
    nukeStore(){
        const {dispatch} = this.props;
        dispatch(logout())
        this.props.history.push({pathname: "/login"});
    }

    render() {
        const {username, showRightMenu , modalTitle, prevRoute, done} = this.props;
        return (
            <div className='headerContainer' style={{position: "fixed", top: "0", width: "100%", zIndex: "101"}}>
                <Grid>
                    <Grid.Column width={4} textAlign={'left'}>
                        {prevRoute && <div onClick={() => this.props.history.push(prevRoute)}>
                            <Icon name='arrow left'/>
                        </div>}
                    </Grid.Column>
                    <Grid.Column width={4} textAlign={'center'}>
                        {modalTitle && <b>{modalTitle}</b>}
                    </Grid.Column>
                    <Grid.Column textAlign={'right'} width={4}>
                        {showRightMenu &&
                        <React.Fragment>
                            <GetHelpMenu hasDedicatedConcierge />
                            <span style={{paddingLeft: '10px', paddingRight: '10px', color: '#ddd'}}/>
                            <Dropdown item text={username} direction='left' className="menuUserDropdown">
                                <Dropdown.Menu style={{padding: '0.5em 0'}}>
                                    <Dropdown.Item as={Menu.Item} onClick={() => this.nukeStore()}>
                                        Log out
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </React.Fragment>}
                        {(prevRoute && !!done) && <span className={"linkText"} onClick={() => this.props.history.push(prevRoute)}><b>Done</b></span>}
                    </Grid.Column>
                </Grid>
            </div>
        )
    }
}

export default connect(mapStateToProps)(withRouter(PortalModalHeader));
