import React, { useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Dropdown, Menu } from 'semantic-ui-react'
import { isLoggedIn } from '../../selectors/authSelector'
import Concierge from '../concierge'
import STRATEGIC_PARTNER from '../../constants/strategicPartners'

export const GetHelpMenu = ({ hasDedicatedConcierge, offline = false }) => {
  const [ showModal, setShowModal ] = useState(false);
  const loggedIn = useSelector(isLoggedIn);

  const manageModal = useCallback(state => {
    setShowModal(prevState => state !== undefined ? state : !prevState)
  }, [setShowModal])

  const { EMAIL: email, NUMBER: tel } = STRATEGIC_PARTNER.CONTACT_SUPPORT

  const showMessageOption = !offline && loggedIn

  return (
    <>
      <Dropdown item text="Get help" direction='left' className="getHelpMenu menuUserDropdown">
        <Dropdown.Menu style={{ padding: '0.5em 0' }}>
          {showMessageOption && (<>
            <Dropdown.Item as={Menu.Item} onClick={() => manageModal(true)}>
              Message our team
            </Dropdown.Item>
            <Dropdown.Divider/>
          </>)}
          <Dropdown.Item
            as={'div'}
          >
            For technical support contact<br />
            <a
              href={`mailto: ${email}`}
              target="_blank"
              rel="noopener noreferrer"
              >{email}</a><br />
            or call <a href={`tel: ${tel}`} className="phoneLink">{tel}</a>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {showMessageOption && (
        <Concierge
          open={showModal}
          onManage={manageModal}
          hasDedicatedConcierge={hasDedicatedConcierge}
        />
      )}
    </>
    )
  }
