import React from "react"
import connect from "react-redux/es/connect/connect"
import {Redirect, Route} from "react-router-dom"
import MfaAuth from "../components/mfaAuth"



const mapStateToProps = (state, ownProps) => {
    return {
        authToken: state.auth.user.authToken ? state.auth.user.authToken : null,
        mfaToken: state.auth.user?.mfaToken ? state.auth.user.mfaToken : null,
        ...ownProps
    }
};

const PrivateRoute = ({ component: Component, authToken, mfaToken, ...rest }) => (
    <Route {...rest} render={(props) => (
        authToken
            ? <Component {...props} />
            : mfaToken ? <MfaAuth {...props} />
            : <Redirect to={{
                pathname: '/login',
                state: { from: props.location }
            }} />
    )} />
);

export default connect(mapStateToProps)(PrivateRoute);