import React, {Component} from 'react';
import {Container, Header, Segment} from "semantic-ui-react";

export default class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        console.warn("Error: ", error, "\nInfo: ", info)
        this.setState({ hasError: true });
    }

    render() {
        const {hasError} = this.state;
        if (hasError) {
            return (
                <Segment as={Container} basic='very' style={{marginTop: '2em'}}>
                    <Header as="h2">
                        Whoops something unexpected happened!
                    </Header>
                </Segment>)
        }

        return this.props.children;
    }
}