import styled, { css } from 'styled-components'
import {
  Segment as SCSegment,
  Button as SCButton,
  Form as SCForm,
  Message as SCMessage,
  Input as SCInput
} from 'semantic-ui-react';
import { Link as RRLink } from 'react-router-dom';

const scope = 'LoginForm'

const linkStyles = css`
  color: ${({ theme }) => theme.links.idle};
`

export const Wrapper = styled(SCSegment)`
  &&& {
    padding: 0;
  }
`
Wrapper.displayName = `${scope}/Wrapper`
Wrapper.defaultProps = {
  basic: true,
}

export const Form = styled(SCForm)`
`
Form.displayName = `${scope}/Form`

export const FormField = styled(SCForm.Field)`
`
FormField.displayName = `${scope}/Form.Field`
Form.Field = FormField

export const FormActions = styled.div`
  margin-top: 25px;
`
FormActions.displayName = `${scope}/Form.Actions`
Form.Actions = FormActions

export const Message = styled(SCMessage)`
`
Wrapper.displayName = `${scope}/Message`

export const SubmitButton = styled(SCButton)`
  &&& {
    background: ${({ theme }) => theme.links.idle};
  }
`
SubmitButton.displayName = `${scope}/SubmitButton`
SubmitButton.defaultProps = {
  primary: true,
  fluid: true,
}

export const Input = styled(SCInput)`
`
Input.displayName = `${scope}/Input`

export const InputLabel = styled.label`
`
InputLabel.displayName = `${scope}/Input.Label`
Input.Label = InputLabel


export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  font-size: 12px;
  line-Height: 1.5;
`
Actions.displayName = `${scope}/Actions`

export const Action = styled.div`
  ${({ large }) => large && 'font-size: 14px;'}
`
Action.displayName = `${scope}/Action`

export const Link = styled(RRLink)`
  ${linkStyles}
`
Link.displayName = `${scope}/Link`

export const ExternalLink = styled.a`
  ${linkStyles}
`
ExternalLink.displayName = `${scope}/ExternalLink`
ExternalLink.defaultProps = {
  target: '_blank',
}

export const EmployerPortal = styled.div`
  font-size: 12px;
  line-Height: 1.5;
  margin-top: 60px;
`
EmployerPortal.displayName = `${scope}/EmployerPortal`
