import React, {Component} from 'react'
import {Link, NavLink, withRouter} from "react-router-dom";
import {Form, Icon, Input, Menu, Message, Popup, Segment, Table, Transition, Label} from "semantic-ui-react";
import {connect} from "react-redux";
import PortalContainer from "../portalContainer";
import {buildFullName, formatDate, formatMoney, formatMontlyCost, resolveTier} from "../../../utils/utils";
import moment from "moment";
import Concierge from "../../concierge";
import BillingAlert from "../billing/BillingAlert";
import {throttle} from "throttle-debounce";
import { Header } from '../../base';
import {ClickableText, CustomGrid, CustomDivider} from '../../custom-common';

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        activeContractId: state.portal.activeContractId,
        employees: state.employees.employees,
        activeCount: state.employees.totalActiveCount,
        inactiveCount: state.employees.totalInactiveCount,
        loadingEmployeesFirstPage: state.employees.loadingEmployeesFirstPage,
        employeesError: state.employees.employeesError,
    }
};

class Employees extends Component {
    activeStatusItem = "active"
    inactiveStatusItem = "inactive"
    pageSize = 10

    constructor(props) {
        super(props);
        this.state = {
            sorting: false,
            column: null,
            direction: "ascending",
            filteredData: [],
            activeEesList: [],
            inactiveEesList: [],
            selectedRowIndex: null,
            search: '',
            conciergeOpen: false,
            selectedStatusItem: this.activeStatusItem,
            tierFilter: null,
            coverageStatusFilter: null,
            billingGroupFilter: null,
            eligibilityClassFilter: null,
            maxDisplayCount: this.pageSize,
            loadingActiveEmployees: false,
            loadingInactiveEmployees: false,
            sortingActiveEmployees: false,
            sortingInactiveEmployees: false,
            loadingOnFiltering: false,
            filterObject: null,
        }

        this.billingGroupOptions = []
        this.groupClassOptions = []
        this.tierOptions = []
        this.activeEmployees = []
        this.inactiveEmployees = []
        this.coverageStatusOptions = []
    }

    allActiveEmployeesLoaded = () => {
        const {activeCount} = this.props
        return this.activeEmployees.length >= activeCount
    }

    allInactiveEmployeesLoaded = () => {
        const {inactiveCount} = this.props
        return this.inactiveEmployees.length >= inactiveCount
    }

    async componentDidMount() {
        const {employees} = this.props
        if (!!employees && employees.length > 0) {
            this.setEmployeeData(employees)
        }
    }

    componentDidUpdate(prevProps){
        const {employees} = this.props
        if(prevProps.employees === employees){
            return
        }
        this.setEmployeeData(employees)
    }

    setEmployeeData = (employees) => {
        const {
            column,
            direction,
            selectedStatusItem,
            sortingActiveEmployees,
            sortingInactiveEmployees,
        } = this.state
        const search = this.state.search.toLowerCase()
        this.buildFilterOptions(employees)
        const [activeEmployees, inactiveEmployees] = this.splitActiveInactive(employees)
        this.activeEmployees = activeEmployees
        this.inactiveEmployees = inactiveEmployees
        this.setState({activeEesList: activeEmployees, inactiveEesList: inactiveEmployees})

        if (this.allActiveEmployeesLoaded()) {
            this.setState({loadingActiveEmployees: false, loadingOnFiltering: false})
        }
        if (this.allInactiveEmployeesLoaded()) {
            this.setState({loadingInactiveEmployees: false, loadingOnFiltering: false})
        }
        if (sortingActiveEmployees) {
            this.sort(activeEmployees, column, direction)
            this.setState({sortingActiveEmployees: !this.allActiveEmployeesLoaded()})
        }
        if(sortingInactiveEmployees){
            this.sort(inactiveEmployees, column, direction)
            this.setState({sortingInactiveEmployees: !this.allInactiveEmployeesLoaded()})
        }

        if (search !== "") {
            let activeEesList = this.search(this.activeEmployees, search)
            let inactiveEesList = this.search(this.inactiveEmployees, search)
            this.setState({activeEesList, inactiveEesList})
        }else {
            if(selectedStatusItem === this.activeStatusItem) {
                const filters = this.buildFiltersObject()
                let activeEesList = this.filter(this.activeEmployees, filters)
                this.setState({activeEesList})
            }else {
                const filters = this.buildFiltersObject()
                let inactiveEesList = this.filter(this.inactiveEmployees, filters)
                this.setState({inactiveEesList})
            }
        }
    }

    buildFilterOptions = (data) => {
        let billingGroupOptions = []
        let groupClassOptions = []
        data.forEach(employee => {
            if (employee.billingGroup != null && billingGroupOptions.some(value => value.key === employee.billingGroup) === false) {
                billingGroupOptions.push({key:employee.billingGroup, text: employee.billingGroup, value: employee.billingGroup})
            }
            if (employee.groupClassName != null && groupClassOptions.some(value => value.key === employee.groupClassName) === false) {
                groupClassOptions.push({key:employee.groupClassName, text: employee.groupClassName, value: employee.groupClassName})
            }
        })
        this.billingGroupOptions = billingGroupOptions.sort((a,b) => a.key - b.key)
        this.groupClassOptions = groupClassOptions.sort((a,b) => a.key - b.key)
        this.tierOptions = [
            {key:"employee", text:"Employee only", value:"employee"},
            {key:"spouse", text:"Employee + Spouse", value:"spouse"},
            {key:"child", text:"Employee + Child(ren)", value:"child"},
            {key:"family", text:"Family", value:"family"},
        ]
        this.coverageStatusOptions = [
            {key:"eligibleWithCoverage", text:"Eligible, with coverage", value:"eligibleWithCoverage"},
            {key:"eligibleWithoutCoverage", text:"Eligible, without coverage", value:"eligibleWithoutCoverage"},
            {key:"ineligibleForCoverage", text:"Ineligible for coverage", value:"ineligibleForCoverage"},
        ]
    }

    resolveCoverageStatusOptionText = (value) => {
        const res = this.coverageStatusOptions.find(item => item.value === value)
        return res.text
    }

    splitActiveInactive = (data) => {
        let activeEmployees = []
        let inActiveEmployees = []
        const now = moment()
        data.forEach(employee =>{
            if (employee.terminationDate !== null && moment(employee.terminationDate).isBefore(now)) {
                inActiveEmployees.push(employee)
            } else {
                activeEmployees.push(employee)
            }
        })
        return [activeEmployees, inActiveEmployees]
    }

    onSearchChange =(event, {value}) => {
        if(!this.allActiveEmployeesLoaded()){
            this.setState({loadingActiveEmployees: true})
        }
        if(!this.allInactiveEmployeesLoaded()) {
            this.setState({loadingInactiveEmployees: true})
        }
        if (value === ""){
            this.setState({loadingActiveEmployees: false, loadingInactiveEmployees: false})
        }
        this.setState({search: value})
        this.throttleChange(value)
    }

    throttleChange = throttle(1000, false, (searchValue) => {
        const {selectedStatusItem, column, direction} = this.state
        let active = this.search(this.activeEmployees, searchValue)
        let inactive = this.search(this.inactiveEmployees, searchValue)
        const filters = this.buildFiltersObject()
        if (selectedStatusItem === this.activeStatusItem) {
            active = this.filter(active, filters)
        } else {
            inactive = this.filter(inactive, filters)
        }
        this.sort(active, column, direction)
        this.sort(inactive, column, direction)
        this.setState({activeEesList: active, inactiveEesList: inactive })
    })

    onSort = (column) => {
        const {direction, activeEesList, inactiveEesList, selectedStatusItem} = this.state;
        const newDirection = direction === "ascending" ? "descending" : "ascending"
        this.setState({
            column,
            direction: newDirection,
            selectedRowIndex: null
        })
        if(this.allActiveEmployeesLoaded()){
            this.sort(activeEesList, column, newDirection)
            this.setState({activeEesList})
        }else if(selectedStatusItem === this.activeStatusItem) {
            this.setState({loadingActiveEmployees: true, sortingActiveEmployees: true})
        }
        if(this.allInactiveEmployeesLoaded()) {
            this.sort(inactiveEesList, column, newDirection)
            this.setState({inactiveEesList})
        }else if(selectedStatusItem === this.inactiveStatusItem){
            this.setState({loadingInactiveEmployees: true, sortingInactiveEmployees: true})
        }
    }

    toggleDetail = (index, showDetail) => {
        if (!showDetail) return;

        const {selectedRowIndex} = this.state
        if (index === selectedRowIndex) {
            index = null
        }
        this.setState({selectedRowIndex: index})
    }

    onViewMore = () => {
        const {maxDisplayCount} = this.state
        const {employees} = this.props
        if (employees.length > maxDisplayCount) {
            this.setState({maxDisplayCount: maxDisplayCount + this.pageSize})
        }
    }



    renderEmployeeRow = (employee, index) => {
        const {selectedRowIndex} = this.state
        const {monthlyPremium = 0, employerContribution = 0} = employee.certificate || {}
        const showDetails = selectedRowIndex === index
        const { certificate } = employee
        let start = "--", end = "--", premium = "--";
        if (certificate) {
            start = formatDate(certificate.effectiveDate, "--")
            end = formatDate(certificate.expirationDate)
            premium = formatMoney(certificate.premium)
        }

        let moderate, severe, catastrophic, chronic, mental
        if (showDetails && certificate) {
            moderate = certificate.assetCoverages.moderate
            severe = certificate.assetCoverages.severe
            catastrophic = certificate.assetCoverages.catastrophic
            chronic = certificate.assetCoverages.chronic
            mental = certificate.assetCoverages.mental
        } else if (showDetails) {
            this.toggleDetail(index, showDetails)
        }

        const eeCost = formatMontlyCost(monthlyPremium)
        const erCost = formatMontlyCost(employerContribution)

        return (<React.Fragment key={index}>
            <Table.Row>
                <Table.Cell><NavLink data-qa-button_firstname exact to={{
                    pathname: `/portal/employee/${employee.id}`,
                    state: {name: buildFullName(employee.firstName, employee.middleName, employee.lastName, employee.suffix)}
                }}>{employee.firstName}</NavLink></Table.Cell>
                <Table.Cell><NavLink data-qa-button_lastname exact to={{
                    pathname: `/portal/employee/${employee.id}`,
                    state: {name: buildFullName(employee.firstName, employee.middleName, employee.lastName, employee.suffix)}
                }}>{employee.lastName}</NavLink></Table.Cell>
                <Table.Cell>{start}</Table.Cell>
                <Table.Cell>{end}</Table.Cell>
                <Table.Cell>{premium}</Table.Cell>
                <Table.Cell data-qa-button_expand onClick={() => {
                    this.toggleDetail(index, !!employee.certificate)
                }}>{employee.certificate &&
                    <React.Fragment>
                        <ClickableText className="linkText">Expand</ClickableText>
                        <Icon name={showDetails ? 'caret up' : 'caret down'} link/>
                    </React.Fragment>
                }
                </Table.Cell>
            </Table.Row>
            {showDetails && <Table.Row>
                <Table.Cell colSpan={8}>
                    <Transition.Group animation={"slide down"} duration={100}>
                        <Segment basic>
                            <CustomGrid columns={3} relaxed style={{
                                background: '#f5f6f7',
                                border: '1px solid #E0E0E0'
                            }}>
                                <CustomGrid.Column className={"small"}>
                                    <Table basic={"very"} padded>
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell colSpan={2}>
                                                    <span className={"neutral600Text"}><b>Benefit amounts</b></span>
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell><b>Moderate</b></Table.Cell>
                                                <Table.Cell
                                                    textAlign={"right"}>{formatMoney(moderate)}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell><b>Severe</b></Table.Cell>
                                                <Table.Cell
                                                    textAlign={"right"}>{formatMoney(severe)}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell><b>Catastrophic</b></Table.Cell>
                                                <Table.Cell
                                                    textAlign={"right"}>{formatMoney(catastrophic)}</Table.Cell>
                                            </Table.Row>
                                            {!!mental &&
                                            <Table.Row>
                                                <Table.Cell><b>Mental</b></Table.Cell>
                                                <Table.Cell
                                                    textAlign={"right"}>{formatMoney(mental)}</Table.Cell>
                                            </Table.Row>}
                                            {!!chronic &&
                                            <Table.Row>
                                                <Table.Cell><b>Chronic</b></Table.Cell>
                                                <Table.Cell
                                                    textAlign={"right"}>{formatMoney(chronic)}</Table.Cell>
                                            </Table.Row>}
                                        </Table.Body>
                                    </Table>
                                </CustomGrid.Column>
                                {employee.dependents && employee.dependents.length > 0 &&
                                <CustomGrid.Column className={"small"}>
                                    <Table basic={"very"} padded>
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell colSpan={2}>
                                                    <span
                                                        className={"neutral600Text"}><b>Covered family members</b></span>
                                                </Table.Cell>
                                            </Table.Row>
                                            {employee.dependents.map((dependent) => {
                                                return (<Table.Row key={dependent.id}>
                                                    <Table.Cell>
                                                        <b>{`${buildFullName(dependent.firstName, dependent.middleName, dependent.lastName, dependent.suffix)}, ${moment().diff(dependent.birthDate, 'years')}`}</b><br/>
                                                        <span
                                                            className={"smaller"}>Effective {formatDate(dependent.effectiveDate)}</span>
                                                    </Table.Cell>
                                                    <Table.Cell verticalAlign={"top"}
                                                                textAlign={"right"}>{dependent.relToEmployee}</Table.Cell>
                                                </Table.Row>)
                                            })}
                                        </Table.Body>
                                    </Table>
                                </CustomGrid.Column>}
                                <CustomGrid.Column className={"small"}>
                                    <Table basic={"very"} padded>
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell colSpan={2}>
                                                    <span className={"neutral600Text"}><b>Cost</b></span>
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell><b>Employer cost</b></Table.Cell>
                                                <Table.Cell
                                                    textAlign={"right"}>{erCost}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell><b>Employee cost</b></Table.Cell>
                                                <Table.Cell
                                                    textAlign={"right"}>{eeCost}</Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                </CustomGrid.Column>
                            </CustomGrid>
                        </Segment>
                    </Transition.Group>
                </Table.Cell>
            </Table.Row>}
        </React.Fragment>)
    }

    manageConciergeModal = (value) => {
        this.setState({conciergeOpen: value})
    }

    switchToOverview = () => {
        this.props.history.push('/portal/billing')
    }

    statusItemCheck = (e, { name }) => {
        const {loadingOnFiltering} = this.state
        if(loadingOnFiltering) {
            this.setState({
                loadingActiveEmployees: false,
                loadingInactiveEmployees: false,
            })
        }
        this.clearAllFilters()
        this.setState({ selectedStatusItem: name })
    }

    onFilterChange =  (e, { name, value }) => {
        const {selectedStatusItem} = this.state
        this.setState({[name]: value})
        let filters = this.buildFiltersObject()
        filters[name] = value

        if(this.allActiveEmployeesLoaded()){
            const activeEesList = this.filter(this.activeEmployees, filters)
            this.setState({[name]: value, activeEesList})
        }else if(selectedStatusItem === this.activeStatusItem) {
            this.setState({loadingActiveEmployees: true, loadingOnFiltering: true})
        }
        if(this.allInactiveEmployeesLoaded()) {
            const inactiveEesList = this.filter(this.inactiveEmployees, filters)
            this.setState({[name]: value, inactiveEesList})
        }else if(selectedStatusItem === this.inactiveStatusItem){
            this.setState({loadingInactiveEmployees: true, loadingOnFiltering: true})
        }
    }

    buildFiltersObject = () => {
        const {tierFilter, coverageStatusFilter, billingGroupFilter, eligibilityClassFilter} = this.state
        return {tierFilter, coverageStatusFilter, billingGroupFilter, eligibilityClassFilter}
    }

    clearFilter = (name) => {
        this.onFilterChange(null, {name: name, value: null})
    }

    clearAllFilters = () => {
        const {selectedStatusItem} = this.state
        let filters = {
            tierFilter: null,
            coverageStatusFilter: null,
            billingGroupFilter: null,
            eligibilityClassFilter: null
        }
        if (selectedStatusItem === this.activeStatusItem) {
            const data = this.filter(this.activeEmployees, filters)
            this.setState({
                tierFilter: null,
                coverageStatusFilter: null,
                billingGroupFilter: null,
                eligibilityClassFilter: null,
                activeEesList: data
            })
        } else {
            const data = this.filter(this.inactiveEmployees, filters)
            this.setState({
                tierFilter: null,
                coverageStatusFilter: null,
                billingGroupFilter: null,
                eligibilityClassFilter: null,
                inactiveEesList: data
            })
        }
    }

    search = (data, value) => {
        return data.filter(item => {
            if (value === '') {
                return true
            }
            const searchValue = value.toLowerCase()
            return `${item.firstName} ${item.lastName}`.toLowerCase().includes(searchValue)
        })
    }

    filter = (data, filters) => {
        if (!data || data.length === 0) {
            return
        }
        return data.filter(item => {
            if (filters.tierFilter !== null) {
                if (item.certificate === null || item.certificate.familyTier !== filters.tierFilter){
                    return false
                }
            }
            if (filters.coverageStatusFilter !== null) {
                if (item.coverageStatus !== filters.coverageStatusFilter) {
                    return false
                }
            }
            if (filters.billingGroupFilter !== null) {
                if (item.billingGroup !== filters.billingGroupFilter){
                    return false
                }
            }

            if (filters.eligibilityClassFilter !== null) {
                if (item.groupClassName !== filters.eligibilityClassFilter) {
                    return false
                }
            }

            return true
        })
    }

    sort = (data, column, direction) => {
        if (!data || data.length === 0) {
            return
        }
        data.sort((a, b) => {
            let v1, v2
            switch (column) {
                case "starts":
                    if (a.certificate) {
                        v1 = formatDate(a.certificate.effectiveDate)
                    } else {
                        v1 = ""
                    }
                    if (b.certificate) {
                        v2 = formatDate(b.certificate.effectiveDate)
                    } else {
                        v2 = ""
                    }
                    break
                case "ends":
                    if (a.certificate) {
                        v1 = formatDate(a.certificate.expirationDate)
                    } else {
                        v1 = ""
                    }
                    if (b.certificate) {
                        v2 = formatDate(b.certificate.expirationDate)
                    } else {
                        v2 = ""
                    }
                    break
                default:
                    v1 = a[column]
                    v2 = b[column]
            }
            if (v1 < v2) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (v1 > v2) {
                return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
    }

    render() {
        const {activeCount, inactiveCount, loadingEmployeesFirstPage, employeesError} = this.props
        const {
            column, direction, search, conciergeOpen, selectedStatusItem, tierFilter, coverageStatusFilter,
            billingGroupFilter, eligibilityClassFilter, activeEesList,inactiveEesList, maxDisplayCount,
            loadingActiveEmployees, loadingInactiveEmployees
        } = this.state

        const activeAmount = this.allActiveEmployeesLoaded()? activeEesList?.length || 0: activeCount
        const inactiveAmount = this.allInactiveEmployeesLoaded()? inactiveEesList?.length || 0: inactiveCount

        const data = selectedStatusItem === this.activeStatusItem ? activeEesList : inactiveEesList
        const showViewMore = data?.length > maxDisplayCount

        const showLoading = (selectedStatusItem === this.activeStatusItem && loadingActiveEmployees) || (selectedStatusItem === this.inactiveStatusItem && loadingInactiveEmployees)

        return (
            <PortalContainer showLeftNavigation={true}>
                <BillingAlert switchToOverview={this.switchToOverview}/>
                <CustomGrid container stackable columns={1} centered style={{marginTop: 0}} fitted={'horizontally'}>
                    <CustomGrid.Column>
                        <CustomDivider hidden/>
                        <Segment padded loading={loadingEmployeesFirstPage}>
                            <Header as={"h4"}>Employee roster</Header>
                            <p className={"neutral900Text"}>
                                To update employee information, please refer to your system of record for enrollment
                                information. You can also run a report of your employees on reports section
                                <Link to={"/portal/reports"}> here. </Link>
                            </p>
                            {employeesError && <Message error> {employeesError}</Message>}

                            <Menu pointing secondary className="employeeRoster">
                                <Menu.Item
                                    name='active'
                                    active={selectedStatusItem === this.activeStatusItem}
                                    onClick={this.statusItemCheck}
                                >
                                    Active ({activeAmount})
                                </Menu.Item>
                                <Menu.Item
                                    name='inactive'
                                    active={selectedStatusItem === this.inactiveStatusItem}
                                    onClick={this.statusItemCheck}
                                >
                                    Inactive ({inactiveAmount})
                                </Menu.Item>
                                <Menu.Menu position='right'>
                                    <Menu.Item>
                                        <Input
                                            icon='search'
                                            iconPosition='left'
                                            value={search} name={search} onChange={this.onSearchChange}
                                        />
                                    </Menu.Item>
                                </Menu.Menu>
                            </Menu>
                            <Segment basic loading={showLoading}>
                                <Label.Group circular>
                                    {tierFilter && <Label> Tier: {resolveTier(tierFilter)}
                                        <Icon name='delete' onClick={() => this.clearFilter("tierFilter")}/>
                                    </Label>}
                                    {coverageStatusFilter && <Label> Coverage status: {this.resolveCoverageStatusOptionText(coverageStatusFilter)}
                                        <Icon name='delete' onClick={() => this.clearFilter("coverageStatusFilter")}/>
                                    </Label>}
                                    {billingGroupFilter && <Label> Billing group: {billingGroupFilter}
                                        <Icon name='delete' onClick={() => this.clearFilter("billingGroupFilter")}/>
                                    </Label>}
                                    {eligibilityClassFilter && <Label> Eligibility class: {eligibilityClassFilter}
                                        <Icon name='delete' onClick={() => this.clearFilter("eligibilityClassFilter")}/>
                                    </Label>}
                                    {(tierFilter || coverageStatusFilter || billingGroupFilter || eligibilityClassFilter) &&
                                    <ClickableText onClick={this.clearAllFilters}>Clear filters</ClickableText>}
                                </Label.Group>

                            <Table sortable basic={"very"}>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell style={{minWidth: "135px"}}
                                                          sorted={column === 'firstName' ? direction : null}
                                                          onClick={() => this.onSort('firstName')}
                                        >
                                            First name {column !== 'firstName' && <Icon name="sort"/>}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell
                                            sorted={column === 'lastName' ? direction : null}
                                            onClick={() => this.onSort('lastName')}
                                        >
                                            Last name {column !== 'lastName' && <Icon name="sort"/>}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell
                                            sorted={column === 'starts' ? direction : null}
                                            onClick={() => this.onSort('starts')}
                                        >
                                            Starts {column !== 'starts' && <Icon name="sort"/>}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell
                                            sorted={column === 'ends' ? direction : null}
                                            onClick={() => this.onSort('ends')}
                                        >
                                            Ends {column !== 'ends' && <Icon name="sort"/>}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Premium
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            <Popup data-qa-button_filter
                                                trigger={<span className="filterText">Filter <Icon name="filter"/></span>}
                                                on='click'
                                                pinned
                                                position='bottom right'
                                            >
                                                <Form>
                                                    <Form.Select name="tierFilter" options={this.tierOptions} value={tierFilter} placeholder="Tier" onChange={this.onFilterChange}/>
                                                    <Form.Select name="coverageStatusFilter" options={this.coverageStatusOptions} value={coverageStatusFilter} placeholder="Coverage status" onChange={this.onFilterChange}/>
                                                    {this.billingGroupOptions.length > 0 && <Form.Select name="billingGroupFilter" options={this.billingGroupOptions} value={billingGroupFilter} placeholder="Billing group" onChange={this.onFilterChange}/>}
                                                    {this.groupClassOptions.length > 0 && <Form.Select name="eligibilityClassFilter" options={this.groupClassOptions} value={eligibilityClassFilter} placeholder="Eligibility class" onChange={this.onFilterChange}/>}
                                                </Form>
                                            </Popup>

                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {!!data &&
                                          data.slice(0, maxDisplayCount).map((item, index) => (
                                            this.renderEmployeeRow(item, index)
                                          ))
                                        }
                                    </Table.Body>
                                </Table>
                                <CustomGrid stackable columns={1}>
                                    <CustomGrid.Row columns={1} textAlign={'center'} verticalAlign={'middle'}>
                                        <CustomGrid.Column textAlign="center">
                                            {showViewMore &&
                                              <ClickableText onClick={this.onViewMore}>View more</ClickableText>
                                            }
                                        </CustomGrid.Column>
                                    </CustomGrid.Row>
                                </CustomGrid>
                            </Segment>
                        </Segment>
                    </CustomGrid.Column>
                </CustomGrid>
                <Concierge open={conciergeOpen} onManage={this.manageConciergeModal} hasDedicatedConcierge/>
            </PortalContainer>
        )
    }
}

export default connect(mapStateToProps)(withRouter(Employees));
