import React from "react";
import {useSelector} from "react-redux";
import {selectEmployeeTermination} from "../../../../../selectors/employeeTermination";
import {CustomQLECard} from "../../../../custom-common";
import {QLE_STATUS} from "../../../../../constants/qle";
import {formatDate} from "../../../../../utils/utils";
import STRATEGIC_PARTNER from "../../../../../constants/strategicPartners";
import {getQLECardType, getQLEStatus} from "../../../../../utils/qle";

export const EmploymentTerminationCard = () => {
    const employeeTermination = useSelector(selectEmployeeTermination);
    const {
        autoApproveDate,
        changeExplanationCopy,
        coverageEndDate,
        coverageImpacted,
        decisionDate,
        reason,
        status,
        terminationDate,
        tier,
        willBePendingForApproval,
    } = employeeTermination;

    const cardType = getQLECardType(willBePendingForApproval, status, decisionDate);
    const qleStatus = getQLEStatus(willBePendingForApproval, status, decisionDate);

    const renderDescription = () => {
        const descriptions = {
            [QLE_STATUS.PENDING_FOR_APPROVAL]: `This change impacts historical coverage and requires carrier approval before it can be processed. If you have any questions, please reach out to ${STRATEGIC_PARTNER.CONTACT_SUPPORT.NUMBER}.`,
            [QLE_STATUS.COMPLETED]: `${changeExplanationCopy ? `${changeExplanationCopy}. ` : ''}If you have any questions, please reach out to ${STRATEGIC_PARTNER.CONTACT_SUPPORT.NUMBER}.`,
            [QLE_STATUS.REJECTED]: `${changeExplanationCopy ? `${changeExplanationCopy}. ` : ''}If you have any questions, please reach out to ${STRATEGIC_PARTNER.CONTACT_SUPPORT.NUMBER}.`,
            [QLE_STATUS.DEFAULT]: coverageImpacted ? 'This change has an impact to coverage, see details below for more information.' : 'No coverage is ending as a result of this employment termination.'
          };

          return <i>{descriptions[qleStatus] || descriptions['default']}</i>;
    };

    const renderChangeRequestLanguage = () => {
        if(autoApproveDate) return null;
        const formattedDecisionDate = decisionDate && formatDate(decisionDate, decisionDate, 'MMM Do, YYYY');
        if (willBePendingForApproval || qleStatus === QLE_STATUS.PENDING_FOR_APPROVAL) return `Pending carrier approval.`;
        if (qleStatus === QLE_STATUS.COMPLETED) return `Approved on ${formattedDecisionDate}.`;
        if (qleStatus === QLE_STATUS.REJECTED) return `Denied on ${formattedDecisionDate}.`;
        return null;
    };

    const getFooterContent = () => {
        const footerData = [];
        footerData.push({
            title: 'Termination date:',
            value: formatDate(terminationDate, terminationDate, "MMM Do, YYYY")
        })
        if(coverageImpacted){
            footerData.push({
                title: `${tier} coverage ends on:`,
                value: formatDate(coverageEndDate, coverageEndDate, "MMM Do, YYYY")
            })
        }
        return footerData.length ? footerData: null;
    };



    return (
        <CustomQLECard
            qleStatus={qleStatus}
            cardType={cardType}
            qleName={"Employment Termination"}
            reason={reason}
            changeRequest={renderChangeRequestLanguage()}
            description={renderDescription()}
            footerData={getFooterContent()}
        />
    )
};