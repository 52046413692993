import React, {Component} from 'react';
import {Dropdown, Grid, Icon, Loader, Menu} from "semantic-ui-react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {logout} from "../../actions/authActions";
import axios from 'axios';
import {GetHelpMenu} from '../menu/getHelpMenu'
import {MFA_ROUTE} from "../../utils/common";
import {StrategicPartnerLogo} from '../common';


const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        username: state.auth.user.username,
        user: state.auth.user,
        mfaNotEnabledGracePeriodEnded: state.auth.mfaNotEnabledGracePeriodEnded
    }
};

class PortalHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logoutStatus: 'idle',
        }
    }

    async backendLogout() {
        try {
            this.setState({ logoutStatus: 'loading'});
            await axios.post("/api/employer/v2/logout", null, {headers: {'Content-Type': 'application/json'}});
            this.setState({ logoutStatus: 'resolved'});
        } catch (e) {
            this.setState({ logoutStatus: 'failed'})
            throw new Error("An error occured while loggin out")
        }
    }


    async nukeStore() {
        const {dispatch, mfaNotEnabledGracePeriodEnded, user} = this.props;

        if(mfaNotEnabledGracePeriodEnded && !user.authToken){
            this.props.history.push("/login");
        } else{
            await this.backendLogout();
            dispatch(logout())
            this.props.history.push({pathname: "/login"});
        }
    }

    render() {
        const {logoutStatus} = this.state
        const {username, showLeftNavigation, prevRoute, user, center, onBack, showArrow, mfaNotEnabledGracePeriodEnded} = this.props;
        const backArrowButton = (
            <Menu.Item onClick={() => {
                onBack ? onBack() : this.props.history.push({pathname: prevRoute})
            }}>
                <Icon name='arrow left'/>
            </Menu.Item>
        );

        const isUserLoggedIn = !!user && user.authToken;
        const isMfaGracePeriod = mfaNotEnabledGracePeriodEnded && !user.authToken;
        const showUserMenuDropdown = isUserLoggedIn || isMfaGracePeriod;
        const showLogo = this.props.location.pathname === MFA_ROUTE && !showArrow;

        return (
            <React.Fragment>
                <Menu fixed={"top"} secondary pointing className='employer-portal-menuContainer'>
                    <Grid verticalAlign='middle'>
                        <Grid.Column width={4}>
                            {showLogo && <StrategicPartnerLogo className={'strategicPartnerLogo'} />}
                            {onBack && (
                                ((showArrow && this.props.location.pathname === MFA_ROUTE) || this.props.location.pathname !== MFA_ROUTE) && (
                                    backArrowButton
                            ))}
                        </Grid.Column>
                        <Grid.Column width={4} textAlign={'center'}>
                            <b>{center}</b>
                        </Grid.Column>
                        <Grid.Column width={4} textAlign='right' className='menu-rightColumn'>
                            <Menu.Menu position="right" >
                                <GetHelpMenu hasDedicatedConcierge offline={this.props.location.pathname === MFA_ROUTE} />
                                {showUserMenuDropdown && <Dropdown
                                    item
                                    text={username}
                                    direction='left'
                                    className="menuUserDropdown"
                                    closeOnChange={false}
                                >
                                    <Dropdown.Menu style={{padding: '0.5em 0'}} open={logoutStatus === 'loading'}>
                                        <Dropdown.Item as={Menu.Item} onClick={() => {
                                            (this.props.location.pathname === MFA_ROUTE && !user?.authToken) ? this.props.history.push({pathname: "/login"}) : this.nukeStore()}}>
                                            { logoutStatus === 'loading'
                                                ? (
                                                    <Loader active inline >
                                                        <span style={{fontWeight: 'normal'}}>Logging out</span>
                                                    </Loader>
                                                )
                                                : 'Log out'
                                            }
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>}
                            </Menu.Menu>
                        </Grid.Column>
                    </Grid>
                </Menu>

                <div className={"header-shadow"}/>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps)(withRouter(PortalHeader));
