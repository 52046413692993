import {openEnrollmentTypes} from '../actions/actionTypes';

const defaultStatus = 'unavailable'

const allowedStatus = [
    defaultStatus,
    'open',
    'setup',
    'started',
    'finished',
    'closed',
];

const initialState = {
    status: defaultStatus,
    confirmedEligibleEmployees: false,
    enrollmentStartDate: '',
    enrollmentEndDate: '',
    preEnrollmentEducationLaunchDate: '',
    reEnrollRequired: null,
    renewedContract: {
        contractId: null,
        policyEffectiveDate: null,
        ratedClasses: [],
    }
};

export const openEnrollmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case openEnrollmentTypes.SET_DATA: {
            const newState = {
                ...state,
                ...action.payload,
            }

            let status = newState.status || defaultStatus

            if (!allowedStatus.includes(status)) {
                console.error(`Open Enrollment update status "${status}" invalid.`)
                status = defaultStatus
            }

            return {
                ...newState,
                status,
            }
        }
        default:
            return state
    }
}
