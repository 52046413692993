export const QLE_STATUS = Object.freeze({
    COMPLETED: 'Completed',
    PENDING_FOR_APPROVAL: 'Pending for Approval',
    REJECTED: 'Rejected',
    DEFAULT: 'default'
});

export const QLE_CARD_TYPE = Object.freeze({
    WARNING: 'warning',
    SUCCESS: 'success',
    ERROR: 'error',
    DEFAULT: 'default'
});