export const FAMILY_TIER_PROPERTIES = Object.freeze({
    'EE': {
        order: 0,
        color: '#3C9078'
    },
    'EE + SP': {
        order: 1,
        color: '#F78B66'
    },
    'EE + CH(S)': {
        order: 2,
        color: '#D75426'
    },
    'FAM': {
        order: 3,
        color: '#F0A446'
    },
});

export const PARTICIPATION_RESULT_PROPERTIES = Object.freeze({
    'Enrolled': {
        order: 0,
        color: '#D75426'
    },
    'Declined/Not enrolled': {
        order: 1,
        color: '#F7BE78'
    },
});

export const PROGRESS_STATUS_PROPERTIES = Object.freeze({
    'Not Started': {
        order: 0,
        color: '#E0E0E0',
        dotColor: '#9E9E9E',
        background: '#EEEEEE',
    },
    'Started': {
        order: 1,
        color: '#F0A446',
        dotColor: '#F0A446',
        background: '#FFF7EC',
    },
    'Finished': {
        order: 2,
        color: '#D75426',
        dotColor: '#145A7B',
        background: '#E3EEED',
    }
});
