import React, {Component, Fragment} from 'react';
import {
    Button,
    Container,
    Form,
    Grid,
    Header,
    Loader,
    Message,
    Icon,
    Input,
    Transition,
    Segment,
    Checkbox
} from "semantic-ui-react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Steps from "../steps/steps";
import {setGroupInfo} from "../../actions/groupActions";
import {getStepProps} from "../../utils/common";
import Footer from "../footer";
import AccountCreationHeader from "./accountCreationHeader";
import RequiredSpan from "../requiredMark";
import EditActivelyWorkHoursModal from "./editActivelyWorkHoursModal";
import BorderedSegment from "../borderedSegment";
import {scrollToTop} from "../../utils/utils";
import STRATEGIC_PARTNER from "../../constants/strategicPartners";
import {CustomDivider} from '../custom-common';

const continuationProvisionsCheckbox = [
    {
        name: 'leaveAbsenceMilitaryLeave',
        label: 'Military Leave of Absence'
    },
    {
        name: 'leaveAbsenceSabbatical',
        label: 'Sabbatical Leave of Absence'
    },
    {
        name: 'leaveAbsenceQuarantine',
        label: 'Leave of Absence due to Quarantine'
    },
    {
        name: 'furlough',
        label: 'Furlough'
    },
    {
        name: 'laborStrike',
        label: 'Labor Strike'
    },
];

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        group: state.accountCreation.group,
        initialData: state.accountCreation.initialData,
        ratedClasses: state.accountCreation.group.ratedClasses,
        variesByClass: state.accountCreation.group.variesByClass,
        newEligibilityInfo: state.accountCreation.group.newEligibilityInfo
    }
};

class Eligibility extends Component {
    DefaultActivelyWorkingHours = 20

    constructor(props) {
        super(props);

        this.state = {
            error: '',
            stepProps: null,
            selectedClassIndex: 0,
        };
        this.required = ['benefitTerminationRules', 'activelyAtWorkHours', 'continuationProvisions'];
        this.handleClassFormChange = this.handleClassFormChange.bind(this);
    }

    componentDidMount() {
        this.buildSteps();
        const {ratedClasses} = this.props;
        if (this.props.variesByClass === null) {
            ratedClasses.forEach(item => {
                this.initializeContinuationProvisions(item);
                if (!item.activelyAtWorkHours) {
                    this.handleClassFormChange(null, {
                        value: '20',
                        name: 'activelyAtWorkHours'
                    }, item.groupClass.groupClassId);
                } else {
                    if (item.activelyAtWorkHours !== this.DefaultActivelyWorkingHours) {
                        const {dispatch} = this.props;
                        dispatch(setGroupInfo("SET_VARIES_BY_CLASS", true));
                    }
                }
            })

            if (ratedClasses.filter(item => item.benefitTerminationRules !== null).length > 0 ||
                ratedClasses.filter(item => item.onlyUnmarried !== null).length > 0 ||
                ratedClasses.filter(item => item.domesticPartnership !== null).length > 0
            ) {
                const {dispatch} = this.props;
                dispatch(setGroupInfo("SET_VARIES_BY_CLASS", true));
            }
        }
    }

    initializeContinuationProvisions = (item) => {
        const {
            contractTerms: {
                eligibility: {
                    coverageContinued,
                    coverageNotContinued,
                }
            }
        } = item;

        const continuationProvisionsValue = coverageNotContinued !== null ? !coverageNotContinued : coverageContinued;

        if (continuationProvisionsValue !== undefined) {
            this.handleContinuationProvisions(null, item, continuationProvisionsValue);
        }

        for (let checkbox of continuationProvisionsCheckbox) {
            const eligibilityValue = item.contractTerms.eligibility[checkbox.name];
            this.handleClassFormChange(null, {
                value: (eligibilityValue || false) && !!coverageContinued,
                name: checkbox.name,
            }, item.groupClass.id);
        }
    };

    buildSteps = () => {
        this.setState({stepProps: getStepProps(2)});
    }

    handleClassFormChange = (event, {value, name}, groupClassId) => {
        const {dispatch} = this.props;
        const payload = {
            fieldName: name,
            value: value,
            id: groupClassId
        }
        dispatch(setGroupInfo('UPDATE_CLASS', payload));
        this.setState({error: ''});
    };

    handleNewFormChange = (event, {name, value}) => {
        const {dispatch} = this.props;
        dispatch(setGroupInfo(name, value));
        this.setState({error: ''})
    };

    handleCheckboxChange = (e, {checked}) => {
        const {dispatch, newEligibilityInfo} = this.props;
        dispatch(setGroupInfo("SET_VARIES_BY_CLASS", checked));
        if (newEligibilityInfo.activelyAtWorkHours === null) {
            this.handleNewFormChange(null, {name: "SET_ACTIVELY_AT_WORK_HOURS", value: this.DefaultActivelyWorkingHours.toString()})
        }
    }

    handleSubmit = () => {
        this.props.history.push({pathname: this.props.nextRoute})
    }

    getActivelyAtWorkHoursErrorMsg = (value) => {
        return value === null
    }

    validate = () => {
        let isError = false;
        if (this.props.variesByClass) {
            this.props.ratedClasses.forEach(groupClass => {
                let required = [...this.required];
                if (groupClass.contractTerms.restrictFamilyMembers === false) {
                    if (this.showUnmarriedChildrenBaseOnState()){
                        required.push('onlyUnmarriedChildren')
                    }
                    if (this.showDomesticPartner()) {
                        required.push('domesticPartnership')
                    }
                }
                const activelyAtWorkHours = groupClass.contractTerms?.eligibility?.activelyAtWorkHours;
                let invalidActivelyAtWorkHours = this.getActivelyAtWorkHoursErrorMsg(activelyAtWorkHours);
                if (invalidActivelyAtWorkHours) {
                    isError = true;
                    return isError;
                }
                let i;
                for (i = 0; i < required.length; i++) {
                    let item = required[i];
                    if (this.itemNotSet(groupClass, item)) {
                        isError = true;
                        break;
                    }
                    if (
                      item === 'continuationProvisions' &&
                      groupClass.continuationProvisions &&
                      !this.isValidateContinuationProvisionsCases(groupClass)) {
                        isError = true;
                        break;
                    }
                }
            })
        } else {
            let required = [...this.required];
            if (this.props.ratedClasses.some(groupClass => groupClass.contractTerms.restrictFamilyMembers === false)) {
                if (this.showUnmarriedChildrenBaseOnState()){
                    required.push('onlyUnmarriedChildren')
                }
                if (this.showDomesticPartner()) {
                    required.push('domesticPartnership')
                }
            }
            const {activelyAtWorkHours} = this.props.newEligibilityInfo;
            let invalidActivelyAtWorkHours = this.getActivelyAtWorkHoursErrorMsg(activelyAtWorkHours);
            if (invalidActivelyAtWorkHours) {
                isError = true;
                return isError;
            }
            let i;
            for (i = 0; i < required.length; i++) {
                let item = required[i];
                if (this.itemNotSet(this.props.newEligibilityInfo, item)) {
                    isError = true;
                    break;
                }
                if (
                  item === 'continuationProvisions' && !this.isValidateContinuationProvisionsCases(this.props.newEligibilityInfo.continuationProvisions.cases)) {
                    isError = true;
                    break;
                }
            }
        }

        return isError;
    }

    showDomesticPartner = () =>
        [
            "Illinois", "IL",
            "District of Columbia", "DC",
            "Rhode Island", "RI",
            "Oregon", "OR"
        ].includes(this.props.initialData.employer.address.state) === false;

    showUnmarriedChildrenBaseOnState = () =>
        [
            "Florida", "FL",
            "Indiana", "IN",
            "Virginia", "VA"
        ].includes(this.props.initialData.employer.address.state) === false;

    itemNotSet(obj, item) {
        return !obj.hasOwnProperty(item) || obj[item] === null ||
            obj[item] === undefined || obj[item].length === 0
    }


    openEditActivelyHoursModal = () => {
        this.setState({openEditActivelyHoursModal: true})
    }

    closeEditActivelyHoursModal = () => {
        this.setState({openEditActivelyHoursModal: false})
    }

    activelyHoursChange = (value) => {
        if (this.props.variesByClass) {
            const groupClassId = this.props.ratedClasses[this.state.selectedClassIndex].groupClass.id;
            this.handleClassFormChange(null, {name: "activelyAtWorkHours", value: value}, groupClassId)
        } else {
            this.handleNewFormChange(null, {name: "SET_ACTIVELY_AT_WORK_HOURS", value: value})
        }
        this.setState({openEditActivelyHoursModal: false})
    }

    handleContinuationProvisions = (e, item, value) => {
        if (!value) {
            for (const checkbox of continuationProvisionsCheckbox) {
                this.handleClassFormChange(e, {
                    name: checkbox.name,
                    value: false
                }, item.groupClass.id)
            }
        }
        this.handleClassFormChange(e, {
            name: "continuationProvisions",
            value: value
        }, item.groupClass.id)
    }

    isValidateContinuationProvisionsCases = (cases) => {
        return cases.leaveAbsenceMilitaryLeave === true   ||
          cases.leaveAbsenceMilitaryLeave === true      ||
          cases.leaveAbsenceQuarantine === true ||
          cases.furlough === true                      ||
          cases.laborStrike === true;
    }

    renderClassForm = (item) => {
        const {benefitTerminationRulesOptions} = this.props.initialData;
        const {
            onlyUnmarriedChildren,
            activelyAtWorkHours,
            domesticPartnership,
            benefitTerminationRules,
            groupClass,
            continuationProvisions,
            contractTerms : {
                restrictFamilyMembers,
                eligibility: {
                    coverageContinued,
                    coverageNotContinued
                },
            },
        } = item;

        return (
            <React.Fragment>
                <Form.Group widths='equal'>
                    <Form.Select
                        className={"benefitTerminationRules"}
                        fluid
                        label='Benefit termination rules'
                        name='benefitTerminationRules'
                        defaultValue={benefitTerminationRules}
                        onChange={(e, {name, value}) => {
                            this.handleClassFormChange(e, {name, value}, groupClass.id)
                        }}
                        options={benefitTerminationRulesOptions}
                        required
                    />
                    <Form.Field>
                        <label>Actively at work
                            hours {activelyAtWorkHours === this.DefaultActivelyWorkingHours &&
                            <span onClick={this.openEditActivelyHoursModal} className={"colorfulIcon"} style={{cursor: 'pointer'}}>
                                <Icon name="pencil"/>Edit
                            </span>}
                        </label>
                        <Input name="activelyAtWorkHours" value={activelyAtWorkHours || ''} disabled/>
                        {activelyAtWorkHours !== this.DefaultActivelyWorkingHours &&
                        <small><i>This proposal assumes the above for actively at work hours. To request a proposal with
                            a different minimum reach out to your Broker.</i></small>}
                    </Form.Field>
                </Form.Group>

                <CustomDivider hidden/>
                {restrictFamilyMembers === false &&
                    <React.Fragment>
                        {this.showUnmarriedChildrenBaseOnState() &&
                            <React.Fragment>
                                <p className={"colorfulText smaller"}><b>Child dependent eligibility</b> <RequiredSpan/></p>
                                <p style={{marginBottom: "0", marginTop: "0"}} className={"smaller"}><b>To be eligible for coverage, do children need to be unmarried?</b></p>
                                <Form.Group grouped>
                                    <Form.Radio
                                        label='Yes, only unmarried children are eligible'
                                        name="onlyUnmarriedChildren"
                                        checked={onlyUnmarriedChildren === true}
                                        onChange={(e) => this.handleClassFormChange(e, {
                                            name: "onlyUnmarriedChildren",
                                            value: true
                                        }, item.groupClass.id)}
                                    />
                                    <Form.Radio
                                        label='No, married children are still eligible'
                                        name="onlyUnmarriedChildren"
                                        checked={onlyUnmarriedChildren === false}
                                        onChange={(e) => this.handleClassFormChange(e, {
                                            name: "onlyUnmarriedChildren",
                                            value: false
                                        }, item.groupClass.id)}
                                    />
                                </Form.Group>
                                <CustomDivider hidden/>
                            </React.Fragment>
                        }
                        {this.showDomesticPartner() && (
                            <React.Fragment>
                                <p className={"colorfulText smaller"}><b>Spouse eligibility rules</b> <RequiredSpan/></p>
                                <p style={{marginBottom: "3", marginTop: "0"}} className={"smaller"}><b>Are domestic partnerships and civil unions eligible for coverage?</b></p>
                                <Form.Group grouped>
                                    <Form.Radio
                                        label='Yes'
                                        name='domesticPartnership'
                                        checked={domesticPartnership === true}
                                        onChange={(e) => this.handleClassFormChange(e, {
                                            name: "domesticPartnership",
                                            value: true
                                        }, item.groupClass.id)}
                                    />
                                    <Form.Radio
                                        label='No'
                                        name='domesticPartnership'
                                        checked={domesticPartnership === false}
                                        onChange={(e) => this.handleClassFormChange(e, {
                                            name: "domesticPartnership",
                                            value: false
                                        }, item.groupClass.id)}
                                    />
                                </Form.Group>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                }
                <CustomDivider hidden/>
                <p className={"smaller colorfulText"}><b>Continuation Provisions</b> <RequiredSpan/></p>
                <p style={{marginBottom: "0", marginTop: "0"}} className={"smaller"}>
                    If employees have temporarily stopped active work, you have the option to allow coverage
                    to continue for a limited period of time, subject to continued premium payment.
                </p>
                <p className={"small"} style={{margin: "1em 0"}}>
                    <b>Do you want to allow coverage to continue? <RequiredSpan/></b>
                </p>
                <Form.Group grouped>
                    <Form.Radio
                        label='Yes'
                        name='continuationProvisions'
                        checked={continuationProvisions !== null ? continuationProvisions : !!coverageContinued}
                        onChange={(e) => this.handleContinuationProvisions(e, item, true)}
                    />
                    <Form.Radio
                        label='No'
                        name='continuationProvisions'
                        checked={continuationProvisions !== null ? !continuationProvisions : !!coverageNotContinued}
                        onChange={(e) => this.handleContinuationProvisions(e, item, false)}
                    />
                    <br/>
                    <div style={{marginLeft: "25px", color: continuationProvisions ? '#212121' : '#757575'}}>
                        <p>Select at least one case to include when coverage will continue below—</p>
                        {continuationProvisionsCheckbox.map(({name, label}) => (
                          <>
                              <Checkbox
                                id={name}
                                label={<label style={{lineHeight: '150%'}}>{label}</label>}
                                name={name}
                                className={`${!continuationProvisions && "continuation-provisions-disabled"}`}
                                disabled={!continuationProvisions}
                                checked={item[name] === true}
                                onClick={(e, {checked}) => this.handleClassFormChange(e, {
                                    name,
                                    value: checked
                                }, item.groupClass.id)}
                              /><br/>
                          </>
                        ))}
                    </div>
                </Form.Group>

            </React.Fragment>
        )
    }

    renderNewForm = () => {
        const {benefitTerminationRulesOptions, quote} = this.props.initialData;
        const {
            onlyUnmarriedChildren,
            activelyAtWorkHours,
            domesticPartnership,
            subjectToERISA,
            benefitTerminationRules,
        } = this.props.newEligibilityInfo;
        const showFields = this.props.ratedClasses.some(groupClass => groupClass.contractTerms.restrictFamilyMembers === false);
        return (
            <React.Fragment>
                <Form.Group widths='equal'>
                    <Form.Select
                        className={"benefitTerminationRules"}
                        fluid
                        label='Benefit termination rules'
                        name='SET_BENEFIT'
                        defaultValue={benefitTerminationRules}
                        onChange={(e, {name, value}) => {
                            this.handleNewFormChange(e, {name, value})
                        }}
                        options={benefitTerminationRulesOptions}
                        required
                    />
                    <Form.Field>
                        <label>Actively at work
                            hours {quote.activelyWorkingHours === this.DefaultActivelyWorkingHours &&
                            <span className={"colorfulText"} onClick={this.openEditActivelyHoursModal}
                                  style={{cursor: 'pointer'}}><Icon name="pencil"/>Edit</span>}</label>
                        <Input name="activelyAtWorkHours" value={activelyAtWorkHours || ''} disabled
                               style={{opacity: "1", backgroundColor: "#EEEEEE"}}/>
                        {quote.activelyWorkingHours !== this.DefaultActivelyWorkingHours &&
                        <small><i>This proposal assumes the above for actively at work hours. To request a proposal with
                            a different minimum reach out to your Broker.</i></small>}
                    </Form.Field>
                </Form.Group>

                <CustomDivider hidden/>
                {showFields &&
                <React.Fragment>
                    {this.showUnmarriedChildrenBaseOnState() &&
                        <Fragment>
                            <p className={"smaller colorfulText"}><b>Child dependent eligibility</b> <RequiredSpan/></p>
                            <p className={"smaller"}><b>To be eligible for coverage, do children need to be unmarried?</b></p>
                            <Form.Group grouped>
                                <Form.Radio
                                    label='Yes, only unmarried children are eligible'
                                    name="onlyUnmarriedChildren"
                                    checked={onlyUnmarriedChildren === true}
                                    onChange={(e) => this.handleNewFormChange(e, {name: "SET_UNMARRIED", value: true})}
                                />
                                <Form.Radio
                                    label='No, married children are still eligible'
                                    name="onlyUnmarriedChildren"
                                    checked={onlyUnmarriedChildren === false}
                                    onChange={(e) => this.handleNewFormChange(e, {name: "SET_UNMARRIED", value: false})}
                                />
                            </Form.Group>
                            <CustomDivider hidden/>
                        </Fragment>
                    }
                    {this.showDomesticPartner() && (
                      <React.Fragment>
                          <p className={"smaller colorfulText"}><b>Spouse eligibility rules</b> <RequiredSpan/></p>
                          <p className={"smaller"}><b>Are domestic partnerships and civil unions eligible for coverage?</b></p>
                          <Form.Group grouped>
                              <Form.Radio
                                label='Yes'
                                name='domesticPartnership'
                                checked={domesticPartnership === true}
                                onChange={(e) => this.handleNewFormChange(e, {name: "SET_DOMESTIC_PARTNERSHIP", value: true})}
                              />
                              <Form.Radio
                                label='No'
                                name='domesticPartnership'
                                checked={domesticPartnership === false}
                                onChange={(e) => this.handleNewFormChange(e, {name: "SET_DOMESTIC_PARTNERSHIP", value: false})}
                              />
                          </Form.Group>
                      </React.Fragment>
                    )}
                </React.Fragment>}

                <CustomDivider hidden/>

                <p className={"smaller colorfulText"}><b>ERISA applicability</b> <RequiredSpan/></p>
                <p className={"smaller"}><b>Will this be part of a benefit plan that's subject to ERISA (Employee Retirement Income Security
                    Act of 1974) requirements? <RequiredSpan/></b></p>
                <Form.Group grouped>
                    <Form.Radio
                        label='Yes'
                        name='subjectToERISA'
                        checked={subjectToERISA === true}
                        onChange={(e) => this.handleNewFormChange(e, {name: "SET_SUBJECT_ERISA", value: true})}
                    />
                    <Form.Radio
                        label='No'
                        name='subjectToERISA'
                        checked={subjectToERISA === false}
                        onChange={(e) => this.handleNewFormChange(e, {name: "SET_SUBJECT_ERISA", value: false})}
                    />
                </Form.Group>
            </React.Fragment>
        )
    }

    toggleDetail = (index) => {
        const {selectedClassIndex} = this.state
        if (index === selectedClassIndex) {
            index = null
        } else {
            scrollToTop()
        }
        this.setState({selectedClassIndex: index})
    }

    renderlassContent = ({ item, index, showDetails }) => {
        return (
          <React.Fragment>
              <div className={"clickable"} onClick={() => {this.toggleDetail(index)}}>
                  <b>Class {index + 1}: {item.groupClass.documentDescription}</b>
                  <Icon style={{float: 'right'}} name={showDetails ? 'chevron up' : 'chevron down'}/>
              </div>
              {showDetails &&
                <Transition.Group animation={"slide down"} duration={100}>
                    <Segment basic>{this.renderClassForm(item)}</Segment>
                </Transition.Group>
              }
          </React.Fragment>
        )
    }

    render() {
        const errors = this.validate();
        const {stepProps, openEditActivelyHoursModal, selectedClassIndex, activelyAtWorkHours} = this.state;
        const {
            group: {
                variesByClass
            },
            prevRoute,
            ratedClasses
        } = this.props;

        const nextButton = (
            <Button onClick={this.handleSubmit} disabled={errors} primary>
                Next step
            </Button>
        )

        if (!stepProps) {
            return <Container><Loader active={true}/></Container>
        }
        const hasMultipleClasses = ratedClasses.length > 1;
        const currentActivelyAtWorkHours = (!!variesByClass && !!selectedClassIndex) ? ratedClasses[selectedClassIndex].activelyAtWorkHours : activelyAtWorkHours
        const sameRestrictFamilyMembers = ratedClasses.every(groupClass => groupClass.contractTerms.restrictFamilyMembers === true) || ratedClasses.every(groupClass => groupClass.contractTerms.restrictFamilyMembers === false);

        return (
            <div className="employer-main-container">
                <AccountCreationHeader prevRoute={prevRoute} history={this.props.history} centerTitle="Create account"/>
                <Container className={"employer-accountCreation-container"}>
                    <Grid stackable>
                        <Grid.Column>
                            <Steps stepProps={stepProps}/>
                        </Grid.Column>
                    </Grid>

                    <Grid centered>
                        <Grid.Column width={8}>
                            <Header as='h2' className={'colorfulText'}>Eligibility details</Header>
                            <Form className={"small"}>
                                {hasMultipleClasses
                                    ? <React.Fragment>
                                        {!!sameRestrictFamilyMembers &&
                                          <Form.Checkbox
                                            checked={variesByClass}
                                            label={"Varies by eligibility class"}
                                            onChange={this.handleCheckboxChange}
                                          />
                                        }
                                        <CustomDivider hidden/>
                                        {variesByClass
                                            ? ratedClasses.map((item, index) => {
                                                const {selectedClassIndex} = this.state
                                                const showDetails = selectedClassIndex === index
                                                return <React.Fragment key={index}>
                                                    {showDetails
                                                      ? <BorderedSegment borderColor={STRATEGIC_PARTNER.PALETTE.PRIMARY}>
                                                          {this.renderlassContent({item, index, showDetails})}
                                                        </BorderedSegment>
                                                      : <Segment>
                                                          {this.renderlassContent({item, index, showDetails})}
                                                        </Segment>
                                                    }
                                                </React.Fragment>
                                              })
                                            : this.renderNewForm()
                                        }
                                      </React.Fragment>
                                    : ratedClasses[0] && this.renderClassForm(ratedClasses[0])
                                }
                            </Form>
                            <CustomDivider hidden/>
                            {this.state.error.length > 0 && <Message negative>{this.state.error}</Message>}
                        </Grid.Column>
                    </Grid>

                    <CustomDivider hidden section/>
                </Container>
                <Footer button={nextButton} showTopFooter hasToDisplayCarrierDisclaimer/>
                <EditActivelyWorkHoursModal open={openEditActivelyHoursModal}
                                            onCancel={this.closeEditActivelyHoursModal}
                                            onSave={this.activelyHoursChange}
                                            activelyWorkingHours={currentActivelyAtWorkHours}/>
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps)(Eligibility));
