import React, {Component} from 'react';
import {connect} from 'react-redux';
import {CustomToast} from "../custom-common";

const mapStateToProps = (state, ownProps) => {
  return {
      ...ownProps,
      toasts: state.toast.list,
    }
};

class Toasts extends Component {
  render() {
      const {toasts} = this.props

      if (!toasts.length) return null

      return (
          <>
              {toasts.map(toast => (
                  <CustomToast key={toast.id} {...toast} />
              ))}
          </>
      );
  }
}
export default connect(mapStateToProps)(Toasts)