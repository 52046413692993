import React, {Component} from "react";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Label from "semantic-ui-react/dist/commonjs/elements/Label";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import BorderedSegment from "../../borderedSegment";
import PlaidMicroDepositVerificationLink from "./plaidMicroDepositVerificationLink";
import RemoveMicrodepositBankAccountModal from "./removeMicrodepositBankAccountModal";

class MicroDepositsBankAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRemoveMdModal: false,
        }
    }

    render() {
        const {
            name, lastAccountNumbers, id, plaidEnv, plaidLinkToken, setPlaidData, loading,
            setLoader, bankDescription, status, remove, tooManyVerificationAttemptsCallback,
            updateStatus, hide
        } = this.props;

        const { showRemoveMdModal } = this.state;

        let statusStyles = {
            borderColor: "#F0A446",
            labelClassName: "label-bkgSunshade50",
            iconClassName: "icon-primary-sunshade",
            text: "Pending verification"
        };

        if (status === "verification_failed") {
            statusStyles = {
                borderColor: "#D82000",
                labelClassName: "label-bkgWarning50",
                iconClassName: "icon-primary-persimmon",
                text: "Unable to verify"
            }
        }

        return (
            <>
                {!!showRemoveMdModal &&
                    <RemoveMicrodepositBankAccountModal show={showRemoveMdModal}
                                              close={()=>{this.setState({showRemoveMdModal: false})}}
                                              remove={this.props.remove}
                                              paymentMethod={id}
                    />
                }
                <BorderedSegment borderColor={statusStyles.borderColor}>
            <Grid>
                <Grid.Column width={10}>
                    <span className="small neutral700Text">
                        <Icon name='university' style={{fontSize: 20}}/><b>{name}</b>
                    </span>
                    <Label className={statusStyles.labelClassName} style={{margin: "0 2em"}} horizontal>
                        <Icon className={`icon-vertical-allign ${statusStyles.iconClassName}`} name="circle" size={"tiny"} />
                        {statusStyles.text}
                    </Label>
                    {status === "pending_manual_verification" && (
                        <PlaidMicroDepositVerificationLink accountId={id}
                                                            plaidEnv={plaidEnv}
                                                            plaidLinkToken={plaidLinkToken}
                                                            setPlaidData={setPlaidData}
                                                            tooManyVerificationAttemptsCallback={tooManyVerificationAttemptsCallback}
                                                            loading={loading}
                                                            setLoader={setLoader}
                                                            bankDescription={bankDescription}
                                                            updateStatus={updateStatus}
                                                            remove={remove}
                                                            hide={hide}
                        />
                    )}
                    <br/>
                    <small className="neutral600Text">******{lastAccountNumbers}</small>
                </Grid.Column>
                <Grid.Column width={2} textAlign={"right"}>
                    <Dropdown icon={"ellipsis horizontal"}>
                        <Dropdown.Menu>
                                    <Dropdown.Item text='Delete' onClick={() => {
                                        this.setState({showRemoveMdModal: true})
                                    }}/>
                        </Dropdown.Menu>
                    </Dropdown>
                </Grid.Column>
            </Grid>
        </BorderedSegment>
            </>
        )
    }
}

export default withRouter(connect()(MicroDepositsBankAccount));
