import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import {formatMoneyFixedTo2} from "../../../utils/utils";
import React from "react";
import moment from "moment/moment";

const TotalAmountPendingPaymentSection = ({amount, lastPaymentDate, marginTop, style}) => (
    <Segment style={{
        backgroundColor: '#FFFFFF',
        padding: '8px 10px',
        color: '#616161',
        border: '1px solid #E0E0E0',
        borderRadius: 8,
        display: 'flex',
        marginTop: marginTop || 25,
        ...style
    }}>
        <Icon name={"info circle"} style={{float: 'left'}}/>
        <div style={{float: "left"}}>
                <span className={"smaller"}>
                {
                    lastPaymentDate
                        ? <>Total amount may not reflect pending payments:<br/>Issued
                            on {moment(lastPaymentDate).format('MMM D, YYYY')}</>
                        : <><i>Total amount does not reflect the following pending payments—</i><br/></>
                }<b> Amount: {formatMoneyFixedTo2(amount)}</b>
                </span>
        </div>
    </Segment>
);

export default TotalAmountPendingPaymentSection;