import React from "react";
import {Header, Grid} from "semantic-ui-react";
import PersonalizationCard from "../../personalizationCard";
import BottomReviewChoices from "./bottomReviewChoices";
import {brellaSelect} from "../../../utils/strings";
import {CustomDivider} from "../../custom-common";

const renderPreset = ({index, title, preset}) => (
    <Grid.Column key={index}>
        <PersonalizationCard title={title} preset={preset}/>
    </Grid.Column>
);

const titles = {value: "Value", enhanced: "Enhanced", premier: "Premier"};

const ReviewSelect = ({optionalRiders, quoteInfo, presets, ratedClassUtil, ageRanges}) => {
  const visiblePresets = {}

  Object.keys(titles).forEach(preset => {
    if (presets[preset].show !== false) visiblePresets[preset] = presets[preset]
  })

  return (
    <>
      <CustomDivider hidden/>
      <Header as={"h2"} className={"colorfulText"}>
        {Object.keys(visiblePresets).length === 1
        ? 'Preconfigured package'
        : 'Preconfigured packages'}
      </Header>
      <CustomDivider hidden/>
      <Grid stackable columns={3} style={{ justifyContent: 'center' }}>
        {Object.keys(visiblePresets).map((title, index) =>
          renderPreset({preset: presets[title], title: titles[title], index: index})
        )}
      </Grid>
      <BottomReviewChoices
        optionalRiders={optionalRiders}
        quoteInfo={quoteInfo}
        ratedClassUtil={ratedClassUtil}
        ageRanges={ageRanges}
        planOffering={brellaSelect}
      />
    </>
  )
};

export default ReviewSelect;
