import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, Segment, Transition } from 'semantic-ui-react';
import { hide, clear } from '../../actions/toastActions';
import PropTypes from 'prop-types';

const TOAST_TIMEOUT = 5000;

export const CustomToast = ({ id, type, title, message, autodismissTime = TOAST_TIMEOUT}) => {

    const dispatch = useDispatch();
    const toast = useSelector(state => state.toast.list.find(toast => toast.id === id));
    const isVisible = toast?.visible;

    useEffect(() => {
        if (isVisible) {
            const timer = setTimeout(() => {
                dispatch(hide(id));
            }, autodismissTime);

            return () => clearTimeout(timer);
        }
    }, [id, isVisible, dispatch]);

    useEffect(() => {
        if (!isVisible) {
            dispatch(clear(id));
        }
    }, [id, isVisible, dispatch]);

    const handleClose = () => {
        dispatch(hide(id));
    };

    const properties = {
            success: { color: 'green', icon: 'check circle outline' },
            error: { color: 'red', icon: 'times circle outline' },
            warning: { color: 'yellow', icon: 'warning sign' },
    };


    const { color, icon } = properties[type];

    return (
        <Transition visible={isVisible} animation='scale' unmountOnExit>
            <Segment raised className={'small toast'}>
                <div>
                <Icon name={icon} color={color} size='large' />
                <div className={'toast-content'}>
                    {title && <div><b>{title}</b></div>}
                    {message && <div dangerouslySetInnerHTML={{ __html: message }} />}
                </div>
                <div>
                <Icon fitted link name='close' onClick={handleClose} style={{ fontSize:18}} />
                </div>
            </div>
            </Segment>
        </Transition>


    );
};

CustomToast.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    type: PropTypes.oneOf(['success', 'error', 'warning']).isRequired,
    title: PropTypes.string,
    message: PropTypes.string,
    autodismiss: PropTypes.number,
};